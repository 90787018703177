import React from 'react'
import PT from 'prop-types'
import { Input, Icon, message, Select } from 'antd'
import { Prompt } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../components/Button'
import InputField from '../../../components/InputField'
import businessTypes from '../../../constants/business_types'
import occupations from '../../../constants/occupations'

const CHECK_EMAIL = gql`
  query checkEmail($email: String!) {
    checkEmail(email: $email)
  }
`

// const CHECK_USERNAME = gql`
//   query checkUsername($username: String!) {
//     checkUsername(username: $username)
//   }
// `

const RegisterForm = ({ displayName, onNext, gotoLogin, inputChange, input }) => {
  const [otherBusinessState, setOtherBusiness] = React.useState(false)
  const [otherOccupationState, setOtherOccupation] = React.useState(false)
  const [
    checkEmail,
    { called: checkEmailCalled, data: checkEmailData, loading: checkEmailLoading },
  ] = useLazyQuery(CHECK_EMAIL)
  // const [
  //   checkUsername,
  //   { called: checkUsernameCalled, data: checkUsernameData = {}, loading: checkUsernameLoading },
  // ] = useLazyQuery(CHECK_USERNAME)

  const isFormDirty = () => {
    return Object.keys(input).some(key => input[key] !== '')
  }

  const validateEmail = email => {
    return /([\S]+@[\S]+\.[\S]+)/.test(email)
  }

  const validatePhone = phone => {
    return /([0-9]{3})*([0-9]{3})*([0-9]{4})$/.test(phone)
  }

  const validateForm = () => {
    if (
      // !input.username ||
      !input.firstName ||
      !input.lastName ||
      !input.email ||
      !input.phone ||
      !input.businessType ||
      !input.occupation
    ) {
      message.error('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false
    }
    if (!validateEmail(input.email)) {
      message.error('รูปแบบอีเมลไม่ถูกต้อง')
      return false
    }
    // if (checkUsernameCalled && !checkUsernameData.checkUsername) {
    //   message.error('มีชื่อผู้ใช้นี้ในระบบแล้ว')
    //   return false
    // }
    if (checkEmailCalled && !checkEmailData.checkEmail) {
      message.error('อีเมลนี้ถูกใช้งานแล้ว')
      return false
    }
    if (!validatePhone(input.phone)) {
      message.error('รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
      return false
    }
    return true
  }

  const handleSubmit = () => {
    // checkUsername({ variables: { username: input.username } })
    checkEmail({ variables: { email: input.email } })
    if (validateForm()) {
      onNext()
    }
  }

  const handleBusinessChange = key => {
    if (key === 'other') {
      setOtherBusiness(true)
    } else {
      setOtherBusiness(false)
      inputChange({ businessType: key })
    }
  }

  const handleOccupationChange = key => {
    if (key === 'other') {
      setOtherOccupation(true)
    } else {
      setOtherOccupation(false)
      inputChange({ occupation: key })
    }
  }

  return (
    <div>
      <Prompt message="Are you sure you want to leave?" when={isFormDirty()} />
      {/* <InputField title="ชื่อผู้ใช้งาน">
        <Input
          value={input.username}
          onChange={e => inputChange({ username: e.target.value })}
          onBlur={() => checkUsername({ variables: { username: input.username } })}
          onPressEnter={() => checkUsername({ variables: { username: input.username } })}
          style={
            checkUsernameCalled && input.username
              ? { borderColor: checkUsernameData.checkUsername ? '#52c41a' : '#f5222d' }
              : {}
          }
          suffix={checkUsernameLoading && <Icon type="loading" />}
        />
        {checkUsernameCalled && !checkUsernameLoading && input.username ? (
          checkUsernameData.checkUsername ? (
            <small style={{ color: '#52c41a' }}>
              <Icon style={{ marginRight: 4 }} type="check-circle" />
              <span>สามารถใช้งานได้</span>
            </small>
          ) : (
            <small style={{ color: '#f5222d' }}>
              <Icon style={{ marginRight: 4 }} type="close-circle" />
              <span>มีชื่อผู้ใช้นี้ในระบบแล้ว</span>
            </small>
          )
        ) : null}
      </InputField> */}
      <InputField title="LINE ID">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontWeight: 600 }}>{displayName}</span>
          <Button type="danger" onClick={gotoLogin}>
            ออกจากระบบ
          </Button>
        </div>
      </InputField>
      <InputField title="ชื่อ">
        <Input value={input.firstName} onChange={e => inputChange({ firstName: e.target.value })} />
      </InputField>
      <InputField title="นามสกุล">
        <Input value={input.lastName} onChange={e => inputChange({ lastName: e.target.value })} />
      </InputField>
      <InputField title="อีเมล">
        <Input
          value={input.email}
          onChange={e => inputChange({ email: e.target.value })}
          onBlur={() => checkEmail({ variables: { email: input.email } })}
          onPressEnter={() => checkEmail({ variables: { email: input.email } })}
          style={
            checkEmailCalled && validateEmail(input.email)
              ? {
                  borderColor:
                    checkEmailCalled && !checkEmailLoading && checkEmailData.checkEmail
                      ? '#52c41a'
                      : '#f5222d',
                }
              : {}
          }
          suffix={checkEmailLoading && <Icon type="loading" />}
        />
        {checkEmailCalled && !checkEmailLoading && input.email ? (
          checkEmailData.checkEmail ? (
            <small style={{ color: '#52c41a' }}>
              <Icon style={{ marginRight: 4 }} type="check-circle" />
              <span>อีเมลยังไม่เคยถูกใช้งาน</span>
            </small>
          ) : (
            <small style={{ color: '#f5222d' }}>
              <Icon style={{ marginRight: 4 }} type="close-circle" />
              <span>อีเมลนี้ถูกใช้งานแล้ว</span>
            </small>
          )
        ) : null}
      </InputField>
      <InputField title="เบอร์โทรศัพท์">
        <Input value={input.phone} onChange={e => inputChange({ phone: e.target.value })} />
      </InputField>
      <InputField title="อาชีพ">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={input.occupation}
          onChange={key => handleOccupationChange(key)}
          style={{ width: '100%' }}
        >
          {occupations.map(bt => (
            <Select.Option key={bt}>{bt}</Select.Option>
          ))}
          <Select.Option key="other">อื่นๆ</Select.Option>
        </Select>
        {otherOccupationState && (
          <Input
            value={input.occupation}
            onChange={e => inputChange({ occupation: e.target.value })}
            style={{ marginTop: 8 }}
            autoFocus
          />
        )}
      </InputField>
      <InputField title="ประเภทธุรกิจ">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={input.businessType}
          onChange={key => handleBusinessChange(key)}
          style={{ width: '100%' }}
        >
          {businessTypes.map(bt => (
            <Select.Option key={bt}>{bt}</Select.Option>
          ))}
          <Select.Option key="other">อื่นๆ</Select.Option>
        </Select>
        {otherBusinessState && (
          <Input
            value={input.businessType}
            onChange={e => inputChange({ businessType: e.target.value })}
            style={{ marginTop: 8 }}
            autoFocus
          />
        )}
      </InputField>
      <Button size="large" type="primary" onClick={handleSubmit} block>
        ถัดไป
      </Button>
    </div>
  )
}

RegisterForm.propTypes = {
  displayName: PT.string,
  input: PT.shape({
    // username: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    email: PT.string,
    phone: PT.string,
    businessType: PT.string,
    occupation: PT.string,
  }),
  onNext: PT.func,
  gotoLogin: PT.func,
  inputChange: PT.func,
}

RegisterForm.defaultProps = {
  onNext: () => {},
  gotoLogin: () => {},
  inputChange: () => {},
}

export default props => {
  return <RegisterForm {...props} />
}
