import React from 'react'
import PT from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Icon } from 'antd'

import AddFacebookChannelBtn from './AddFacebookChannelBtn'
import ChannelItem from './ChannelItem'
import Button from '../../../../components/Button'
import Divider from '../../../../components/Divider'
import Loading from '../../../../components/Loading'

const ALL_CHANNELS = gql`
  query allChannels($botId: String!) {
    allChannels(bot_id: $botId) {
      name
      title
      platform
    }
  }
`

const CREATE_CHANNEL = gql`
  mutation createChannel($botId: String!, $platform: ChannelPlatform!, $input: ChannelInput) {
    createChannel(bot_id: $botId, platform: $platform, input: $input) {
      name
    }
  }
`

const ChannelList = ({ botId, selectedChannel, onChannelSelect }) => {
  const [btnLoading, setBtnLoading] = React.useState(false)

  const { data, loading, error } = useQuery(ALL_CHANNELS, { variables: { botId } })
  const [createChannel] = useMutation(CREATE_CHANNEL, {
    refetchQueries: [{ query: ALL_CHANNELS, variables: { botId } }],
  })

  if (error) {
    console.error(error)
  }

  const allChannels = (data && data.allChannels) || []

  const facebookChannel = allChannels.find(ch => ch.platform === 'FacebookBot')
  let lineChannels = {
    Bot: [],
    Login: [],
    Notify: [],
  }

  allChannels.forEach(ch => {
    if (ch.platform === 'LineBot') {
      lineChannels.Bot.push(ch)
    } else if (ch.platform === 'LineLogin') {
      lineChannels.Login.push(ch)
    } else if (ch.platform === 'LineNotify') {
      lineChannels.Notify.push(ch)
    }
  })

  const handleAddChannel = async platform => {
    setBtnLoading(true)
    try {
      const { data } = await createChannel({
        variables: { botId, platform, input: { title: `New ${platform} Channel` } },
      })
      const newChannel = (data && data.createChannel) || {}
      onChannelSelect(newChannel.name)
    } catch (err) {
      console.error(err)
    }
    setBtnLoading(false)
  }

  return (
    <div style={{ padding: '24px 36px 16px' }}>
      <div style={{ marginBottom: 32 }}>
        <h1>Channels</h1>
      </div>
      <div style={{ position: 'relative' }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div style={{ marginBottom: 32 }}>
              <Divider margin={8}>Facebook</Divider>
              {facebookChannel ? (
                <ChannelItem
                  type="Page"
                  data={facebookChannel}
                  loading={btnLoading}
                  isSelected={selectedChannel === facebookChannel.name}
                  onSelect={onChannelSelect}
                />
              ) : (
                <AddFacebookChannelBtn botId={botId} createChannel={createChannel} />
              )}
            </div>

            <div style={{ marginBottom: 32 }}>
              <Divider margin={8}>LINE Bot</Divider>
              {lineChannels.Bot.length ? (
                <>
                  {lineChannels.Bot.map((ch, i) => (
                    <ChannelItem
                      key={ch.name}
                      type="Bot"
                      data={ch}
                      loading={btnLoading}
                      isSelected={selectedChannel === ch.name}
                      onSelect={onChannelSelect}
                    />
                  ))}
                  <Button
                    icon={<Icon type="plus" />}
                    style={{ marginBottom: '8px' }}
                    onClick={() => handleAddChannel('LineBot')}
                    block
                  >
                    เพิ่ม
                  </Button>
                </>
              ) : (
                <ChannelItem
                  type="Bot"
                  loading={btnLoading}
                  onConnect={() => handleAddChannel('LineBot')}
                />
              )}
            </div>

            <div style={{ marginBottom: 32 }}>
              <Divider margin={8}>LINE Login</Divider>
              {lineChannels.Login.length ? (
                <>
                  {lineChannels.Login.map((ch, i) => (
                    <ChannelItem
                      key={ch.name}
                      type="Login"
                      data={ch}
                      loading={btnLoading}
                      isSelected={selectedChannel === ch.name}
                      onSelect={onChannelSelect}
                    />
                  ))}
                  <Button
                    icon={<Icon type="plus" />}
                    style={{ marginBottom: '8px' }}
                    onClick={() => handleAddChannel('LineLogin')}
                    block
                  >
                    เพิ่ม
                  </Button>
                </>
              ) : (
                <ChannelItem
                  type="Login"
                  loading={btnLoading}
                  onConnect={() => handleAddChannel('LineLogin')}
                />
              )}
            </div>

            {/* <div style={{ marginBottom: 32 }}>
              <Divider margin={8}>LINE Notify</Divider>
              {lineChannels.Notify.length ? (
                <>
                  {lineChannels.Notify.map((ch, i) => (
                    <ChannelItem
                      key={ch.name}
                      type="Notify"
                      data={ch}
                      loading={btnLoading}
                      isSelected={selectedChannel === ch.name}
                      onSelect={onChannelSelect}
                    />
                  ))}
                  <Button
                    icon={<Icon type="plus" />}
                    style={{ marginBottom: '8px' }}
                    onClick={() => handleAddChannel('LineNotify')}
                    block
                  >
                    เพิ่ม
                  </Button>
                </>
              ) : (
                <ChannelItem
                  type="Notify"
                  loading={btnLoading}
                  onConnect={() => handleAddChannel('LineNotify')}
                />
              )}
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}

ChannelList.propTypes = {
  botId: PT.string,
  selectedChannel: PT.string,
  onChannelSelect: PT.func,
}

ChannelList.defaultProps = {
  onChannelSelect: () => {},
}

export default ChannelList
