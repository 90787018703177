import React from 'react'
import PT from 'prop-types'

import TicketTypeForm from './TicketTypeForm'

const CreateTicketTypeForm = ({ closeForm, onSubmit, loading, readOnly }) => {
  const [input, setInput] = React.useState({
    vat_type: 'EX_VAT',
  })

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <TicketTypeForm
      input={input}
      inputChange={inputChange}
      onDelete={closeForm}
      onSave={onSubmit}
      loading={loading}
      readOnly={readOnly}
    />
  )
}

CreateTicketTypeForm.propTypes = {
  loading: PT.bool,
  closeForm: PT.func,
  onSubmit: PT.func,
  readOnly: PT.bool,
}

CreateTicketTypeForm.defaultProps = {
  closeForm: () => {},
  onSubmit: () => {},
}

export default CreateTicketTypeForm
