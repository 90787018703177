import React from 'react'
import { Input } from 'antd'

import InputField from '../../../../../components/InputField'

const LineLoginForm = ({ chId, input, inputChange, loading }) => {
  return (
    <div>
      <InputField title="ชื่อ Channel">
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Channel ID">
        <Input
          value={input.id}
          onChange={e => inputChange({ id: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Channel Secret">
        <Input
          value={input.channel_secret}
          onChange={e => inputChange({ channel_secret: e.target.value })}
          disabled={loading}
        />
      </InputField>
    </div>
  )
}

export default LineLoginForm
