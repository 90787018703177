import { NO_IMG, DEFAULT_IMGMAP } from './defaultValue'

export const defaultLineMsg = {
  text: {
    type: 'text',
    text: 'Text Message',
  },
  image: {
    type: 'image',
    originalContentUrl: NO_IMG,
    previewImageUrl: NO_IMG,
  },
  imagemap: {
    type: 'imagemap',
    baseUrl: DEFAULT_IMGMAP + '?=ignore',
    altText: 'This is an imagemap',
    baseSize: {
      width: 1040,
      height: 1040,
    },
    actions: [
      {
        type: 'message',
        text: 'Imagemap Clicked',
        area: {
          x: 0,
          y: 0,
          width: 1040,
          height: 1040,
        },
      },
    ],
  },
  flex: {
    type: 'flex',
    altText: 'Flex Message',
    contents: {
      type: 'bubble',
      body: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'text',
            text: 'hello',
          },
          {
            type: 'text',
            text: 'world',
          },
        ],
      },
    },
  },
}

export const defaultFacebookMsg = {
  text: {
    text: 'Text Message',
  },
  image: {
    attachment: {
      type: 'image',
      payload: {
        url: NO_IMG,
      },
    },
  },
}
