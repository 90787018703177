import React from 'react'
import PT from 'prop-types'
import { Table, Icon, Tooltip } from 'antd'
import moment from 'moment'

import NumberRenderer from '../../../components/NumberRenderer'

// const detailTableRender = e => {
//   const ds = e.detail.map((d, i) => ({ ...d, key: i }))
//   return (
//     <Table dataSource={ds} pagination={false}>
//       <Table.Column title="บอท" key="bot" dataIndex="bot" />
//       <Table.Column title="แพ็กเกจ" key="package" dataIndex="package" />
//       <Table.Column
//         title="ราคา"
//         key="amount"
//         dataIndex="amount"
//         render={amount => <NumberRenderer suffix="฿" value={amount} />}
//       />
//     </Table>
//   )
// }

const TransactionTable = ({ dataSource, height, loading }) => {
  return (
    <Table
      rowKey="timestamp"
      dataSource={dataSource}
      // expandedRowRender={detailTableRender}
      pagination={false}
      scroll={{ y: height }}
      loading={loading}
    >
      <Table.Column
        title="วันที่"
        key="timestamp"
        dataIndex="timestamp"
        width={180}
        render={timestamp => {
          const date = moment(timestamp)
          return (
            <div>
              <Tooltip title={date.format('HH:mm:ss')}>
                <Icon type="clock-circle" style={{ marginRight: 4 }} />
              </Tooltip>
              <span>{date.format('L')}</span>
            </div>
          )
        }}
      />
      <Table.Column title="รายการ" key="title" dataIndex="title" />
      <Table.Column
        title="จำนวนเครดิต"
        key="amount"
        dataIndex="amount"
        align="right"
        width={180}
        render={amount => (
          <NumberRenderer
            suffix="฿"
            value={amount}
            style={{ fontWeight: 500, color: amount >= 0 ? '#00D196' : '#FF5C5C' }}
          />
        )}
      />
    </Table>
  )
}

TransactionTable.propTypes = {
  dataSource: PT.arrayOf(
    PT.shape({
      timestamp: PT.number,
      title: PT.string,
      amount: PT.number,
      detail: PT.arrayOf(
        PT.shape({
          bot: PT.string,
          package: PT.string,
          amount: PT.number,
        }),
      ),
    }),
  ),
  height: PT.oneOfType([PT.string, PT.number]),
  loading: PT.bool,
}

export default TransactionTable
