import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import InputField from '../../../components/InputField'
import InputImage from '../../../components/InputImage'

const ImageForm = ({ botId, input, inputChange, loading, readOnly }) => {
  return (
    <>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <div style={{ width: 640, padding: '24px 36px 16px' }}>
          <InputField title="รูปกิจกรรม (ขนาด 1:1)" isRequired>
            <small
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8, color: '#B7B7B7' }}
            >
              <Icon type="info-circle" style={{ marginRight: 4 }} />
              <span>รูปภาพ .png หรือ .jpg และมีขนาดน้อยกว่า 10 MB</span>
            </small>
            <InputImage
              botId={botId}
              value={input.image_url}
              onChange={url => inputChange({ image_url: url })}
              loading={loading}
              readOnly={readOnly}
            />
          </InputField>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <div style={{ width: 640, padding: '24px 36px 16px' }}>
          <InputField title="รูปกิจกรรมสำหรับ LINE (ขนาด 1.51:1)">
            <small
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8, color: '#B7B7B7' }}
            >
              <Icon type="info-circle" style={{ marginRight: 4 }} />
              <span>รูปภาพ .png หรือ .jpg และมีขนาดน้อยกว่า 10 MB</span>
            </small>
            <InputImage
              botId={botId}
              value={input.image_url_line}
              onChange={url => inputChange({ image_url_line: url })}
              loading={loading}
              readOnly={readOnly}
            />
          </InputField>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <div style={{ width: 640, padding: '24px 36px 16px' }}>
          <InputField title="รูปกิจกรรมสำหรับ Facebook (ขนาด 1.91:1)">
            <small
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8, color: '#B7B7B7' }}
            >
              <Icon type="info-circle" style={{ marginRight: 4 }} />
              <span>รูปภาพ .png หรือ .jpg และมีขนาดน้อยกว่า 10 MB</span>
            </small>
            <InputImage
              botId={botId}
              value={input.image_url_facebook}
              onChange={url => inputChange({ image_url_facebook: url })}
              loading={loading}
              readOnly={readOnly}
            />
          </InputField>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <div style={{ width: 640, padding: '24px 36px 16px' }}>
          <InputField title="รูปพื้นหลังหน้าจอสำหรับเช็คอิน (ขนาด 9:16)" isRequired>
            <small
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8, color: '#B7B7B7' }}
            >
              <Icon type="info-circle" style={{ marginRight: 4 }} />
              <span>รูปภาพ .png หรือ .jpg และมีขนาดน้อยกว่า 10 MB</span>
            </small>
            <InputImage
              botId={botId}
              value={input.cover_url}
              onChange={url => inputChange({ cover_url: url })}
              loading={loading}
              readOnly={readOnly}
            />
          </InputField>
        </div>
      </div>
    </>
  )
}

ImageForm.propTypse = {
  botId: PT.string.isRequired,
  input: PT.shape({
    title: PT.string,
    from: PT.string,
    to: PT.string,
    venue: PT.string,
    description: PT.string,
    description_paid: PT.string,
    image_url: PT.string,
    image_url_line: PT.string,
    image_url_facebook: PT.string,
    cover_url: PT.string,
  }),
  inputChange: PT.func,
  loading: PT.bool,
  readOnly: PT.bool,
}

ImageForm.defaultProps = {
  input: {},
  inputChange: () => {},
}

export default ImageForm
