import React from 'react'
import PT from 'prop-types'
import { Tag } from 'antd'

import BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'

const AudienceInfo = ({ data, colorTheme }) => {
  return (
    <div
      style={{
        padding: '16px 36px',
        display: 'flex',
        // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <ImageRenderer
        src={data.picture_url}
        alt="profile"
        width={168}
        height={168}
        errorImg={BOT_BLANK}
        style={{
          borderRadius: '50%',
          marginRight: 24,
          borderWidth: '5px',
          borderStyle: 'solid',
          borderColor: colorTheme,
        }}
      />
      <div style={{ width: 'calc(100% - 168px - 24px)' }}>
        <div style={{ fontWeight: 600, fontSize: '1.5em', marginBottom: 8 }}>{data.full_name}</div>
        <div style={{ color: '#b7b7b7', fontSize: '0.9em', marginBottom: 16 }}>
          ID: {data.social_id}
        </div>
        <div>
          {(data.tags || []).map(t => (
            <Tag key={t} style={{ marginBottom: 8 }}>
              {t}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  )
}

AudienceInfo.propTypes = {
  data: PT.shape({
    id: PT.string,
    full_name: PT.string,
    picture_url: PT.string,
    tags: PT.arrayOf(PT.string),
    social_id: PT.string,
    platform: PT.oneOf(['FacebookBot', 'LineBot']),
    rate: PT.number,
  }),
  colorTheme: PT.string,
}

export default AudienceInfo
