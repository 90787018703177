import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'
import ReactPlayer from 'react-player'

import Button from './Button'

const Tutorial = ({ slideLink, videoLink }) => {
  const [pdfState, setPdfState] = React.useState(false)
  const [videoState, setVideoState] = React.useState(false)

  const closeAllState = () => {
    setPdfState(false)
    setVideoState(false)
  }

  const handleKeydown = e => {
    if (e.key === 'Escape') {
      closeAllState()
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeydown)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  })

  return (
    <div>
      {slideLink && (
        // <Button size="small" onClick={() => setPdfState(true)}>
        <Button size="small" onClick={() => window.open(slideLink, '_blank')}>
          คู่มือการใช้งาน
        </Button>
      )}
      {videoLink && (
        <Button
          size="small"
          style={{ marginLeft: 8 }}
          icon={<Icon type="video-camera" />}
          onClick={() => setVideoState(true)}
        >
          ดูวีดีโอสอน
        </Button>
      )}

      {pdfState && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1,
          }}
          onClick={closeAllState}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <iframe title="pdf" src={slideLink} width="724" height="543" />
            <div
              style={{ position: 'absolute', top: -32, right: 0, cursor: 'pointer' }}
              // onClick={() => setPdfState(false)}
            >
              <Icon type="close" style={{ color: 'white', fontSize: '22px' }} />
            </div>
          </div>
        </div>
      )}

      {videoState && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1,
          }}
          onClick={closeAllState}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <ReactPlayer url={videoLink} width={966} height={543} controls />
            <div
              style={{ position: 'absolute', top: -32, right: 0, cursor: 'pointer' }}
              // onClick={() => setVideoState(false)}
            >
              <Icon type="close" style={{ color: 'white', fontSize: '22px' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Tutorial.propTypes = {
  slideLink: PT.string,
  videoLink: PT.string,
}

export default Tutorial
