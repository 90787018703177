import React from 'react'
import PT from 'prop-types'
import { Table, Button, Tag, Popconfirm, Tooltip } from 'antd'
import moment from 'moment'

const RichmenuTable = ({ dataSources, loading, onDelete, onCreate, onEdit }) => {
  return (
    <Table loading={loading} dataSource={dataSources} rowKey="name" pagination={false}>
      <Table.Column
        key="status"
        width={80}
        render={(_, record) => {
          if (record.is_default) return <Tag color="#52c41a">Default</Tag>
        }}
      />
      <Table.Column
        title="Title"
        key="title"
        dataIndex="title"
        render={(title, record) =>
          record.is_default && !record.title ? (
            <div style={{ textAlign: 'center' }}>
              <i>You don't have one, create first.</i>
            </div>
          ) : (
            <span>{title}</span>
          )
        }
      />
      <Table.Column
        title="Schedule"
        key="schedule"
        dataIndex="schedule"
        render={schedule => {
          if (schedule) {
            const date = moment(schedule, 'YYYY-MM-DD HH:mm:ss')
            return (
              <div>
                <div>{date.format('HH:mm:ss')}</div>
                <div>{date.format('ddd Do MMM YYYY')}</div>
              </div>
            )
          } else {
            return '-'
          }
        }}
      />
      <Table.Column
        title="Preview"
        key="img"
        dataIndex="image_url"
        width={480}
        render={(url, record) => (
          <div style={{ padding: 8 }}>
            <img src={url} alt={record.image_url} width="100%" />
          </div>
        )}
      />
      <Table.Column
        title="Action"
        key="action"
        width={120}
        render={(_, record) => {
          if (record.is_default && !record.title) {
            return (
              <Tooltip title="Create">
                <Button loading={loading} icon="plus" shape="circle" onClick={() => onCreate()} />
              </Tooltip>
            )
          } else {
            return (
              <div>
                <Tooltip title="Edit">
                  <Button
                    onClick={() => onEdit(record)}
                    loading={loading}
                    icon="edit"
                    shape="circle"
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure?"
                    okType="danger"
                    onConfirm={() => onDelete(record)}
                  >
                    <Button loading={loading} icon="delete" shape="circle" type="danger" />
                  </Popconfirm>
                </Tooltip>
              </div>
            )
          }
        }}
      />
    </Table>
  )
}

RichmenuTable.propTypes = {
  dataSources: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      image_url: PT.string,
    }),
  ).isRequired,
  loading: PT.bool,
  onCreate: PT.func.isRequired,
  onEdit: PT.func.isRequired,
  onDelete: PT.func.isRequired,
}

RichmenuTable.defaultProps = {
  dataSources: [],
  onCreate: () => {},
  onEdit: () => {},
  onDelete: () => {},
}

export default RichmenuTable
