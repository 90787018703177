import React from 'react'
import PT from 'prop-types'
import { Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Loading from '../../components/Loading'

const ALL_RICHMESSAGE = gql`
  query allRichMessages($botId: String!) {
    allRichMessages(bot_id: $botId) {
      name
      title
      line_messages
      facebook_messages
      actions
      modified
    }
  }
`

const RichMessageEditor = ({ botId, defaultRichMessage, onMessageChange, loading }) => {
  const { data, loading: fetching, error } = useQuery(ALL_RICHMESSAGE, { variables: { botId } })

  if (error) {
    console.error(error)
  }

  if (fetching) {
    return <Loading />
  }

  const allRichMessages = (data && data.allRichMessages) || []

  const handleRichMessageChange = val => {
    const tmp = allRichMessages.find(r => r.name === val)
    const msg = tmp.line_messages[0]
    onMessageChange(msg, { richMessageId: val })
  }

  return (
    <div style={{ padding: '12px 24px' }}>
      <h4>ริชเมสเสจ</h4>
      <Select
        value={defaultRichMessage}
        style={{ width: '100%' }}
        onChange={handleRichMessageChange}
        placeholder="เลือกจากริชเมสเสจที่สร้างไว้"
        loading={loading}
      >
        {allRichMessages.map(r => (
          <Select.Option key={r.name}>{r.title}</Select.Option>
        ))}
      </Select>
    </div>
  )
}

RichMessageEditor.propTypes = {
  botId: PT.string,
  message: PT.object,
  onMessageChange: PT.func,
  defaultRichMessage: PT.string,
  loading: PT.bool,
}

RichMessageEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default RichMessageEditor
