import React from 'react'
import PT from 'prop-types'
import { GojsDiagram } from 'react-gojs'
import * as go from 'gojs'
import { Icon, Modal } from 'antd'

import Button from '../../components/Button'
import CreateKeywordForm from './CreateKeywordForm'
import './style.css'

const SHAPE_STYLE = {
  width: 120,
  stroke: '#707070',
  strokeWidth: 1,
  fill: 'white',
}

const TEXT_STYLE = {
  margin: 8,
  stroke: '#1C70B0',
  textAlign: 'center',
  overflow: go.TextBlock.OverflowEllipsis,
  maxLines: 1,
  width: 104,
}

const KeywordDiagram = ({
  onCreateKeyword,
  onDeleteKeyword,
  onNodeSelect,
  defaultSelect,
  model,
  style,
}) => {
  const [createModalState, setCreateModal] = React.useState(false)
  const [createLoading, setCreateLoading] = React.useState(false)
  const createForm = React.createRef()
  let diagram = undefined

  const handleCreateKeyword = () => {
    if (createForm.current) {
      setCreateLoading(true)
      const cb = id => {
        if (diagram) {
          var node = diagram.findNodeForKey(id)
          diagram.centerRect(node.actualBounds)
          diagram.selectCollection([node])
        }
        setCreateModal(false)
        setCreateLoading(false)
      }
      createForm.current.handleSubmit(cb)
    }
  }

  const handleDeleteKeyword = () => {
    const keywordNode = model.nodeDataArray.find(node => node.key === defaultSelect)
    Modal.confirm({
      title: `ยืนยันการลบคีย์เวิร์ด`,
      content: (
        <div>
          คีย์เวิร์ด <i>{keywordNode.title}</i> จะถูกลบอย่างถาวร
        </div>
      ),
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
      okType: 'danger',
      onOk: () => onDeleteKeyword(defaultSelect),
    })
  }

  const createDiagram = diagramId => {
    const $ = go.GraphObject.make

    const myDiagram = $(go.Diagram, diagramId, {
      initialContentAlignment: go.Spot.LeftCenter,
      initialAutoScale: go.Diagram.UniformToFill,
      contentAlignment: go.Spot.TopCenter,
      layout: $(go.TreeLayout, {
        angle: 90,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        treeStyle: go.TreeLayout.StyleLayered,
      }),
      click: clearNodeSelection,
      allowZoom: true,
    })

    myDiagram.addDiagramListener('InitialLayoutCompleted', e => {
      if (defaultSelect) {
        var node = myDiagram.findNodeForKey(defaultSelect)
        myDiagram.centerRect(node.actualBounds)
        myDiagram.selectCollection([node])
      }
    })

    myDiagram.nodeTemplate = $(
      go.Node,
      'Auto',
      $(go.Shape, 'RoundedRectangle', SHAPE_STYLE),
      $(go.TextBlock, TEXT_STYLE, new go.Binding('text', 'title')),
      {
        selectionChanged: node => handleNodeSelectChange(node.key, node.isSelected),
        selectionAdornmentTemplate: $(
          go.Adornment,
          'Auto',
          $(go.Shape, 'RoundedRectangle', { fill: '#1C70B0', strokeWidth: 0 }),
          $(
            go.TextBlock,
            { ...TEXT_STYLE, stroke: 'white', font: 'bold 12pt sans-serif' },
            new go.Binding('text', 'title'),
          ),
          $(go.Placeholder),
        ),
      },
    )

    myDiagram.linkTemplate = $(
      go.Link,
      {
        selectionChanged: clearNodeSelection,
        selectionAdorned: false,
        routing: go.Link.AvoidsNodes,
        corner: 4,
      },
      $(go.Shape, { stroke: '#707070' }),
    )

    myDiagram.toolManager.draggingTool.isEnabled = false

    diagram = myDiagram

    return myDiagram
  }

  const clearNodeSelection = () => {
    onNodeSelect('')
  }

  const handleNodeSelectChange = (key, isSelected) => {
    if (isSelected) {
      onNodeSelect(key)
    }
  }

  return (
    <div style={style}>
      <div style={{ padding: '8px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        {onCreateKeyword && (
          <Button
            style={{ marginRight: 8 }}
            icon={<Icon type="plus" />}
            onClick={() => setCreateModal(true)}
          >
            เพิ่มคีย์เวิร์ด
          </Button>
        )}
        {onDeleteKeyword && defaultSelect && (
          <Button type="danger" icon={<Icon type="delete" />} onClick={() => handleDeleteKeyword()}>
            ลบคีย์เวิร์ดที่เลือก
          </Button>
        )}
      </div>
      <div style={{ height: 240 }}>
        <GojsDiagram
          diagramId="keyword-diagram"
          model={model}
          createDiagram={createDiagram}
          className="myDiagram"
        />
      </div>

      <Modal
        visible={createModalState}
        title="สร้างคีย์เวิร์ดใหม่"
        onCancel={() => setCreateModal(false)}
        onOk={() => handleCreateKeyword()}
        okText="สร้าง"
        cancelText="ยกเลิก"
        confirmLoading={createLoading}
      >
        <CreateKeywordForm
          ref={createForm}
          submit={(input, cb) => onCreateKeyword({ title: input }, cb)}
          onSubmit={() => handleCreateKeyword()}
        />
      </Modal>
    </div>
  )
}

KeywordDiagram.propTypes = {
  model: PT.shape({
    nodeDataArray: PT.arrayOf(
      PT.shape({
        key: PT.string,
        title: PT.string,
      }),
    ),
    linkDataArray: PT.arrayOf(
      PT.shape({
        name: PT.string,
        from: PT.string,
        to: PT.string,
      }),
    ),
  }),
  defaultSelect: PT.string,
  onCreateKeyword: PT.func,
  onDeleteKeyword: PT.func,
  onNodeSelect: PT.func,
  style: PT.object,
}

KeywordDiagram.defaultProps = {
  model: { nodeDataArray: [], linkDataArray: [] },
  onNodeSelect: () => {},
}

export default KeywordDiagram
