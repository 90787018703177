import AgentGroup from '../pages/agent/AgentGroup'
import AgentList from '../pages/agent/AgentList'

export default [
  {
    path: '/list',
    page: AgentList,
  },
  {
    path: '/group',
    page: AgentGroup,
  },
  {
    path: '/:agentId',
    page: null,
  },
]
