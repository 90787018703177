import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message } from 'antd'

import ConfigForm from './ConfigForm'
import Loading from '../../../components/Loading'

const GET_CONFIG = gql`
  query getWisibleConfig($botId: String!) {
    getWisibleConfig(bot_id: $botId) {
      enabled
      access_token
    }
  }
`

const UPDATE_CONFIG = gql`
  mutation updateWisibleConfig($botId: String!, $input: WisibleConfigInput!) {
    updateWisibleConfig(bot_id: $botId, input: $input)
  }
`

const WisibleConfig = ({ match }) => {
  const botId = match.params.botId
  const [loading, setLoading] = React.useState(false)
  const { data, error, loading: queryLoading } = useQuery(GET_CONFIG, { variables: { botId } })
  const [updateConfig] = useMutation(UPDATE_CONFIG)

  if (queryLoading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  const wisibleConfig = (data && data.getWisibleConfig) || {}

  const handleFormSubmit = async input => {
    setLoading(true)
    try {
      await updateConfig({ variables: { botId, input } })
      message.success('บันทึก')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <h1>ตั้งค่าการเชื่อมต่อ</h1>
      </div>
      <div style={{ width: 520, padding: '16px 32px' }}>
        <ConfigForm data={wisibleConfig} onSubmit={handleFormSubmit} loading={loading} />
      </div>
    </div>
  )
}

export default WisibleConfig
