import React from 'react'
import PT from 'prop-types'
import { useDrag } from 'react-dnd'

import { ItemTypes } from './dndConstants'
import { Icon } from 'antd'

const TagItem = ({ owner, tag, style, danger, onDrop, onDelete }) => {
  const [isHover, setHover] = React.useState(false)
  const [{ isDragging }, dragRef] = useDrag({
    item: { owner, tag, type: ItemTypes.TAG },
    end: (item, monitor) => {
      onDrop(item, (monitor.getDropResult() || {}).zone)
    },
    collect: mon => ({
      isDragging: !!mon.isDragging(),
    }),
  })

  return (
    <div
      ref={dragRef}
      style={{
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #707070',
        borderRadius: 4,
        textAlign: 'center',
        padding: 8,
        marginBottom: 8,
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: isDragging ? 0.5 : 1,
        ...style,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span style={{ fontWeight: 600, color: danger ? '#DB1540' : '#1C70B0' }}>{tag}</span>
      {isHover && onDelete && (
        <Icon
          onClick={onDelete}
          type="close"
          style={{
            position: 'absolute',
            top: '50%',
            right: 8,
            transform: 'translateY(-50%)',
            color: 'red',
            cursor: 'pointer',
          }}
        />
      )}
    </div>
  )
}

TagItem.propTypes = {
  owner: PT.shape({
    segment: PT.string,
    key: PT.oneOf(['includes', 'excludes']),
  }),
  tag: PT.string,
  style: PT.object,
  danger: PT.bool,
  onDrop: PT.func,
}

TagItem.defaultProps = {
  onDrop: () => {},
}

export default TagItem
