import React from 'react'

import ChannelList from './list'
import ChannelEditor from './editor'
import Tutorial from '../../../components/Tutorial'

const Channel = ({ match }) => {
  const botId = match.params.botId
  const [selected, selectedChange] = React.useState('')
  return (
    <div style={{ height: '100%' }}>
      <div style={{ display: 'flex', height: 'calc(100% - 42px)' }}>
        <div
          style={{ width: 420, borderRight: '1px solid rgba(0, 0, 0, 0.15)', overflowY: 'auto' }}
        >
          <ChannelList selectedChannel={selected} onChannelSelect={selectedChange} botId={botId} />
        </div>
        <div style={{ width: 'calc(100% - 420px)', overflowY: 'auto' }}>
          <ChannelEditor chId={selected} selectChannel={selectedChange} botId={botId} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          slideLink="https://drive.google.com/file/d/1aiHl9-IVL1DAltlf92Lzc5xLIqAXjhut/view"
          videoLink="https://youtu.be/jaA6p6eeZwE"
        />
      </div>
    </div>
  )
}

export default Channel
