export function queryStrToObj(str) {
  if (!str) {
    return {}
  }
  const tmp = str[0] === '?' ? str.substr(1) : str
  const arr = tmp.split('&')
  let obj = {}
  arr.forEach(item => {
    const pair = item.split('=')
    obj = { ...obj, [pair[0]]: pair[1] }
  })
  return obj
}

export function objToQueryStr(obj) {
  if (!obj) {
    return ''
  }
  const keys = Object.keys(obj)
  const arr = keys.map(key => (obj[key] ? `${key}=${obj[key]}` : null)).filter(item => item != null)
  let str = `?${arr.join('&')}`
  return str
}
