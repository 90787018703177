import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'

import InputField from '../../components/InputField'

class CreateKeywordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
    }
  }

  handleSubmit = cb => {
    const { submit } = this.props
    const { input } = this.state
    if (input !== '') {
      submit(input, id => {
        cb(id)
        this.setState({ input: '' })
      })
    }
  }

  render() {
    const { onSubmit } = this.props
    const { input } = this.state
    return (
      <div>
        <InputField title="ชื่อคีย์เวิร์ด" isRequired>
          <Input
            value={input}
            onChange={e => this.setState({ input: e.target.value })}
            onPressEnter={onSubmit}
            autoFocus
          />
        </InputField>
      </div>
    )
  }
}

CreateKeywordForm.propTypes = {
  submit: PT.func,
  onSubmit: PT.func,
}

CreateKeywordForm.defaultProps = {
  submit: () => {},
  onSubmit: () => {},
}

export default CreateKeywordForm
