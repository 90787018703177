import React, { useContext, useState } from 'react'
import { Icon, Modal, Input } from 'antd'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import BotContext from '../../../../context/Bot'
import Button from '../../../../components/Button'
// import ProgressBar from '../../../../components/ProgressBar'
// import NumberRenderer from '../../../../components/NumberRenderer'
import Tutorial from '../../../../components/Tutorial'
import Loading from '../../../../components/Loading'
import InputField from '../../../../components/InputField'
import BroadcastListCard from './BroadcastListCard'

const ALL_BROADCAST = gql`
  query allBroadcast($botId: String!) {
    allBroadcast(bot_id: $botId) {
      id
      title
      schedule
      status
    }
  }
`

const CREATE_BROADCAST = gql`
  mutation createBroadcast($botId: String!, $title: String!) {
    createBroadcast(bot_id: $botId, title: $title) {
      id
    }
  }
`

const SUBSCRIBE_BROADCAST = gql`
  subscription onBroadcastChanged($botId: String!) {
    onBroadcastChanged(bot_id: $botId)
  }
`

const BroadcastList = ({ history }) => {
  const bot = useContext(BotContext)
  const [modalState, setModal] = useState(false)
  const [inputTitle, setInputTitle] = useState('')
  const { data, loading, error, subscribeToMore } = useQuery(ALL_BROADCAST, {
    variables: { botId: bot.name },
  })
  const [createBroadcast, { loading: createLoading }] = useMutation(CREATE_BROADCAST)

  const [getBroadcast] = useLazyQuery(ALL_BROADCAST, { fetchPolicy: 'network-only' })

  React.useEffect(() => {
    let unsub = () => {}
    const subscribeFriendDoc = () => {
      unsub = subscribeToMore({
        document: SUBSCRIBE_BROADCAST,
        variables: { botId: bot.name },
        updateQuery: async (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          const newSubData = subscriptionData.data.onBroadcastChanged || {}
          getBroadcast({
            variables: { botId: bot.name, bcId: newSubData.id },
          })
          return prev
        },
      })
    }

    subscribeFriendDoc()
    return () => {
      unsub()
    }
  }, [bot, subscribeToMore, getBroadcast])

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error.message)
  }

  const allBroadcast = (data && data.allBroadcast) || []

  const handleCreateBroadcast = async () => {
    try {
      const { data } = await createBroadcast({
        variables: { botId: bot.name, title: inputTitle },
      })
      setInputTitle('')
      setModal(false)
      const newBroadcast = (data && data.createBroadcast) || {}
      history.push(`/app/bot/${bot.name}/main/broadcast/${newBroadcast.id}`)
    } catch (err) {
      console.error(err.message)
    }
  }

  const gotoBroadcast = id => {
    history.push(`/app/bot/${bot.name}/main/broadcast/${id}`)
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginBottom: 0 }}>บรอดแคสต์</h1>
          <Button
            type="primary"
            ghost
            loading={createLoading}
            onClick={() => setModal(true)}
            icon={<Icon type="plus" />}
          >
            สร้างใหม่
          </Button>
        </div>
        {/* <div style={{ marginBottom: 8 }}>
          <span>จำนวนข้อความที่ส่ง</span>
          <NumberRenderer
            style={{ margin: '0px 8px', color: '#1C70B0', fontWeight: 600 }}
            value={0}
          />
          <span>ข้อความ</span>
        </div> */}
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: 24,
            marginBottom: 16,
          }}
        >
          <Icon type="notification" style={{ marginRight: 8 }} />
          <div style={{ width: 'calc(100% - 22px)', height: '100%' }}>
            <ProgressBar value={0} max={0} />
          </div>
        </div> */}
      </div>
      <div
        style={{
          padding: 32,
          height: 'calc(100% - 83px - 42px)',
          display: 'grid',
          gridGap: '24px 24px',
          gridTemplateColumns: '50%',
          gridTemplateRows: '50%',
        }}
      >
        <BroadcastListCard
          style={{ gridColumn: 1, gridRow: 1 }}
          title={<span style={{ color: '#FFB900' }}>กำลังส่ง</span>}
          data={allBroadcast.filter(b => b.status === 'SENDING')}
          onItemClick={id => gotoBroadcast(id)}
        />
        <BroadcastListCard
          style={{ gridColumn: 2, gridRow: 1 }}
          title={<span style={{ color: '#B7B7B7' }}>แบบร่าง</span>}
          data={allBroadcast.filter(b => b.status === 'DRAFT')}
          onItemClick={id => gotoBroadcast(id)}
        />
        <BroadcastListCard
          style={{ gridColumn: 1, gridRow: 2 }}
          title={<span style={{ color: '#32B159' }}>ตั้งเวลาส่ง</span>}
          data={allBroadcast.filter(b => b.status === 'READY')}
          onItemClick={id => gotoBroadcast(id)}
        />
        <BroadcastListCard
          style={{ gridColumn: 2, gridRow: 2 }}
          title={<span style={{ color: '#2592EF' }}>ส่งเสร็จแล้ว</span>}
          data={allBroadcast.filter(b => b.status === 'SENT')}
          onItemClick={id => gotoBroadcast(id)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          slideLink="https://drive.google.com/file/d/1jg4192JohXrWMbGp3oABdkensWSQM9NO/view"
          videoLink="https://youtu.be/sgWXlca_YgU"
        />
      </div>

      <Modal
        visible={modalState}
        title="สร้างบรอดแคสต์ใหม่"
        onCancel={() => setModal(false)}
        onOk={() => handleCreateBroadcast()}
        okText="สร้าง"
        cancelText="ยกเลิก"
        confirmLoading={createLoading}
      >
        <InputField title="ชื่อบรอดแคสต์">
          <Input
            autoFocus
            value={inputTitle}
            onChange={e => setInputTitle(e.target.value)}
            onPressEnter={() => handleCreateBroadcast()}
          />
        </InputField>
      </Modal>
    </div>
  )
}

export default BroadcastList
