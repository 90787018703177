import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'
import moment from 'moment'

import NO_IMG from '../../../assets/images/no_img.png'
import ImageRenderer from '../../../components/ImageRenderer'

const EventCard = ({ data, style, onClick }) => {
  return (
    <div
      style={{
        width: 300,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        marginRight: 16,
        marginBottom: 16,
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
    >
      <ImageRenderer
        src={data.image_url}
        errorImg={NO_IMG}
        alt="event_img"
        style={{ width: 300, height: 300 }}
      />
      <div style={{ padding: '8px 16px' }}>
        <div>
          <h3 style={{ fontWeight: 600, color: '#1C70B0' }}>{data.title}</h3>
        </div>
        <div>
          <Icon type="calendar" style={{ marginRight: 12 }} />
          <span>{data.from ? moment(data.from).format('DD / MM / YYYY') : '-'}</span>
        </div>
        <div style={{ marginTop: 4 }}>
          <Icon type="clock-circle" style={{ marginRight: 12 }} />
          <span>
            {data.to && data.from
              ? `${moment(data.from).format('HH:mm')} - ${moment(data.to).format('HH:mm')}`
              : '-'}
          </span>
        </div>
        <div style={{ display: 'flex', marginTop: 4 }}>
          <Icon type="environment" style={{ marginRight: 12 }} />
          <div className="text-wrap-ellipsis" style={{ width: 'calc(100% - 26px)' }}>
            {data.venue}
          </div>
        </div>
      </div>
    </div>
  )
}

EventCard.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    image_url: PT.string,
    from: PT.string,
    to: PT.string,
    venue: PT.string,
  }),
  style: PT.object,
  onClick: PT.func,
}

EventCard.defaultProps = {
  onClick: () => {},
}

export default EventCard
