import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Input, Spin, Icon } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

import BotCard from './BotCard'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import NumberRenderer from '../../../components/NumberRenderer'
import { objToQueryStr } from '../../../helpers/queryStr'

const ALL_BOTS = gql`
  query allBots($filters: BotFilter, $page: PaginationInput) {
    allBots(filters: $filters, page: $page) {
      total
      bots {
        name
        title
        image
      }
      pagination {
        next
        size
      }
    }
  }
`

const GET_PERMISSION = gql`
  query getUserPermissions($userId: String!) {
    getUserPermissions(user_id: $userId) {
      user
      role
      for_value
    }
  }
`

const BotList = ({ history }) => {
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState({ offset: 0, size: 36 })
  const [moreLoading, setMoreLoading] = React.useState(false)

  const { data, loading, error, fetchMore } = useQuery(ALL_BOTS, {
    variables: {
      filters: { search },
      page,
    },
  })
  const { data: getPermissionData, loading: getPermissionLoading } = useQuery(GET_PERMISSION, {
    variables: { userId: localStorage.getItem('user_id') },
  })

  const getUserPermissions = (getPermissionData && getPermissionData.getUserPermissions) || []

  const getEnv = () => {
    if (window.location.hostname === 'localhost') {
      return 'LOCAL'
    } else if (window.location.host.substr(0, 4) === 'uat-') {
      return 'UAT'
    } else if (window.location.host.substr(0, 4) === 'dev-') {
      return 'DEV'
    } else {
      return 'PRODUCTION'
    }
  }

  const openChat = botId => {
    const accessToken = localStorage.getItem('access_token')
    const userId = localStorage.getItem('user_id')
    const paramToSend = {
      destination: `/${botId}/chat`,
      access_token: accessToken,
      user_id: userId,
    }
    if (window.location.origin.includes('manager')) {
      if (getEnv() === 'DEV') {
        window.open(`https://dev-chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      } else if (getEnv() === 'UAT') {
        window.open(`https://uat-chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      } else {
        window.open(`https://chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      }
    } else {
      window.open(`https://chat-shell.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
    }
  }

  const goCreateBot = () => {
    history.push('/app/bot/create')
  }

  const goMenu = botId => {
    history.push(`/app/bot/${botId}`)
  }

  const handleCardClick = botId => {
    const botPermission = getUserPermissions.find(p => p.for_value === botId)
    if (botPermission && botPermission.role === 'Agent') {
      openChat(botId)
    } else {
      goMenu(botId)
    }
  }

  const handleScroll = value => {
    if (value.top === 1) {
      onLoadMore()
    }
  }

  const handleSearch = s => {
    setSearch(s)
    setPage({ offset: 0, size: page.size })
  }

  if (error) {
    console.error(error.message)
  }

  const allBots = (data && data.allBots) || {}
  const bots = allBots.bots || []
  const pagination = allBots.pagination || {}

  const onLoadMore = async () => {
    if (pagination.next == null) {
      return
    }
    setMoreLoading(true)
    await fetchMore({
      query: ALL_BOTS,
      variables: {
        filters: { search },
        page: { offset: pagination.next, size: pagination.size },
        userId: localStorage.getItem('user_id'),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newAllBots = fetchMoreResult.allBots
        return {
          ...prev,
          allBots: {
            ...newAllBots,
            bots: [...bots, ...newAllBots.bots],
          },
        }
      },
    })
    setMoreLoading(false)
  }

  return (
    <>
      <div
        style={{
          padding: '24px 36px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {search ? (
            <h1 style={{ marginBottom: 0, marginRight: 8 }}>ผลการค้นหา {search}</h1>
          ) : (
            <h1 style={{ marginBottom: 0, marginRight: 8 }}>รายชื่อบอททั้งหมด</h1>
          )}
          <span style={{ marginBottom: 4 }}>
            (จำนวนบอท: <NumberRenderer value={allBots.total} />)
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <Input.Search
              style={{ width: 180 }}
              placeholder="ค้นหาตามชื่อ.."
              onSearch={handleSearch}
            />
          </div>
          <Button
            onClick={goCreateBot}
            type="primary"
            style={{ padding: '0 24px', marginLeft: 8 }}
            icon={<Icon type="plus" />}
          >
            เพิ่มบอท
          </Button>
        </div>
      </div>
      <Scrollbars onScrollFrame={handleScroll} style={{ height: 'calc(100% - 83px)' }}>
        {loading || getPermissionLoading ? (
          <Loading />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                paddingTop: 24,
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              {bots.map(bot => (
                <BotCard key={bot.name} data={bot} onClick={botId => handleCardClick(botId)} />
              ))}
            </div>
            {moreLoading && pagination.next && <Spin style={{ margin: '32px 0' }} />}
          </div>
        )}
      </Scrollbars>
    </>
  )
}

export default BotList
