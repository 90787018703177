import React from 'react'

import LeadsSideMenu from './LeadsSideMenu'
import LeadsInfoDisplay from './LeadsInfoDisplay'

const LeadsDetail = () => {
  const [currMenu, setMenu] = React.useState('friendInfo')
  const mockData = {
    id: '1575267672_U45f131e0efe9f96fc3a68ad217712a00',
    full_name: 'มอม้า สระอุ',
    picture_url:
      'https://profile.line-scdn.net/0hWtL-sphfCFtxVCM4cBF3DE0RBjYGeg4TCTASPgAEUD4MMEkPGDIXbldSX2ldN0YIGTcSaVMHVT8M',
  }
  return (
    <div style={{ height: '100%' }}>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <LeadsInfoDisplay data={mockData} />
      </div>
      <div style={{ height: 'calc(100% - 252px - 56px)' }}>
        <div style={{ height: '100%', width: 280, borderRight: '1px solid rgba(0, 0, 0, 0.05)' }}>
          <LeadsSideMenu selected={currMenu} onSelect={tab => setMenu(tab)} />
        </div>
      </div>
    </div>
  )
}

export default LeadsDetail
