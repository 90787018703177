import ApolloClient from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import fetch from 'node-fetch'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
let baseUrl = 'http://localhost:20000'
let socketUrl = 'ws://localhost:20000/graphql'

let { host } = window.location
if (process.env.NODE_ENV !== 'development') {
  baseUrl = `${window.location.protocol}//${host}/api`
  socketUrl = `${protocolPrefix}//${host}/api/graphql`
}

baseUrl = `https://manager-api.aiya.ai/api`
socketUrl = `https://manager-api.aiya.ai/api/graphql`

// let baseUrl = 'https://2a28b31b.ngrok.io'
// let baseUrl = 'http://localhost:20000'
// let baseUrl = 'https://manager.aiya.ai/api'
// let baseUrl = '/api'

const uploadLink = createUploadLink({ uri: baseUrl })

const wsLink = new WebSocketLink({
  fetch,
  uri: socketUrl,
  options: {
    reconnect: true,
  },
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  }
})

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink,
)

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: obj => {
      if (obj.__typename === 'Channel') return obj.name
      return defaultDataIdFromObject(obj)
    },
  }),
  link: link
    .concat(
      onError(({ response }) => {
        const errMsg = response && response.errors[0].message
        console.log('global error', errMsg)
        if (errMsg === 'Not Authorized') {
          window.location.href = `${window.location.origin}/login`
        }
      }),
    )
    .concat(uploadLink),
})

export { client }
