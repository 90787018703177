import React from 'react'
import { Input, Icon } from 'antd'
import PT from 'prop-types'

import InputField from '../../../../components/InputField'

const KeywordTraining = ({ loading, sentences, addSentence, removeSentence }) => {
  const [input, setInput] = React.useState('')
  const [hovered, setHover] = React.useState('')
  return (
    <div style={{ display: 'flex', height: '100%', minHeight: '240px' }}>
      <div style={{ width: 420, borderRight: '1px solid rgba(0, 0, 0, 0.15)', padding: '24px' }}>
        <InputField title="คีย์เวิร์ด">
          <Input.Search
            value={input}
            onChange={e => setInput(e.target.value)}
            enterButton={<Icon type="plus" />}
            onSearch={s => {
              addSentence(s)
              setInput('')
            }}
            disabled={loading}
            loading={loading}
          />
        </InputField>
      </div>
      <div style={{ width: 360, borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}>
        <div>
          <div style={{ padding: '24px 8px 8px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <h4>คีย์เวิร์ดที่เรียนรู้แล้ว</h4>
          </div>
          {sentences.map(s => (
            <div
              key={s}
              onMouseEnter={() => setHover(s)}
              onMouseLeave={() => setHover('')}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{ width: 'calc(100% - 24px)' }}>{s}</div>
              <div
                style={{
                  width: 24,
                  position: 'relative',
                  cursor: 'pointer',
                  color: 'red',
                  display: hovered === s ? 'block' : 'none',
                }}
                onClick={() => removeSentence(s)}
              >
                <Icon
                  type="close-circle"
                  theme="filled"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

KeywordTraining.propTypes = {
  loading: PT.bool,
  sentences: PT.arrayOf(PT.string),
  addSentence: PT.func,
  removeSentence: PT.func,
}

KeywordTraining.defaultProps = {
  addSentence: () => {},
  removeSentence: () => {},
}

export default KeywordTraining
