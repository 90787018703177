import React from 'react'
import PT from 'prop-types'

import PHONE_MOCKUP from '../../../../assets/images/phone_mockup.png'
import ImageRenderer from '../../../../components/ImageRenderer'

const RichmenuPreview = ({ imageUrl, chatBarText }) => {
  return (
    <div style={{ width: '100%', maxWidth: 680, height: 'inherit' }}>
      <div style={{ margin: '0 auto', width: 300, position: 'relative' }}>
        <ImageRenderer src={PHONE_MOCKUP} alt="phone" width={300} height={560} />
        {imageUrl && (
          <ImageRenderer
            src={imageUrl}
            alt="richmenu"
            width={300 - 50}
            style={{ position: 'absolute', bottom: 69, left: 24, right: 26 }}
          />
        )}
        <span
          style={{
            position: 'absolute',
            bottom: 40,
            left: '53%',
            transform: 'translateX(-50%)',
            color: 'white',
            fontWeight: 500,
          }}
        >
          {chatBarText}
        </span>
      </div>
    </div>
  )
}

RichmenuPreview.propTypes = {
  imageUrl: PT.string,
  chatBarText: PT.string,
}

RichmenuPreview.defaultProps = {
  chatBarText: 'MENU',
}

export default RichmenuPreview
