import { client } from '../api/apollo'
import gql from 'graphql-tag'

const appId = process.env.NODE_ENV === 'production' ? '545893845591153' : '545893845591153'

let hasInitial = false

export function initialFbSdk(cb) {
  if (hasInitial) {
    cb && cb()
    return null
  }
  window.fbAsyncInit = function() {
    window.FB.init({
      appId,
      cookie: true,
      xfbml: true,
      version: 'v3.2',
    })

    hasInitial = true
    window.FB.AppEvents.logPageView()
    cb && cb()
  }
  ;(function(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

export function getLoginStatus(cb) {
  window.FB.getLoginStatus(cb)
}

export function login(cb) {
  window.FB.login(
    res => {
      if (res.status === 'connected') {
        const accessToken = res.authResponse.accessToken
        const UPDATE_FB = gql`
          mutation($accessToken: String!) {
            updateFacebookProfile(user_access_token: $accessToken) {
              name
            }
          }
        `
        client.mutate({
          mutation: UPDATE_FB,
          variables: { accessToken },
        })
      }
      cb && cb(res)
    },
    {
      scope:
        'public_profile,manage_pages,email,pages_messaging,read_page_mailboxes,publish_pages,pages_messaging_subscriptions',
    },
  )
}

export function logout() {
  window.FB.logout()
}

export function getUserData(cb) {
  window.FB.api('/me', res => {
    cb(res)
  })
}

export function getUserAccount(cb) {
  window.FB.api('/me/accounts?limit=100', res => {
    cb(res)
  })
}

export function getPagePicture(id, cb) {
  window.FB.api(`/${id}/picture?width=240&height=240&redirect=false`, res => {
    cb(res)
  })
}

export default {
  initialFbSdk,
  getLoginStatus,
  login,
  logout,
  getUserData,
  getUserAccount,
  getPagePicture,
}
