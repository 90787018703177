import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import CampaignSidenav from '../layouts/sidenav/CampaignSidenav'
import CampaignList from '../pages/campaign/CampaignList/index'
import CreateCampaign from '../pages/campaign/CreateCampaign/index'

const CampaignContainer = props => {
  const initPath = props.match.path
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div style={{ width: 280, height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
        <CampaignSidenav routeProps={props} />
      </div>
      <div
        style={{
          width: 'calc(100% - 280px)',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Switch>
          <Redirect from={initPath} to={initPath + '/list'} exact />
          <Route path={initPath + '/list'} component={CampaignList} />
          <Route path={initPath + '/:bcId'} component={CreateCampaign} />
        </Switch>
      </div>
    </div>
  )
}

export default CampaignContainer
