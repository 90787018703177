import React from 'react'
import PT from 'prop-types'

import { PT_AGENT } from './propTypes'
import AIYA_BLANK from '../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../components/ImageRenderer'

const AgentCard = ({ data, style, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: 480,
        height: 100,
        backgroundColor: 'white',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        cursor: onClick ? 'pointer' : 'inherit',
        ...style,
      }}
      onClick={onClick && (() => onClick(data))}
    >
      <div style={{ width: 100, height: 100, padding: 14 }}>
        <ImageRenderer
          style={{ width: 72, height: 72, borderRadius: '50%' }}
          src={data.picture_url || AIYA_BLANK}
          alt={data.name}
          errorImg={AIYA_BLANK}
        />
      </div>
      <div style={{ width: 'calc(100% - 100px)', padding: 14 }}>
        <div>
          <div className="text-wrap-ellipsis" style={{ fontSize: '1.4em' }}>
            {data.title}
          </div>
        </div>
        <div style={{ marginBottom: 4 }}>
          <div
            className="text-wrap-ellipsis"
            style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}
          >
            ID: {data.name}
          </div>
        </div>
      </div>
    </div>
  )
}

AgentCard.propTypes = {
  onClick: PT.func,
  data: PT_AGENT,
  style: PT.object,
}

export default AgentCard
