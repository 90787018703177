import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'

const CARD_SIZE = 180

const TemplateCard = ({ data, isSelected, onClick }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        width: CARD_SIZE,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        boxSizing: 'content-box',
        borderColor: isSelected ? '#00D196' : 'rgba(0, 0, 0, 0.1)',
        color: isSelected && '#00D196',
        cursor: 'pointer',
        marginRight: 8,
        borderRadius: 4,
        overflow: 'hidden',
        flex: '0 0 auto',
      }}
      onClick={onClick}
    >
      <ImageRenderer
        src={data.imageUrl}
        errorImg={BOT_BLANK}
        alt={data.title}
        width={CARD_SIZE}
        height={CARD_SIZE}
        style={{
          cursor: 'pointer',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          borderColor: isSelected ? '#00D196' : 'rgba(0, 0, 0, 0.1)',
        }}
      />
      <div style={{ padding: '4px 8px' }}>
        {isSelected && <Icon theme="filled" type="check-circle" style={{ marginRight: 8 }} />}
        <span style={{ fontSize: 14, fontWeight: 600 }}>{data.title}</span>
      </div>
    </div>
  )
}

TemplateCard.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    imageUrl: PT.string,
  }),
  isSelected: PT.bool,
  onClick: PT.func,
}

TemplateCard.defaultProps = {
  onClick: () => {},
}

export default TemplateCard
