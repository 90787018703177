import React from 'react'
import { Input, Select, Spin } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

import LeadsCard from './LeadsCard'
import Loading from '../../../components/Loading'
import NoData from '../../../components/NoData'

const LeadsList = ({ history, match }) => {
  const botId = match.params.botId
  const [moreLoading, setMoreLoading] = React.useState(false)
  const [page, setPage] = React.useState({ next: '', size: 40 })
  const [filters, setFilters] = React.useState({ search: '', status: '' })

  const leads = []

  const openDetail = leadsId => {
    history.push(`/app/bot/${botId}/sales/leads/${leadsId}`)
  }

  const handleScroll = value => {
    if (value.top === 1) {
      onLoadMore()
    }
  }

  const handleSearch = s => {
    setFilters({ ...filters, search: s })
    setPage({ next: '', size: page.size })
  }

  const handleStatusChange = status => {
    setFilters({ ...filters, status })
    setPage({ next: '', size: page.size })
  }

  const onLoadMore = () => {
    // if (pagination.next == null) {
    //   return
    // }
    setMoreLoading(true)
    try {
      console.log('loadmore')
    } catch (err) {
      console.error(err)
    }
    setMoreLoading(false)
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>รายชื่อ Leads</h1>
        <div>
          <Select
            onChange={handleStatusChange}
            style={{ width: 150, marginRight: 8 }}
            defaultValue="all"
          >
            <Select.Option key="all">สถานะทั้งหมด</Select.Option>
            <Select.Option key="leads">Leads</Select.Option>
            <Select.Option key="hot_leads">Hot Leads</Select.Option>
          </Select>
          <Input.Search
            onSearch={handleSearch}
            style={{ width: 180 }}
            placeholder="ค้นหาเพื่อน.."
          />
        </div>
      </div>
      {false ? (
        <Loading />
      ) : (
        <Scrollbars onScrollFrame={handleScroll} style={{ height: 'calc(100% - 97px)' }}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', padding: 16 }}
          >
            {leads.length ? (
              leads.map(l => <LeadsCard key={l.id} data={l} onClick={l => openDetail(l.id)} />)
            ) : (
              <NoData />
            )}
          </div>
          {/* {moreLoading && pagination.next && ( */}
          {moreLoading && (
            <div style={{ textAlign: 'center' }}>
              <Spin style={{ margin: '32px 0' }} />
            </div>
          )}
        </Scrollbars>
      )}
    </div>
  )
}

export default LeadsList
