import React from 'react'
import { Tabs, message, Switch } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import BotReply from './BotReply'
import KeywordTraining from './KeywordTraining'
import Loading from '../../../../components/Loading'

// for refetch only
const ALL_KEYWORDS = gql`
  query allKeywordWithLinks($botId: String!) {
    allKeywords(bot_id: $botId, source: MAP) {
      name
      title
    }
    allKeywordLinks(bot_id: $botId) {
      name
      from
      to
    }
  }
`

const GET_KEYWORD = gql`
  query getKeyword($keywordId: String!) {
    getKeyword(keyword_id: $keywordId) {
      name
      title
      published
      sentences {
        text
      }
      bot_action {
        name
        facebook_messages
        line_messages
      }
    }
  }
`

const UPDATE_KEYWORD = gql`
  mutation updateKeyword($keywordId: String!, $input: KeywordInput!) {
    updateKeyword(keyword_id: $keywordId, input: $input) {
      name
    }
  }
`

const UPDATE_ACTION = gql`
  mutation updateAction($actionId: String!, $input: BotActionInput!) {
    updateAction(action_id: $actionId, input: $input) {
      name
    }
  }
`

const ADD_SENTENCE = gql`
  mutation addSentence($keywordId: String!, $input: String!) {
    addSentence(keyword_id: $keywordId, input: $input)
  }
`

const REMOVE_SENTENCE = gql`
  mutation removeSentence($keywordId: String!, $input: String!) {
    removeSentence(keyword_id: $keywordId, input: $input)
  }
`

const KeywordEditor = ({
  botId,
  keyword,
  updateKeywordMutation,
  updateActionMutation,
  addSentenceMutation,
  removeSentenceMutation,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [currPlatform, setPlatform] = React.useState('LineBot')

  const updateKeyword = async input => {
    if (input.title === 'Get Started') {
      message.error('ไม่สามารถเปลี่ยนชื่อคีย์เวิร์ดเป็น Get Started ได้')
      return
    }
    setLoading(true)
    try {
      await updateKeywordMutation({ variables: { keywordId: keyword.name, input } })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const updateAction = async input => {
    setLoading(true)
    const stop = message.loading('กำลังบันทึก...', 0)
    try {
      await updateActionMutation({ variables: { actionId: keyword.bot_action.name, input } })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    stop()
    setLoading(false)
  }

  const addSentence = async input => {
    setLoading(true)
    try {
      await addSentenceMutation({ variables: { keywordId: keyword.name, input } })
      message.success('เพิ่มคีย์เวิร์ดสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const removeSentence = async input => {
    setLoading(true)
    try {
      await removeSentenceMutation({ variables: { keywordId: keyword.name, input } })
      message.success('ลบคีย์เวิร์ดสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <div>
      <Tabs
        type="card"
        tabBarStyle={{ padding: '32px 8px 0', backgroundColor: '#1C70B0', marginBottom: 0 }}
        tabBarExtraContent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8, color: 'white', fontWeight: 600 }}>
              {currPlatform === 'LineBot' && <span>Line</span>}
              {currPlatform === 'FacebookBot' && <span>Facebook</span>}
            </div>
            <Switch
              className="platform-switch"
              checked={currPlatform === 'LineBot'}
              onChange={e => setPlatform(e ? 'LineBot' : 'FacebookBot')}
            />
          </div>
        }
      >
        <Tabs.TabPane tab="รายละเอียด" key="1">
          <BotReply
            botId={botId}
            platform={currPlatform}
            keyword={keyword}
            updateAction={updateAction}
            updateKeyword={updateKeyword}
            loading={loading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="สอนคีย์เวิร์ด" key="2">
          <KeywordTraining
            loading={loading}
            sentences={keyword.sentences.map(s => s.text)}
            addSentence={addSentence}
            removeSentence={removeSentence}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const keywordId = props.match.params.keywordId

  const { data, error, loading } = useQuery(GET_KEYWORD, { variables: { keywordId } })
  const [updateKeyword] = useMutation(UPDATE_KEYWORD, {
    refetchQueries: [
      { query: ALL_KEYWORDS, variables: { botId } },
      { query: GET_KEYWORD, variables: { keywordId } },
    ],
  })
  const [updateAction] = useMutation(UPDATE_ACTION, {
    refetchQueries: [{ query: GET_KEYWORD, variables: { keywordId } }],
  })
  const [addSentence] = useMutation(ADD_SENTENCE, {
    refetchQueries: [{ query: GET_KEYWORD, variables: { keywordId } }],
  })
  const [removeSentence] = useMutation(REMOVE_SENTENCE, {
    refetchQueries: [{ query: GET_KEYWORD, variables: { keywordId } }],
  })
  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }
  const keyword = (data && data.getKeyword) || {}
  // console.log(keyword)

  return (
    <KeywordEditor
      {...props}
      botId={botId}
      keyword={keyword}
      addSentenceMutation={addSentence}
      removeSentenceMutation={removeSentence}
      updateActionMutation={updateAction}
      updateKeywordMutation={updateKeyword}
    />
  )
}
