import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const MenuItem = styled.span`
  padding: 10.5px 24px;
  cursor: pointer;
  &:hover {
    font-weight: 500;
    color: ${({ color }) => color || 'rgba(0, 0, 0, 0.65)'};
    border-bottom: 3px solid ${({ color }) => color || 'rgba(0, 0, 0, 0.65)'};
  }
  ${({ selected, color }) =>
    selected &&
    `
  font-weight: 500;
    color: ${color || 'rgba(0, 0, 0, 0.65)'};
    border-bottom: 3px solid ${color || 'rgba(0, 0, 0, 0.65)'};
  `}
`

const AudienceMenuTab = ({ colorTheme, menus, selected, onSelect }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        height: 42,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      {menus.map(m => (
        <MenuItem
          key={m.key}
          color={colorTheme}
          onClick={() => onSelect(m.key)}
          selected={m.key === selected}
        >
          {m.title}
        </MenuItem>
      ))}
    </div>
  )
}

AudienceMenuTab.propTypes = {
  colorTheme: PT.string,
  menus: PT.arrayOf(
    PT.shape({
      key: PT.string,
      title: PT.string,
    }),
  ),
  selected: PT.string,
  onSelect: PT.func,
}

AudienceMenuTab.defaultProps = {
  onSelect: () => {},
  menus: [],
}

export default AudienceMenuTab
