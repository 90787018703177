import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'

const TextEditor = ({ message, onMessageChange, loading }) => {
  const [textInput, inputChange] = React.useState(message.text)
  React.useEffect(() => {
    inputChange(message.text)
  }, [message])
  const handleTextChange = () => {
    let newMsg = {
      type: 'text',
      text: textInput,
    }
    if (textInput === '') {
      newMsg.text = message.text
      inputChange(message.text)
    }
    onMessageChange(newMsg)
  }

  return (
    <div>
      <Input.TextArea
        style={{ width: '100%', resize: 'none', border: 'none' }}
        autoSize={{ minRows: 4 }}
        value={textInput}
        onChange={e => inputChange(e.target.value)}
        onBlur={handleTextChange}
        disabled={loading}
      />
    </div>
  )
}

TextEditor.propTypes = {
  message: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

TextEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default TextEditor
