import React from 'react'
import PT from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import TicketTypeForm from './TicketTypeForm'
import Loading from '../../../../components/Loading'

const GET_TICKET_TYPE = gql`
  query getTicketType($ticketTypeId: String!) {
    getTicketType(tt_id: $ticketTypeId) {
      name
      title
      from
      to
      qty
      rate
      vat_type
      promo_codes {
        name
        title
        type
        discount
        max_use
      }
    }
  }
`

const EditTicketTypeForm = ({ ticketType, onSubmit, loading, readOnly }) => {
  const [input, setInput] = React.useState({
    ...ticketType,
    promo_codes: (ticketType.promo_codes || []).map(pc => ({
      ...pc,
      name: undefined,
      __typename: undefined,
    })),
    name: undefined,
    __typename: undefined,
  })

  React.useEffect(() => {
    setInput({
      ...ticketType,
      promo_codes: (ticketType.promo_codes || []).map(pc => ({
        ...pc,
        name: undefined,
        __typename: undefined,
      })),
      name: undefined,
      __typename: undefined,
    })
  }, [ticketType])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <TicketTypeForm
      input={input}
      inputChange={inputChange}
      onSave={input => onSubmit(input, ticketType.name)}
      loading={loading}
      readOnly={readOnly}
    />
  )
}

EditTicketTypeForm.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    from: PT.string,
    to: PT.string,
    qty: PT.number,
    rate: PT.number,
    vat_type: PT.oneOf(['IN_VAT', 'EX_VAT', 'NO_VAT']),
    promo_codes: PT.arrayOf(
      PT.shape({
        name: PT.string,
        title: PT.string,
        type: PT.oneOf(['FIX', 'PERCENT', 'PRIVATE']),
        discount: PT.number,
        max_use: PT.number,
      }),
    ),
  }),
  loading: PT.bool,
  onSubmit: PT.func,
  readOnly: PT.bool,
}

EditTicketTypeForm.defaultProps = {
  data: {},
  onSubmit: () => {},
}

export default props => {
  const { data: dataProps } = props
  const { data, error, loading } = useQuery(GET_TICKET_TYPE, {
    variables: { ticketTypeId: dataProps.name },
    fetchPolicy: 'cache-and-network',
  })
  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }
  const ticketType = (data && data.getTicketType) || {}
  return <EditTicketTypeForm {...props} ticketType={ticketType} />
}
