import React from 'react'
import PT from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message, Modal } from 'antd'

import ChannelForm from './form'
import Loading from '../../../../components/Loading'

// for refetch only
const ALL_CHANNELS = gql`
  query allChannels($botId: String!) {
    allChannels(bot_id: $botId) {
      name
      title
      platform
    }
  }
`

const GET_CHANNEL = gql`
  query getChannel($chId: String!) {
    getChannel(ch_id: $chId) {
      name
      title
      platform
      enabled
      channel_secret
      id
      access_token
    }
  }
`

const UPDATE_CHANNEL = gql`
  mutation updateChannel($chId: String!, $input: ChannelInput!) {
    updateChannel(ch_id: $chId, input: $input) {
      name
    }
  }
`

const DELETE_CHANNEL = gql`
  mutation deleteChannel($chId: String!) {
    deleteChannel(ch_id: $chId) {
      name
    }
  }
`

const ChannelEditor = ({ botId, chId, selectChannel }) => {
  const [formLoading, setFormLoading] = React.useState(false)

  const { data, loading, error } = useQuery(GET_CHANNEL, { variables: { chId } })
  const [updateChannel] = useMutation(UPDATE_CHANNEL, {
    refetchQueries: [
      { query: ALL_CHANNELS, variables: { botId } },
      { query: GET_CHANNEL, variables: { chId } },
    ],
  })
  const [deleteChannel] = useMutation(DELETE_CHANNEL, {
    refetchQueries: [{ query: ALL_CHANNELS, variables: { botId } }],
  })

  if (error) {
    console.error(error)
  }

  const channel = (data && data.getChannel) || {}

  const handleEnableToggle = async enabled => {
    setFormLoading(true)
    try {
      await updateChannel({ variables: { chId, input: { enabled } } })
      message.success(enabled ? 'เปิดใช้งาน' : 'ปิดใช้งาน')
    } catch (err) {
      console.error(err)
    }
    setFormLoading(false)
  }

  const handleSaveBtnClick = async input => {
    setFormLoading(true)
    try {
      await updateChannel({ variables: { chId, input } })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setFormLoading(false)
  }

  const handleDeleteBtnClick = () => {
    Modal.confirm({
      title: 'คุณต้องการจะลบแชลแนลนี้?',
      okType: 'danger',
      okText: 'ลบ',
      cancelText: 'ไม่',
      onOk: async () => {
        setFormLoading(true)
        try {
          await deleteChannel({ variables: { chId } })
          selectChannel('')
        } catch (err) {
          console.error(err)
          setFormLoading(false)
        }
      },
    })
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {loading ? (
        <Loading />
      ) : (
        <ChannelForm
          channel={channel}
          onToggle={handleEnableToggle}
          onSave={handleSaveBtnClick}
          onDelete={handleDeleteBtnClick}
          loading={formLoading}
        />
      )}
    </div>
  )
}

ChannelEditor.propTypes = {
  chId: PT.string,
  selectChannel: PT.func,
}

ChannelEditor.defaultProps = {
  selectChannel: () => {},
}

export default props => {
  if (props.chId) {
    return <ChannelEditor {...props} />
  } else {
    return null
  }
}
