import React from 'react'
import PT from 'prop-types'

import KeywordConfigForm from './KeywordConfigForm'
import MessagesPreview from '../../../../libs/MessagesPreview'
import { LineMessageComposer, FacebookMessageComposer } from '../../../../libs/MessageComposer'

const BotReply = ({ botId, platform, keyword, updateAction, updateKeyword, loading }) => {
  const handleFormChange = item => {
    updateKeyword(item)
  }

  let messages = []
  if (platform === 'LineBot') {
    messages = keyword.bot_action.line_messages
  } else if (platform === 'FacebookBot') {
    messages = keyword.bot_action.facebook_messages
  }
  const messagesToPreview = messages.map(msg => msg.message)

  const handleMessageChange = msgs => {
    const input = {
      facebook_messages: platform === 'FacebookBot' ? msgs : undefined,
      line_messages: platform === 'LineBot' ? msgs : undefined,
    }
    updateAction(input)
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ width: 480 }}>
        <KeywordConfigForm data={keyword} onChange={handleFormChange} loading={loading} />
        <div>
          {platform === 'LineBot' && (
            <LineMessageComposer
              botId={botId}
              label="ข้อความตอบกลับอัตโนมัติ"
              onChange={handleMessageChange}
              defaultValue={messages}
              loading={loading}
            />
          )}
          {platform === 'FacebookBot' && (
            <FacebookMessageComposer
              botId={botId}
              label="ข้อความตอบกลับอัตโนมัติ"
              onChange={handleMessageChange}
              defaultValue={messages}
              loading={loading}
            />
          )}
        </div>
      </div>
      <div style={{ width: 'calc(100% - 480px)' }}>
        <MessagesPreview messages={messages} platform={platform} />
      </div>
    </div>
  )
}

BotReply.propTypes = {
  botId: PT.string,
  platform: PT.oneOf(['LineBot', 'FacebookBot']),
  keyword: PT.shape({
    name: PT.string,
    title: PT.string,
    published: PT.bool,
    bot_action: PT.shape({
      name: PT.string,
      facebook_messages: PT.arrayOf(PT.object),
      line_messages: PT.arrayOf(PT.object),
    }),
  }),
  updateAction: PT.func,
  updateKeyword: PT.func,
  loading: PT.bool,
}

BotReply.defaultProps = {
  updateAction: () => {},
  updateKeyword: () => {},
}

export default BotReply
