import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import AudienceSidenav from '../layouts/sidenav/AudienceSidenav'
import audienceRoutes from '../routes/audienceRoutes'

const AudienceContainer = props => {
  const initPath = props.match.path
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div style={{ width: 280, height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
        <AudienceSidenav routeProps={props} />
      </div>
      <div
        style={{
          width: 'calc(100% - 280px)',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Switch>
          <Redirect from={initPath} to={initPath + '/friend/list'} exact />
          {audienceRoutes.map(route => (
            <Route key={route.path} path={initPath + route.path} component={route.page} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default AudienceContainer
