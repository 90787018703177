import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Loading from '../components/Loading'
import { BotProvider } from '../context/Bot'
import Header from '../layouts/header/BotHeader'
import BotTopNav from '../layouts/header/BotTopNav'
import botRoutes from '../routes/botRoutes'

const GET_BOT = gql`
  query getBot($botId: String!) {
    getBot(bot_id: $botId) {
      name
      title
      image
      friend_tags
      friend_num
      modules
      enabled
    }
  }
`

const BotContainer = props => {
  const { match } = props
  const initPath = match.path
  const botId = match.params.botId
  const { data, loading, error } = useQuery(GET_BOT, { variables: { botId } })

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  const bot = (data && data.getBot) || { name: botId }

  return (
    <BotProvider value={bot}>
      <Header bot={bot} routeProps={props} />
      <BotTopNav bot={bot} routeProps={props} />
      <div style={{ height: 'calc(100% - 108px)' }}>
        <Switch>
          <Redirect from={initPath} to={initPath + '/main'} exact />
          {botRoutes.map(route =>
            route.module ? (
              (bot.modules || []).includes(route.module) && (
                <Route key={route.path} path={initPath + route.path} component={route.page} />
              )
            ) : (
              <Route key={route.path} path={initPath + route.path} component={route.page} />
            ),
          )}
        </Switch>
      </div>
    </BotProvider>
  )
}

export default BotContainer
