import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import Button from '../../../../components/Button'

const ChannelItem = ({ loading, isSelected, type, data, onSelect, onDisconnect, onConnect }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
      }}
    >
      <div style={isSelected ? { color: '#1C70B0', fontWeight: 600 } : {}}>
        <span>{data ? data.title : type}</span>
      </div>
      {data ? (
        <div style={{ display: 'flex' }}>
          {onSelect && (
            <Button
              onClick={() => onSelect(data.name)}
              type="primary"
              ghost={!isSelected}
              style={{ marginLeft: 4 }}
              loading={loading}
              icon={<Icon theme="filled" type="setting" />}
            />
          )}
          {onDisconnect && (
            <Button
              onClick={() => onDisconnect(data.name)}
              type="danger"
              style={{ marginLeft: 4 }}
              loading={loading}
              icon={<Icon type="disconnect" />}
            />
          )}
        </div>
      ) : (
        onConnect && (
          <Button loading={loading} onClick={onConnect} icon={<Icon type="link" />}>
            เชื่อมต่อ
          </Button>
        )
      )}
    </div>
  )
}

ChannelItem.propTypes = {
  loading: PT.bool,
  isSelected: PT.bool,
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineLogin', 'LineNotify']),
  }),
  type: PT.oneOf(['Page', 'Bot', 'Login', 'Notify']).isRequired,
  onSelect: PT.func,
  onDisconnect: PT.func,
  onConnect: PT.func,
}

export default ChannelItem
