import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Modal, Select, Icon, Tooltip, Input, message } from 'antd'

import AdminCard from './AdminCard'
import Button from '../../../components/Button'
import Divider from '../../../components/Divider'
import Loading from '../../../components/Loading'
import InputField from '../../../components/InputField'
import BotContext from '../../../context/Bot'

const ALL_ADMINS = gql`
  query allBotAdmins($botId: String!) {
    allBotAdmins(bot_id: $botId) {
      user
      role
      owner
      creation
    }
  }
`

const CREATE_TOKEN = gql`
  mutation createInviteToken($botId: String!, $role: UserRole) {
    createInviteToken(bot_id: $botId, role: $role)
  }
`

const DELETE_ADMIN = gql`
  mutation removeAdmin($botId: String!, $user: String!) {
    removeAdmin(bot_id: $botId, user: $user)
  }
`

const Admin = () => {
  const bot = React.useContext(BotContext)

  const inviteLink = React.createRef()

  const [tokenLoading, setLoadingToken] = React.useState(false)
  const [modalState, setModalState] = React.useState(false)
  const [permissionType, setPermissionType] = React.useState('Admin')

  const { data, loading, error } = useQuery(ALL_ADMINS, { variables: { botId: bot.name } })
  const [createInviteToken] = useMutation(CREATE_TOKEN)
  const [removeAdmin] = useMutation(DELETE_ADMIN, {
    refetchQueries: [{ query: ALL_ADMINS, variables: { botId: bot.name } }],
  })

  if (error) {
    console.error(error)
  }

  const allBotAdmins = (data && data.allBotAdmins) || []

  let admins = {
    Admin: [],
    Creator: [],
    Supervisor: [],
    Agent: [],
  }

  allBotAdmins.forEach(a => {
    const roles = Object.keys(admins)
    if (roles.includes(a.role)) {
      admins[a.role].push(a)
    } else if (a.role != null) {
      admins[a.role] = [a]
    }
  })

  const copyInviteLink = () => {
    inviteLink.current.select()
    document.execCommand('copy')
    message.success('Link Copied!!')
  }

  const handleDeleteClick = admin => {
    Modal.confirm({
      title: `ลบสิทธิ์ของ ${admin.user}`,
      content: `${admin.user} จะไม่สามารถจัดการบอทตัวนี้ได้`,
      okText: 'ลบ',
      okType: 'danger',
      cancelText: 'ยกเลิก',
      onOk: () => deleteAdmin(admin.user),
    })
  }

  const generateInviteLink = async () => {
    setLoadingToken(true)
    try {
      const { data } = await createInviteToken({
        variables: { botId: bot.name, role: permissionType },
      })
      setModalState(false)
      const token = data.createInviteToken
      Modal.info({
        title: 'Invite link',
        content: (
          <div>
            <p style={{ marginBottom: 4 }}>Copy this link to invite new administrator.</p>
            <Input
              ref={inviteLink}
              value={`${window.location.origin}/a/invitation?token=${token}&bot=${btoa(
                encodeURIComponent(
                  JSON.stringify({ name: bot.name, title: bot.title, picture_url: bot.image }),
                ),
              )}`}
              addonAfter={
                document.queryCommandSupported('copy') && (
                  <Tooltip title="copy">
                    <Icon onClick={copyInviteLink} style={{ cursor: 'pointer' }} type="copy" />
                  </Tooltip>
                )
              }
              readOnly
            />
          </div>
        ),
        width: 560,
      })
    } catch (err) {
      console.error(err.message)
    }
    setLoadingToken(false)
  }

  const deleteAdmin = async user => {
    try {
      await removeAdmin({ variables: { botId: bot.name, user } })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 36px 16px',
        }}
      >
        <h1>จัดการสิทธิ์การใช้งานระบบ</h1>
        <Button
          loading={loading}
          style={{ width: 100 }}
          onClick={() => setModalState(true)}
          type="primary"
          ghost
          icon={<Icon type="plus" />}
        >
          เพิ่ม
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        Object.keys(admins).map(role => (
          <React.Fragment key={role}>
            {!!admins[role].length && (
              <div style={{ padding: '0 36px 24px' }}>
                <Divider>{role}</Divider>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {admins[role].length ? (
                    admins[role].map(admin => (
                      <AdminCard
                        key={admin.user}
                        data={admin}
                        onDelete={() => handleDeleteClick(admin)}
                      />
                    ))
                  ) : (
                    <span
                      style={{ fontStyle: 'italic', fontWeight: 500, color: 'rgba(0, 0, 0, 0.3)' }}
                    >
                      NO DATA
                    </span>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))
      )}
      <Modal
        visible={modalState}
        title="เพิ่มสิทธิ์การเข้าถึง"
        onCancel={() => setModalState(false)}
        okText="Issue"
        onOk={generateInviteLink}
        confirmLoading={tokenLoading}
      >
        <p>
          To add a new user, that user needs to access a verification URL generated using the form
          in modal before they can be added.
        </p>
        <p>
          After selecting a permission type, click Issue to generate a verification URL for that
          user to access.
        </p>
        <InputField title="Role" compact>
          <Select
            style={{ width: 240 }}
            value={permissionType}
            onChange={val => setPermissionType(val)}
          >
            <Select.Option key="Admin">Admin</Select.Option>
            {/* <Select.Option key="Supervisor">Supervisor</Select.Option> */}
            <Select.Option key="Agent">Agent</Select.Option>
          </Select>
        </InputField>
      </Modal>
    </div>
  )
}

export default Admin
