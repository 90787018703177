import React from 'react'
import { Modal, Select, Input, Tooltip, Icon, Spin, message } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import AgentCard from '../AgentCard'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import InputField from '../../../components/InputField'
import NoData from '../../../components/NoData'
import Divider from '../../../components/Divider'
import BotContext from '../../../context/Bot'

const ALL_AGENTS = gql`
  query allAgents($botId: String!) {
    allAgents(bot_id: $botId) {
      name
      title
      role
    }
  }
`

const CREATE_TOKEN = gql`
  mutation createInviteToken($botId: String!, $role: UserRole) {
    createInviteToken(bot_id: $botId, role: $role)
  }
`

const AgentList = ({ match }) => {
  const bot = React.useContext(BotContext)
  const botId = match.params.botId

  const inviteLink = React.createRef()

  const [loadingToken, setLoadingToken] = React.useState(false)
  const [modalState, setModalState] = React.useState(false)
  const [permissionType, setPermissionType] = React.useState('Agent')

  const { data, loading, error } = useQuery(ALL_AGENTS, { variables: { botId } })
  const [createInviteToken] = useMutation(CREATE_TOKEN)
  if (error) {
    console.error(error)
  }
  const moreLoading = false

  const agents = (data && data.allAgents) || []

  const handleSearch = s => {
    console.log('search', s)
  }

  const copyInviteLink = () => {
    inviteLink.current.select()
    document.execCommand('copy')
    message.success('Link Copied!!')
  }

  const generateInviteLink = async () => {
    setLoadingToken(true)
    try {
      const { data } = await createInviteToken({
        variables: { botId, role: permissionType },
      })
      setModalState(false)
      const token = data.createInviteToken
      Modal.info({
        title: 'Invite link',
        content: (
          <div>
            <p style={{ marginBottom: 4 }}>Copy this link to invite new administrator.</p>
            <Input
              ref={inviteLink}
              value={`${window.location.origin}/a/invitation?token=${token}&bot=${btoa(
                encodeURIComponent(
                  JSON.stringify({ name: bot.name, title: bot.title, picture_url: bot.image }),
                ),
              )}`}
              addonAfter={
                document.queryCommandSupported('copy') && (
                  <Tooltip title="copy">
                    <Icon onClick={copyInviteLink} style={{ cursor: 'pointer' }} type="copy" />
                  </Tooltip>
                )
              }
              readOnly
            />
          </div>
        ),
        width: 560,
      })
    } catch (err) {
      console.error(err.message)
    }
    setLoadingToken(false)
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>รายชื่อเอเจนท์</h1>
        <div>
          {/* <Input.Search
            onSearch={handleSearch}
            style={{ width: 180, marginRight: 8 }}
            placeholder="ค้นหาชื่อเอเจนท์.."
          /> */}
          <Button
            type="primary"
            ghost
            icon={<Icon type="plus" />}
            onClick={() => setModalState(true)}
          >
            เพิ่มเอเจนท์
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Scrollbars style={{ height: 'calc(100% - 97px)' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              padding: '0 36px 24px',
            }}
          >
            {agents.length ? (
              <>
                {!!agents.filter(a => a.role === 'Admin').length && (
                  <>
                    <Divider>Admin</Divider>
                    {agents
                      .filter(a => a.role === 'Admin')
                      .map(a => (
                        <AgentCard
                          key={a.name}
                          data={a}
                          style={{ marginRight: 16, marginBottom: 16 }}
                        />
                      ))}
                  </>
                )}
                {!!agents.filter(a => a.role === 'Agent').length && (
                  <>
                    <Divider>Agent</Divider>
                    {agents
                      .filter(a => a.role === 'Agent')
                      .map(a => (
                        <AgentCard
                          key={a.name}
                          data={a}
                          style={{ marginRight: 16, marginBottom: 16 }}
                        />
                      ))}
                  </>
                )}
              </>
            ) : (
              <NoData />
            )}
          </div>
          {moreLoading && (
            <div style={{ textAlign: 'center' }}>
              <Spin style={{ margin: '32px 0' }} />
            </div>
          )}
        </Scrollbars>
      )}
      <Modal
        visible={modalState}
        title="เพิ่มสิทธิ์การเข้าถึง"
        onCancel={() => setModalState(false)}
        okText="Issue"
        onOk={generateInviteLink}
        confirmLoading={loadingToken}
      >
        <p>
          To add a new user, that user needs to access a verification URL generated using the form
          in modal before they can be added.
        </p>
        <p>
          After selecting a permission type, click Issue to generate a verification URL for that
          user to access.
        </p>
        <InputField title="Role" compact>
          <Select
            style={{ width: 240 }}
            value={permissionType}
            onChange={val => setPermissionType(val)}
          >
            <Select.Option key="Admin">Admin</Select.Option>
            {/* <Select.Option key="Supervisor">Supervisor</Select.Option> */}
            <Select.Option key="Agent">Agent</Select.Option>
          </Select>
        </InputField>
      </Modal>
    </div>
  )
}

export default AgentList
