import AudienceList from '../pages/audience/AudienceList'
import AudienceDetail from '../pages/audience/AudienceDetail'
import Segment from '../pages/audience/Segment'

export default [
  {
    path: '/friend/list',
    page: AudienceList,
  },
  {
    path: '/friend/:uuid',
    page: AudienceDetail,
  },
  {
    path: '/segment/list',
    page: Segment,
  },
]
