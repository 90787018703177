import React from 'react'
import PT from 'prop-types'
import { Input, Rate, Select } from 'antd'

import Button from '../../../components/Button'
import InputField from '../../../components/InputField'
import BotContext from '../../../context/Bot'

const AudienceForm = ({ data, onSubmit, loading }) => {
  const bot = React.useContext(BotContext)
  const [input, setInput] = React.useState({
    full_name: data.full_name,
    rating: data.rating,
    tags: data.tags,
    about: data.about,
    bc_data: data.bc_data,
  })

  React.useEffect(() => {
    setInput({
      full_name: data.full_name,
      rating: data.rating,
      tags: data.tags,
      about: data.about,
      bc_data: data.bc_data,
    })
  }, [data])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <div style={{ padding: '16px 36px' }}>
      <InputField title="ชื่อ">
        <Input
          disabled={loading}
          value={input.full_name}
          onChange={e => inputChange({ full_name: e.target.value })}
        />
      </InputField>
      <InputField title="ระดับความสำคัญ">
        <Rate
          value={input.rating}
          onChange={val => inputChange({ rating: val })}
          allowClear={false}
          disabled={loading}
          allowHalf
        />
      </InputField>
      <InputField title="แท็ก">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          value={input.tags}
          onChange={tags => inputChange({ tags })}
        >
          {(bot.friend_tags || []).map(tag => (
            <Select.Option key={tag}>{tag}</Select.Option>
          ))}
        </Select>
      </InputField>
      <InputField title="บันทึก">
        <Input.TextArea
          value={input.about}
          onChange={e => inputChange({ about: e.target.value })}
          autosize={{ minRows: 3 }}
        />
      </InputField>
      <Button
        loading={loading}
        onClick={() => onSubmit(input)}
        style={{ marginTop: 16 }}
        type="primary"
        block
      >
        บันทึก
      </Button>
    </div>
  )
}

AudienceForm.propTypes = {
  data: PT.shape({
    id: PT.string,
    full_name: PT.string,
    picture_url: PT.string,
    tags: PT.arrayOf(PT.string),
    rate: PT.number,
  }),
  onSubmit: PT.func,
}

AudienceForm.defaultProps = {
  onSubmit: () => {},
}

export default AudienceForm
