import React from 'react'
import { Icon, Steps } from 'antd'

import RegisterForm from './RegisterForm'
import Confirmation from './Confirmation'
import Success from './Success'

import AIYA_LOGO from '../../../assets/images/aiya-logo-blue.png'
import ImageRenderer from '../../../components/ImageRenderer'
import { queryStrToObj } from '../../../helpers/queryStr'

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currStep: 0,
      input: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        businessType: '',
        occupation: '',
      },
    }
  }

  inputChange = item => {
    const { input } = this.state
    this.setState({ input: { ...input, ...item } })
  }

  nextStep = () => {
    const { currStep } = this.state
    this.setState({ currStep: currStep + 1 })
  }

  backStep = () => {
    const { currStep } = this.state
    this.setState({ currStep: currStep - 1 })
  }

  gotoLogin = () => {
    const { history } = this.props
    history.push('/login')
  }

  gotoCreateBot = () => {
    const { history } = this.props
    history.push('/app/bot/create')
  }

  render() {
    const { lineData } = this.props
    const { currStep, input } = this.state

    const contents = [
      {
        title: 'ลงทะเบียนข้อมูลส่วนตัว',
        icon: <Icon type="edit" style={{ fontSize: '0.8em' }} />,
        content: (
          <RegisterForm
            displayName={lineData.displayName}
            input={input}
            inputChange={this.inputChange}
            onNext={this.nextStep}
            gotoLogin={this.gotoLogin}
          />
        ),
      },
      {
        title: 'ตรวจสอบรายละเอียดส่วนตัว',
        icon: <Icon type="form" style={{ fontSize: '0.8em' }} />,
        content: (
          <Confirmation
            lineData={lineData}
            data={input}
            onBack={this.backStep}
            onNext={this.nextStep}
          />
        ),
      },
      {
        title: 'เสร็จสิ้นการลงทะเบียน',
        icon: <Icon type="check-circle" style={{ fontSize: '0.8em' }} />,
        content: (
          <Success gotoLogin={this.gotoLogin} gotoCreateBot={this.gotoCreateBot} data={input} />
        ),
      },
    ]

    const activeStyle = { color: 'rgba(38, 119, 180, 1)' }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
        }}
      >
        <ImageRenderer src={AIYA_LOGO} alt="AIYA" width={150} style={{ marginTop: 8 }} />
        <h1>ลงทะเบียนผู้ใช้ใหม่</h1>
        <div style={{ width: '900px', marginBottom: 36 }}>
          <Steps size="small" current={currStep}>
            {contents.map((ct, i) => (
              <Steps.Step
                key={i}
                title={<span style={currStep >= i ? activeStyle : {}}>{ct.title}</span>}
                icon={ct.icon}
              />
            ))}
          </Steps>
        </div>
        <div style={{ width: 468, marginBottom: 48 }}>{(contents[currStep] || {}).content}</div>
      </div>
    )
  }
}

export default props => {
  const params = queryStrToObj(props.location.search)

  if (!params.q) {
    window.location = 'https://aiya.ai/R/7ed4a615ce'
    return null
  }

  const tmp = queryStrToObj('?' + atob(decodeURIComponent(params.q)))
  const lineData = {
    ...tmp,
    displayName: decodeURIComponent(tmp.displayName),
  }

  return <Register {...props} lineData={lineData} />
}
