import React from 'react'
import shortid from 'shortid'
import PT from 'prop-types'
import { Icon } from 'antd'

import Editor from './Editor'
import Button from '../components/Button'

class LineMessageComposer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: props.defaultValue || [],
    }
  }

  addMessage = () => {
    const { messages } = this.state
    const newMessage = {
      name: `msg-${shortid.generate()}`,
      type: 'box',
      message: {
        type: 'text',
        text: 'Text Message',
      },
    }
    this.setState({ messages: [...messages, newMessage] })
  }

  delMessage = msgId => {
    const { messages } = this.state
    const indexToDel = messages.findIndex(msg => msgId === msg.name)
    if (indexToDel === -1) {
      return
    }
    const tmp = [...messages]
    tmp.splice(indexToDel, 1)
    this.setState({ messages: tmp })
  }

  handleMessageChange = (msgId, message, data) => {
    const { messages } = this.state
    const indexToEdit = messages.findIndex(msg => msgId === msg.name)
    if (indexToEdit === -1) {
      return
    }
    if (
      JSON.stringify(message) === JSON.stringify(messages[indexToEdit].message) &&
      JSON.stringify(data) === JSON.stringify(messages[indexToEdit].data)
    ) {
      return
    }
    const tmp = [...messages]
    tmp[indexToEdit] = { ...tmp[indexToEdit], message, data }
    this.setState({ messages: tmp })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ messages: this.props.defaultValue })
    }
    if (prevState.messages !== this.state.messages) {
      this.props.onChange(this.state.messages)
    }
  }

  render() {
    const { botId, label, loading } = this.props
    const { messages } = this.state
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          padding: '18px 36px',
        }}
      >
        <div style={{ width: '100%', marginBottom: 8 }}>
          {label || 'ข้อความ'} ( {messages.length} / 5 )
        </div>
        <div style={{ marginBottom: 8 }}>
          {messages.map((msg, i) => (
            <Editor
              loading={loading}
              botId={botId}
              index={i}
              key={msg.name}
              message={msg.message}
              data={msg.data}
              onChange={(message, data) => this.handleMessageChange(msg.name, message, data)}
              delMessage={() => this.delMessage(msg.name)}
            />
          ))}
        </div>
        {messages.length < 5 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 'calc(480px - 72px)',
            }}
          >
            <Button onClick={this.addMessage} icon={<Icon type="plus" />}>
              เพิ่มกล่องข้อความ
            </Button>
          </div>
        )}
      </div>
    )
  }
}

LineMessageComposer.propTypes = {
  botId: PT.string.isRequired,
  label: PT.string,
  onChange: PT.func,
  defaultValue: PT.arrayOf(PT.object),
  loading: PT.bool,
}

LineMessageComposer.defaultProps = {
  onChange: () => {},
}

export default LineMessageComposer
