import React from 'react'
import PT from 'prop-types'

const NumberRenderer = ({ value, style, prefix, suffix }) => {
  const stringNum = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (
    <span style={style}>
      {!!prefix && <span style={{ marginRight: 4 }}>{prefix}</span>}
      <span>{stringNum}</span>
      {!!suffix && <span style={{ marginLeft: 4 }}>{suffix}</span>}
    </span>
  )
}

NumberRenderer.propTypes = {
  value: PT.number,
  prefix: PT.oneOfType([PT.string, PT.node]),
  suffix: PT.oneOfType([PT.string, PT.node]),
  style: PT.objectOf(PT.any),
}

NumberRenderer.defaultProps = {
  value: 0,
  style: {},
}

export default NumberRenderer
