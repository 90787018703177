import React from 'react'
import PT from 'prop-types'
import moment from 'moment'

import AIYA_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'
import { Button } from 'antd'

const AdminCard = ({ data, onDelete }) => {
  const date = moment(data.creation)

  return (
    <div
      style={{
        display: 'flex',
        width: 480,
        height: 100,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        margin: 8,
        borderRadius: 4,
      }}
    >
      <div style={{ width: 100, height: 100, padding: 14 }}>
        <ImageRenderer
          style={{ width: 72, height: 72, borderRadius: '50%' }}
          src={AIYA_BLANK}
          alt={data.user}
          errorImg={AIYA_BLANK}
        />
      </div>
      <div style={{ width: 'calc(100% - 100px)', padding: 14 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '1.3em' }}>{data.user}</span>
          <Button onClick={onDelete} type="danger" icon="delete" />
        </div>
        <div>
          <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}>
            Warrantor: {data.owner}
          </span>
        </div>
        <div>
          <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}>
            Date: {date.format('HH:mm:ss DD-MM-YYYY')}
          </span>
        </div>
      </div>
    </div>
  )
}

AdminCard.propTypes = {
  data: PT.shape({
    user: PT.string,
    owner: PT.string,
    creation: PT.string,
  }),
  onDelete: PT.func,
}

AdminCard.defaultProps = {
  onDelete: () => {},
}

export default AdminCard
