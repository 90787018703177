import PT from 'prop-types'

export const PT_AGENT = PT.shape({
  name: PT.string,
  title: PT.string,
  picture_url: PT.string,
  role: PT.oneOf(['Admin', 'Agent']),
})

export const PT_AGENT_GROUP = PT.shape({
  name: PT.string,
  title: PT.string,
  description: PT.string,
  agents: PT.arrayOf(PT_AGENT),
})
