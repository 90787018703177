import React from 'react'
import PT from 'prop-types'
import { Icon, Modal } from 'antd'

import TextEditor from './TextEditor'
import ImageEditor from './ImageEditor'
import IconButton from '../../components/IconButton'
import IconMenu from '../../components/IconMenu'
import { facebookMsgType } from '../../constants/msgType'
import { defaultFacebookMsg } from '../../constants/defaultMsg'

class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messageType: this.classifyMsg(props.message),
    }
  }

  classifyMsg = msg => {
    if (!msg) {
      return
    }
    if (msg.text) {
      return facebookMsgType.TEXT
    }
    const attachmentType = msg.attachment && msg.attachment.type
    if (attachmentType === 'image') {
      return facebookMsgType.IMAGE
    }
    return null
  }

  handleMsgTypeChange = type => {
    const { onChange, loading } = this.props
    if (!loading) {
      Modal.confirm({
        title: 'ยืนยันการแก้ไข',
        content: (
          <div>
            <div>การแก้ไขข้อความจะทำให้ข้อความในกล่องนี้หายไป</div>
            <div>เพื่อความปลอดภัยกรุณาสำรองข้อความเก่าไว้ก่อน</div>
          </div>
        ),
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          this.setState({ messageType: type })
          onChange(defaultFacebookMsg[type])
        },
        onCancel: () => {},
      })
    }
  }

  handleMessageChange = (newMsg, data) => {
    const { onChange } = this.props
    onChange(newMsg, data)
  }

  render() {
    const { botId, message, data, loading, delMessage } = this.props
    const { messageType } = this.state
    return (
      <div
        style={{
          width: '360px',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 32,
            borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
            padding: '0 4px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <IconButton style={{ marginRight: 8 }}>
              <Icon type="menu" />
            </IconButton>
            <IconMenu
              onClick={() => this.handleMsgTypeChange(facebookMsgType.TEXT)}
              active={messageType === facebookMsgType.TEXT}
              style={{ marginRight: 4 }}
            >
              <Icon type="message" />
            </IconMenu>
            <IconMenu
              onClick={() => this.handleMsgTypeChange(facebookMsgType.IMAGE)}
              active={messageType === facebookMsgType.IMAGE}
              style={{ marginRight: 4 }}
            >
              <Icon type="picture" />
            </IconMenu>
          </div>
          <IconButton hoverColor="red" onClick={delMessage}>
            <Icon type="close" />
          </IconButton>
        </div>
        {messageType === facebookMsgType.TEXT && (
          <TextEditor
            message={message}
            onMessageChange={this.handleMessageChange}
            loading={loading}
          />
        )}
        {messageType === facebookMsgType.IMAGE && (
          <ImageEditor
            message={message}
            data={data}
            onMessageChange={this.handleMessageChange}
            botId={botId}
            loading={loading}
          />
        )}
      </div>
    )
  }
}

Editor.propTypes = {
  botId: PT.string.isRequired,
  index: PT.number,
  message: PT.object,
  data: PT.object,
  onChange: PT.func,
  delMessage: PT.func,
  loading: PT.bool,
}

Editor.defaultProps = {
  onChange: () => {},
  delMessage: () => {},
}

export default Editor
