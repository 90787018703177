export const lineMsgType = {
  TEXT: 'text',
  IMAGE: 'image',
  RICH_MESSAGE: 'richMessage',
  IMAGEMAP: 'imagemap',
  FLEX: 'flex',
}

export const facebookMsgType = {
  TEXT: 'text',
  IMAGE: 'image',
}
