export default [
  'งานบริการ',
  'เทคโนโลยี',
  'ธุรกิจออนไลน์',
  'การตลาด',
  'การศึกษา',
  'สื่อมวลชน',
  'การเงิน',
  'งานประกัน',
  'อสังหาริมทรัพย์',
  'งานฝ่ายบุคคล',
  'ระบบจัดการอีเวนท์',
  'งานหน่วยงานราชการ',
]
