import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon, Modal } from 'antd'

import ACRM_LOGO from '../../assets/images/ACRM_LOGO_outline.png'
import AIYA_BOT from '../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../components/ImageRenderer'
import NumberRenderer from '../../components/NumberRenderer'
import AppbarMenu from '../../components/AppbarMenu'

const Header = ({ bot, routeProps: { history } }) => {
  const appbarMenus = [
    {
      key: 'logout',
      title: 'ออกจากระบบ',
    },
  ]

  const handleMenuClick = key => {
    if (key === 'logout') {
      openModalConfirm()
    }
  }

  const openModalConfirm = () => {
    Modal.confirm({
      title: 'ออกจากระบบตอนนี้?',
      okText: 'ใช่',
      cancelText: 'ไม่',
      okType: 'danger',
      onOk: () => {
        history.push('/logout')
      },
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '100vw',
        height: 60,
        padding: '6px 0px 6px 48px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/app/bot">
          <ImageRenderer src={ACRM_LOGO} alt="logo" height={48} style={{ cursor: 'pointer' }} />
        </Link>

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 24 }}>
          <div
            style={{
              borderRadius: '50%',
              width: 36,
              height: 36,
              overflow: 'hidden',
              marginRight: 8,
            }}
          >
            <ImageRenderer
              src={bot.image || AIYA_BOT}
              alt="bot"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <span style={{ fontWeight: 500, color: 'rgba(38, 119, 180, 1)' }}>
              {bot.title || 'Bot Name'}
            </span>
            <span style={{ fontSize: '0.9em', fontWeight: 300, color: 'rgba(183, 183, 183, 1)' }}>
              @{bot.name || 'bot_id'}
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 36 }}>
          <Icon type="user" />
          <div style={{ marginLeft: 8, color: 'rgba(183, 183, 183, 1)' }}>
            <NumberRenderer
              value={bot.friend_num || 0}
              style={{ color: 'rgba(38, 119, 180, 1)' }}
            />
          </div>
        </div>
      </div>
      <AppbarMenu menus={appbarMenus} onMenuClick={handleMenuClick} />
    </div>
  )
}

Header.propTypes = {
  bot: PT.shape({
    name: PT.string,
    title: PT.string,
    image: PT.string,
    friend_num: PT.number,
  }),
  routeProps: PT.object,
}

Header.defaultProps = {
  bot: {},
}

export default Header
