import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'
import { useDrop } from 'react-dnd'

import { ItemTypes } from './dndConstants'

const TagDropZone = ({ children, style, zone }) => {
  const [{ isOver }, dropRef] = useDrop({
    accept: ItemTypes.TAG,
    drop: () => ({ zone }),
    collect: mon => ({
      isOver: !!mon.isOver(),
    }),
  })

  return (
    <div style={{ position: 'relative', ...style }} ref={dropRef}>
      {children}
      <div style={{ padding: 12, color: isOver ? 'rgba(0, 0, 0, 0.65)' : '#B7B7B7' }}>
        <Icon type="plus" style={{ marginRight: 4 }} />
        <span>เพิ่มแท็ก</span>
      </div>
    </div>
  )
}

TagDropZone.propTyes = {
  children: PT.node,
  style: PT.object,
  zone: PT.shape({
    segment: PT.string,
    key: PT.oneOf(['includes', 'excludes']),
  }),
}

export default TagDropZone
