export function getImageResolution(imageUrl) {
  return new Promise(function(resolve, reject) {
    var img = new Image()
    img.onload = function() {
      resolve({ width: this.width, height: this.height })
    }
    img.onerror = function(err) {
      reject(err)
    }
    img.src = imageUrl
  })
}
