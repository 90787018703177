import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import ImageInput from '../../components/ImageInput'
import { NO_IMG } from '../../constants/defaultValue'

const ImageEditor = ({ message, data, onMessageChange, botId, loading }) => {
  const handleImageChange = url => {
    const newMsg = {
      attachment: {
        type: 'image',
        payload: {
          url: url,
        },
      },
    }
    onMessageChange(newMsg)
  }
  const displayUrl = (data || {}).url || ((message.attachment || {}).payload || {}).url
  return (
    <div style={{ padding: '12px 24px' }}>
      <h4>รูปภาพ</h4>
      <small style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <Icon type="info-circle" style={{ marginRight: 4 }} />
        <span>รูปภาพ .jpg และ ขนาดน้อยกว่า 1 MB</span>
      </small>
      <ImageInput
        botId={botId}
        value={displayUrl !== NO_IMG ? displayUrl : undefined}
        onChange={handleImageChange}
        loading={loading}
      />
    </div>
  )
}

ImageEditor.propTypes = {
  botId: PT.string.isRequired,
  message: PT.object,
  data: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

ImageEditor.defaultProps = {
  message: {},
  data: {},
  onMessageChange: () => {},
}

export default ImageEditor
