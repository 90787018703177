import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'

const SettingSidenav = ({ routeProps }) => {
  const initUrl = routeProps.match.url
  return (
    <div style={{ padding: 36, display: 'flex', flexDirection: 'column' }}>
      <NavLink to={initUrl + '/bot'} className="sidenav-item" activeClassName="active">
        ตั้งค่าบอท
      </NavLink>
      <NavLink to={initUrl + '/channel'} className="sidenav-item" activeClassName="active">
        ตั้งค่า Channels
      </NavLink>
      <NavLink to={initUrl + '/permission'} className="sidenav-item" activeClassName="active">
        การจัดการสิทธิ์
      </NavLink>
      {/* <NavLink to={initUrl + '/package'} className="sidenav-item" activeClassName="active">
        แพ็กเกจรายเดือน
      </NavLink> */}
    </div>
  )
}

SettingSidenav.propTypes = {
  routeProps: PT.object.isRequired,
}

export default SettingSidenav
