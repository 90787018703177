import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'

const AudienceSidenav = ({ routeProps }) => {
  const initUrl = routeProps.match.url

  return (
    <div style={{ padding: 36, display: 'flex', flexDirection: 'column' }}>
      <NavLink to={initUrl + '/friend/list'} className="sidenav-item" activeClassName="active">
        รายชื่อเพื่อน
      </NavLink>
      <NavLink to={initUrl + '/segment/list'} className="sidenav-item" activeClassName="active">
        กลุ่มเป้าหมาย
      </NavLink>
    </div>
  )
}

AudienceSidenav.propTypes = {
  routeProps: PT.object.isRequired,
}

export default AudienceSidenav
