export const colorsPool = [
  '#FF5C5C',
  '#093357',
  '#04467E',
  '#137890',
  '#0AD6BE',
  '#0C6427',
  '#007712',
  '#D6C004',
  '#BC8E11',
  '#A00326',
  '#64175B',
  '#206EB0',
  '#2592EF',
  '#2094B0',
  '#77C4BB',
  '#32B159',
  '#FFE200',
  '#40D156',
  '#FFB900',
  '#8423AF',
]
