import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import AIYA_BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'
import Button from '../../../components/Button'

const LeadsInfoDisplay = ({ data }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 36 }}>
      <div style={{ display: 'flex' }}>
        <ImageRenderer
          src={data.picture_url}
          alt={data.id}
          errorImg={AIYA_BOT_BLANK}
          style={{ width: 180, height: 180, borderRadius: '50%', marginRight: 36 }}
        />
        <div>
          <div style={{ color: '#b7b7b7', marginBottom: 24 }}>
            <small style={{ marginRight: 8 }}>Audience ID:</small>
            <small>{data.id}</small>
          </div>
          <div>
            <div style={{ color: '#b7b7b7' }}>LINE Display name</div>
            <div>{data.full_name}</div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button type="primary" icon={<Icon type="message" />} ghost>
          ไปยังแชท
        </Button>
      </div>
    </div>
  )
}

LeadsInfoDisplay.propTypes = {
  data: PT.shape({
    id: PT.string,
    full_name: PT.string,
    picture_url: PT.string,
    tags: PT.arrayOf(PT.string),
  }),
}

export default LeadsInfoDisplay
