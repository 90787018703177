import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const Btn = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  .child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    border-color: rgba(28, 112, 176, 1);
  }
  &.active {
    border-color: rgba(28, 112, 176, 1);
    color: rgba(28, 112, 176, 1);
  }
`

const IconMenu = ({ children, active, onClick, style }) => {
  return (
    <Btn style={style} onClick={onClick} className={active && 'active'}>
      <span className="child">{children}</span>
    </Btn>
  )
}

IconMenu.propTypes = {
  children: PT.node,
  onClick: PT.func,
  style: PT.object,
  active: PT.bool,
}

IconMenu.defaultProps = {
  onClick: () => {},
  style: {},
}

export default IconMenu
