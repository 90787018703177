import React from 'react'
import PT from 'prop-types'

import InputField from '../../../../../components/InputField'

const FacebookPageForm = ({ data }) => {
  return (
    <div>
      <InputField title="ชื่อ Page">
        <span>{data.title}</span>
      </InputField>
      <InputField title="Page ID">
        <span>{data.id}</span>
      </InputField>
      <InputField title="Access Token">
        <div style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>{data.access_token}</div>
      </InputField>
    </div>
  )
}

FacebookPageForm.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineLogin', 'LineNotify']),
    enabled: PT.bool,
    channel_secret: PT.string,
    id: PT.string,
    access_token: PT.string,
  }),
}

export default FacebookPageForm
