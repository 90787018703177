import React from 'react'
import PT from 'prop-types'
import { useDrag } from 'react-dnd'
import { Icon } from 'antd'

import { ItemTypes } from './dndConstants'
import AgentCard from '../AgentCard'
import { PT_AGENT } from '../propTypes'
import AIYA_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'

const AgentItem = ({ data, owner, fullCard, onDrop, onDelete }) => {
  const [isHover, setHover] = React.useState(false)
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: ItemTypes.AGENT, data, owner },
    end: (item, mon) => {
      onDrop(item, (mon.getDropResult() || {}).dropTarget)
    },
    collect: mon => ({
      isDragging: !!mon.isDragging(),
    }),
  })
  return (
    <div
      ref={dragRef}
      style={{
        marginBottom: 8,
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {fullCard ? (
        <AgentCard data={data} />
      ) : (
        <div
          style={{
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            padding: 8,
            borderRadius: 4,
          }}
        >
          <ImageRenderer
            alt={data.name}
            src={data.picture_url}
            errorImg={AIYA_BLANK}
            style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 8 }}
          />
          <div
            className="text-wrap-ellipsis"
            style={{ textAlign: 'left', width: 'calc(100% - 40px)' }}
          >
            {data.title}
          </div>
          {isHover && onDelete && (
            <Icon
              type="close"
              style={{ color: 'red' }}
              onClick={() => onDelete(data.name, owner)}
            />
          )}
        </div>
      )}
    </div>
  )
}

AgentItem.propTypes = {
  data: PT_AGENT,
  owner: PT.string,
  fullCard: PT.bool,
  onDrop: PT.func,
  onDelete: PT.func,
}

AgentItem.defaultProps = {
  onDrop: () => {},
}

export default AgentItem
