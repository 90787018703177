import React from 'react'
import PT from 'prop-types'

const Divider = ({ children, style, lineColor, margin }) => {
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        marginTop: margin || 16,
        marginBottom: margin || 16,
      }}
    >
      <span
        style={{
          backgroundColor: 'white',
          paddingRight: '16px',
          color: 'rgba(0, 0, 0, 0.25)',
          ...style,
        }}
      >
        {children}
      </span>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          top: '50%',
          zIndex: -1,
          transform: 'translateY(-50%)',
          height: 1,
          backgroundColor: lineColor || 'rgba(0, 0, 0, 0.15)',
        }}
      />
    </div>
  )
}

Divider.propTypes = {
  children: PT.oneOfType([PT.string, PT.node]),
  lineColor: PT.string,
  style: PT.object,
  margin: PT.oneOfType([PT.string, PT.number]),
}

export default Divider
