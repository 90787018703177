import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'antd'

const MenuItem = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const AppbarMenu = ({ menus, onMenuClick }) => {
  return (
    <div style={{ color: '#1C70B0', display: 'flex' }}>
      {menus.map(m => (
        <MenuItem key={m.key} style={m.style} onClick={() => onMenuClick(m.key, m.payload)}>
          {typeof m.icon === 'string' ? <Icon type={m.icon} /> : m.icon}
          <span style={{ marginLeft: m.icon ? 8 : 0 }}>{m.title}</span>
        </MenuItem>
      ))}
    </div>
  )
}

AppbarMenu.propTypes = {
  menus: PT.arrayOf(
    PT.shape({
      key: PT.oneOfType([PT.string, PT.number]).isRequired,
      title: PT.string.isRequired,
      payload: PT.any,
      icon: PT.oneOfType([PT.string, PT.node]),
      style: PT.object,
    }),
  ),
  onMenuClick: PT.func,
}

AppbarMenu.defaultProps = {
  menus: [],
  onMenuClick: (key, payload) => {},
}

export default AppbarMenu
