import React from 'react'
import PT from 'prop-types'
import { Table, Tag, Popconfirm, Icon } from 'antd'
import moment from 'moment'

import Button from '../../../components/Button'

const CampaignTable = ({ height, dataSource, openCampaign, onDelete, loading }) => {
  const [hoverTitle, setTitleHovered] = React.useState()
  return (
    <Table
      dataSource={dataSource}
      rowKey="name"
      pagination={{ hideOnSinglePage: true }}
      loading={loading}
      scroll={{ y: height }}
    >
      <Table.Column
        key="title"
        title="แคมเปญ"
        dataIndex="title"
        onCell={record => ({
          onClick: () => openCampaign(record.name),
          onMouseEnter: () => setTitleHovered(record.name),
          onMouseLeave: () => setTitleHovered(),
        })}
        render={(title, record) => (
          <span
            style={{
              cursor: 'pointer',
              textDecoration: hoverTitle === record.name ? 'underline' : 'none',
            }}
          >
            {title}
          </span>
        )}
      />
      <Table.Column
        ket="tags"
        title="กลุ่มเป้าหมาย"
        dataIndex="tags"
        width={280}
        render={tags => (
          <div>
            {tags.map(t => (
              <Tag key={t} style={{ marginRight: 4 }}>
                {t}
              </Tag>
            ))}
          </div>
        )}
      />
      <Table.Column
        key="schedule"
        title="วันที่ส่ง"
        dataIndex="schedule"
        width={180}
        render={schedule =>
          schedule ? <span>{moment(schedule).format('DD/MM/YYYY HH:mm')}</span> : <span>-</span>
        }
      />
      <Table.Column
        key="action"
        width={80}
        render={record =>
          record.status === 'Draft' && (
            <Popconfirm
              title="ลบแคมเปญนี้?"
              okText="ลบ"
              okType="danger"
              cancelText="ยกเลิก"
              onConfirm={() => onDelete(record.name)}
            >
              <Button type="danger" icon={<Icon type="delete" theme="filled" />} />
            </Popconfirm>
          )
        }
      />
    </Table>
  )
}

CampaignTable.propTypes = {
  openCampaign: PT.func,
  onDelete: PT.func,
  dataSource: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      schedule: PT.string,
      tags: PT.arrayOf(PT.string),
      status: PT.oneOf(['Draft', 'Ready', 'Ready5', 'Sent', 'Canceled', 'Sending']),
    }),
  ),
  loading: PT.bool,
  height: PT.oneOfType([PT.string, PT.number]),
}

CampaignTable.defaultProps = {
  openCampaign: () => {},
  onDelete: () => {},
}

export default CampaignTable
