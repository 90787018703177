export const getDataUrl = (file, cb) => {
  const fr = new FileReader()
  fr.onload = () => {
    cb(fr.result)
  }
  fr.readAsDataURL(file)
}

export function getImageResolution(imageUrl) {
  return new Promise(function(resolve, reject) {
    var img = new Image()
    img.onload = function() {
      resolve({ width: this.width, height: this.height })
    }
    img.onerror = function(err) {
      reject(err)
    }
    img.src = imageUrl
  })
}

export function getObjectUrl(url = '') {
  let tmp = url.split('://')
  const protocol = tmp.length === 2 ? tmp[0] : ''
  tmp = url.replace(`${protocol}://`, '').split('/')
  const filename = tmp.pop()
  return {
    hostname: `${protocol ? protocol + '://' : ''}${tmp[0]}`,
    path: tmp.join('/'),
    filename: filename,
    extension: `.${filename.split('.')[1]}`,
  }
}
