import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import eventRoutes from '../routes/eventRoutes'
import EventList from '../pages/event/EventList'
import CreateEvent from '../pages/event/CreateEvent/'

const EventContainer = props => {
  const initPath = props.match.path
  return (
    <Switch>
      <Redirect from={initPath} to={initPath + '/list'} exact />
      <Route path={initPath + '/list'} component={EventList} />
      <Route path={initPath + '/create'} component={CreateEvent} />
      <Route
        path={initPath + '/:eventId'}
        render={() => (
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Switch>
              <Redirect from={initPath} to={initPath + '/list'} exact />
              {eventRoutes.map(route => (
                <Route
                  key={route.path}
                  path={initPath + route.path}
                  component={route.page}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </div>
        )}
      />
    </Switch>
  )
}

export default EventContainer
