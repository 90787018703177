import React from 'react'
import PT from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Icon } from 'antd'

import Button from '../../../components/Button'

const IMAGE_UPLOAD = gql`
  mutation imageUpload($file: Upload!, $botId: String) {
    imageUpload(file: $file, bot_id: $botId) {
      id
      filename
      mimetype
      encoding
      url
    }
  }
`

const ImageInput = ({ botId, value, onChange, alt, accept, style }) => {
  const [upload] = useMutation(IMAGE_UPLOAD)
  const fileUpload = React.createRef()
  const [loading, setLoading] = React.useState(false)

  const getFilenameFromUrl = uri => {
    const qmIndex = uri.indexOf('?')
    const url = qmIndex !== -1 ? uri.substring(0, qmIndex) : uri

    const splited = url.split('/').filter(t => !!t)
    let tmp = splited[splited.length - 1]

    return tmp
  }

  const handleBtnClick = () => {
    fileUpload.current.click()
  }

  const handleUpload = async e => {
    const file = e.target.files[0]
    setLoading(true)
    try {
      const { data } = await upload({ variables: { file, botId } })
      const imageUpload = (data && data.imageUpload) || {}
      onChange(imageUpload.url)
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <div style={style}>
      {value ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img
            onClick={() => window.open(value, '_blank')}
            src={value}
            alt={alt}
            style={{ width: '180px', cursor: 'pointer' }}
          />
          <div style={{ width: 'calc(100% - 180px - 16px)' }}>
            <Button loading={loading} type="danger" onClick={handleBtnClick}>
              อัพโหลดใหม่
            </Button>
            <small>{getFilenameFromUrl(value)}</small>
          </div>
        </div>
      ) : (
        <Button
          loading={loading}
          type="primary"
          ghost
          block
          icon={<Icon type="upload" />}
          onClick={handleBtnClick}
        >
          อัพโหลดรูปภาพ
        </Button>
      )}
      <input
        ref={fileUpload}
        type="file"
        accept={accept}
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
    </div>
  )
}

ImageInput.propTypes = {
  value: PT.string,
  onChange: PT.func,
  botId: PT.string,
  alt: PT.string,
  loading: PT.bool,
  accept: PT.string,
  style: PT.object,
}

ImageInput.defaultProps = {
  onChange: () => {},
  alt: 'preview',
  accept: 'image/*',
}

export default ImageInput
