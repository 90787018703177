import React from 'react'
import PT from 'prop-types'
import { Table } from 'antd'
import moment from 'moment'

import NO_IMG from '../../../assets/images/no_img.png'
import ImageRenderer from '../../../components/ImageRenderer'

const PastEventTable = ({ data, openEvent, height }) => {
  return (
    <Table
      scroll={{ y: height }}
      dataSource={data}
      rowKey="name"
      pagination={false}
      onRow={rec => ({
        onClick: () => {
          openEvent(rec.name)
        },
      })}
    >
      <Table.Column
        title="ชื่ออีเว้นท์"
        key="title"
        render={(_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageRenderer
              src={record.image_url}
              errorImg={NO_IMG}
              alt={record.name}
              height={56}
              style={{ marginRight: 16, borderRadius: 4 }}
            />
            <div className="text-wrap-ellipsis" style={{ fontWeight: 600 }}>
              {record.title}
            </div>
          </div>
        )}
      />
      <Table.Column
        title="วันที่"
        key="date"
        dataIndex="from"
        render={date => <span>{date ? moment(date).format('DD / MM / YYYY') : '-'}</span>}
      />
      <Table.Column
        title="เวลา"
        key="time"
        render={(_, record) => (
          <span>
            {record.from && record.to
              ? `${moment(record.from).format('HH:mm')} - ${moment(record.to).format('HH:mm')}`
              : '-'}
          </span>
        )}
      />
      <Table.Column title="สถานที่" key="location" dataIndex="venue" />
      <Table.Column title="จำนวนผู้เข้าร่วมงาน" key="audience" />
      )}
    </Table>
  )
}

PastEventTable.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      image_url: PT.string,
      from: PT.string,
      to: PT.string,
      venue: PT.string,
    }),
  ),
  openEvent: PT.func,
  height: PT.number,
}

PastEventTable.defaultProps = {
  openEvent: () => {},
}

export default PastEventTable
