import React from 'react'
import PT from 'prop-types'
import { Icon, Input, Select, message } from 'antd'
import { Prompt } from 'react-router-dom'

import InputField from '../../../components/InputField'
import InputImageFile from '../../../components/InputImageFile'
import Button from '../../../components/Button'

import business_types from '../../../constants/business_types'
import bot_categories from '../../../constants/bot_categories'
import bot_templates from '../../../constants/bot_templates'
import TemplateCard from './TemplateCard'

const BotForm = ({ input, inputChange, onNext }) => {
  const [otherCategoryState, setOtherCategory] = React.useState(false)
  const [otherBusinessState, setOtherBusiness] = React.useState(false)
  const [showTemplates, setShowTemplates] = React.useState(bot_templates[input.category] || [])

  const isFormDirty = () => {
    return Object.keys(input).some(key => !!input[key])
  }

  const validateForm = () => {
    if (!input.title || !input.image || !input.category || !input.business_type) {
      message.error('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (validateForm()) {
      onNext()
    }
  }

  const handleCategoryChange = key => {
    const templates = bot_templates[key] || []
    setShowTemplates(templates)

    if (key === 'other') {
      setOtherCategory(true)
    } else {
      setOtherCategory(false)
      inputChange({ category: key })
    }
  }

  const handleBusinessChange = key => {
    if (key === 'other') {
      setOtherBusiness(true)
    } else {
      setOtherBusiness(false)
      inputChange({ business_type: key })
    }
  }

  return (
    <div>
      <Prompt message="Are you sure you want to leave?" when={isFormDirty()} />
      <InputField title="ชื่อบอท">
        <Input value={input.title} onChange={e => inputChange({ title: e.target.value })} />
      </InputField>
      <InputField title="รูปบอท">
        <small>
          <Icon style={{ marginRight: 4 }} type="info-circle" />
          <span>รูปภาพขนาดสี่เหลี่ยมจัตุรัส เช่น 500x500px., 800x800px. เป็นต้น</span>
        </small>
        <InputImageFile value={input.image} onChange={file => inputChange({ image: file })} />
      </InputField>
      <InputField title="ประเภทธุรกิจ">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={input.business_type}
          onChange={key => handleBusinessChange(key)}
          style={{ width: '100%' }}
        >
          {business_types.map(bt => (
            <Select.Option key={bt}>{bt}</Select.Option>
          ))}
          <Select.Option key="other">อื่นๆ</Select.Option>
        </Select>
        {otherBusinessState && (
          <Input
            value={input.business_type}
            onChange={e => inputChange({ business_type: e.target.value })}
            style={{ marginTop: 8 }}
            autoFocus
          />
        )}
      </InputField>
      <InputField title="การนำไปใช้">
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={input.category}
          onChange={key => handleCategoryChange(key)}
          style={{ width: '100%' }}
        >
          {bot_categories.map(bc => (
            <Select.Option key={bc}>{bc}</Select.Option>
          ))}
          <Select.Option key="other">อื่นๆ</Select.Option>
        </Select>
        {otherCategoryState && (
          <Input
            value={input.category}
            onChange={e => inputChange({ category: e.target.value })}
            style={{ marginTop: 8 }}
            autoFocus
          />
        )}
      </InputField>
      {!!showTemplates.length && (
        <InputField title="เลือกเทมเพลตของบอท">
          <div
            className="smooth-scroll"
            style={{ display: 'flex', flexFlow: 'row nowrap', overflow: 'auto' }}
          >
            <TemplateCard
              data={{ name: 'default', title: 'Default' }}
              onClick={() => inputChange({ template: 'default' })}
              isSelected={input.template === 'default'}
            />
            {showTemplates.map(t => (
              <TemplateCard
                key={t.name}
                data={t}
                onClick={() => inputChange({ template: t.name })}
                isSelected={input.template === t.name}
              />
            ))}
          </div>
        </InputField>
      )}
      <Button style={{ marginTop: 32 }} onClick={handleSubmit} size="large" type="primary" block>
        ถัดไป
      </Button>
    </div>
  )
}

BotForm.propTypes = {
  input: PT.shape({
    title: PT.string,
    image: PT.object,
    category: PT.string,
    business_type: PT.string,
  }),
  inputChange: PT.func,
  onNext: PT.func,
}

BotForm.defaultProps = {
  inputChange: () => {},
  onNext: () => {},
}

export default BotForm
