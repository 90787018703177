import React from 'react'
import ReactApexChart from 'react-apexcharts'
import PT from 'prop-types'

const LineChartDouble = ({ colors }) => {
  const options = {
    annotations: {
      position: 'front',
      points: [
        {
          x: 9,
          y: 148,
          marker: {
            size: 6,
            fillColor: '#fff',
            strokeColor: colors[0],
            radius: 2,
          },
          label: {
            text: '148',
            offsetY: 0,
            borderWidth: 0,
            style: {
              color: colors[0],
            },
          },
        },
        {
          x: 9,
          y: 13,
          marker: {
            size: 6,
            fillColor: '#fff',
            strokeColor: colors[1],
            radius: 2,
          },
          label: {
            text: '13',
            offsetY: 0,
            borderWidth: 0,
            style: {
              color: colors[1],
            },
          },
        },
      ],
    },
    legend: { show: false },
    colors: colors,
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) =>
        `<div class="linechart-tooltip">${series[seriesIndex][dataPointIndex]}</div>`,
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      show: false,
    },
  }
  const series = [
    {
      name: 'active',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
    {
      name: 'block',
      data: [10, 22, 18, 9, 0, 0, 32, 38, 13],
    },
  ]
  return (
    <div
      style={{
        width: 320,
        borderRadius: 4,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        padding: '16px 8px',
        marginRight: 16,
        marginBottom: 16,
      }}
    >
      <div style={{ padding: '0 8px', display: 'flex' }}>
        <div style={{ marginRight: 16 }}>
          <div style={{ fontWeight: 600, fontSize: '1.3em' }}>เพื่อนใหม่</div>
          <span style={{ fontSize: '1.8em', fontWeight: 500, marginRight: 8, color: colors[0] }}>
            100
          </span>
          <small>32%</small>
        </div>
        <div>
          <div style={{ fontWeight: 600, fontSize: '1.3em' }}>ถูกบล็อค</div>
          <span style={{ fontSize: '1.8em', fontWeight: 500, marginRight: 8, color: colors[1] }}>
            10
          </span>
          <small>4%</small>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="line" />
    </div>
  )
}

LineChartDouble.propTypes = {
  colors: PT.arrayOf(PT.string),
}

LineChartDouble.defaultProps = {
  colors: ['#00D196', '#FF5C5C'],
}

export default LineChartDouble
