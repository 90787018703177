import React from 'react'
import PT from 'prop-types'
import { Tag, Tooltip } from 'antd'

import AIYA_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import ImageRenderer from '../../../components/ImageRenderer'

const AudienceCard = ({ data, onClick }) => {
  const tags = data.tags || []
  const [sTags, hTags] = tags.length > 3 ? [tags.slice(0, 3), tags.slice(3)] : [tags, []]
  return (
    <div
      style={{
        display: 'flex',
        width: 480,
        height: 100,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        margin: 8,
        borderRadius: 4,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick && (() => onClick(data))}
    >
      <div style={{ width: 100, height: 100, padding: 14 }}>
        <ImageRenderer
          style={{ width: 72, height: 72, borderRadius: '50%' }}
          src={data.picture_url || AIYA_BLANK}
          alt={data.id}
          errorImg={AIYA_BLANK}
        />
      </div>
      <div style={{ width: 'calc(100% - 100px)', padding: 14 }}>
        <div>
          <span style={{ fontSize: '1.4em' }}>{data.full_name}</span>
        </div>
        <div style={{ marginBottom: 4 }}>
          <span style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}>ID: {data.id}</span>
        </div>
        <div>
          {sTags.map(t => (
            <Tag key={t}>{t}</Tag>
          ))}
          {!!hTags.length && (
            <Tooltip
              title={hTags.map(t => (
                <div key={t}>{t}</div>
              ))}
            >
              <Tag>+ {hTags.length}</Tag>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

AudienceCard.propTypes = {
  onClick: PT.func,
  data: PT.shape({
    id: PT.string,
    full_name: PT.string,
    picture_url: PT.string,
    tags: PT.arrayOf(PT.string),
  }),
}

export default AudienceCard
