import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Spin, Input, Select, Icon, Menu } from 'antd'

import AudienceCard from './AudienceCard'
import BotContext from '../../../context/Bot'
import Loading from '../../../components/Loading'
import NoData from '../../../components/NoData'
import NumberRenderer from '../../../components/NumberRenderer'
import Tutorial from '../../../components/Tutorial'

const ALL_FRIENDS = gql`
  query allFriends($botId: String!, $page: JSON, $filters: FriendFilter) {
    allFriends(bot_id: $botId, page: $page, filters: $filters) {
      total
      blocked
      pagination
      friends {
        id
        full_name
        picture_url
        tags
      }
    }
  }
`

const GET_FRIEND = gql`
  query getFriend($botId: String!, $uuid: String!) {
    getFriend(bot_id: $botId, uuid: $uuid) {
      id
      full_name
      picture_url
      tags
    }
  }
`

const SUB_FRIEND_DOC = gql`
  subscription onFriendChanged($botId: String!) {
    onFriendChanged(bot_id: $botId) {
      id
      doctype
      op
    }
  }
`

const AudienceList = ({ history }) => {
  const bot = React.useContext(BotContext)
  const [moreLoading, setMoreLoading] = React.useState(false)
  const [page, setPage] = React.useState({ next: '', size: 40 })
  const [filters, setFilters] = React.useState({ search: '', tags: [], blocked: false })

  const { data, loading, error, fetchMore, subscribeToMore } = useQuery(ALL_FRIENDS, {
    variables: { botId: bot.name, page, filters },
  })
  const [getFriend] = useLazyQuery(GET_FRIEND, { fetchPolicy: 'network-only' })

  React.useEffect(() => {
    let unsub = () => {}
    const subscribeFriendDoc = () => {
      unsub = subscribeToMore({
        document: SUB_FRIEND_DOC,
        variables: { botId: bot.name },
        updateQuery: async (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          try {
            getFriend({
              variables: { botId: bot.name, uuid: subscriptionData.data.onFriendChanged.id },
            })
            return prev
          } catch (err) {
            console.error(err)
            return prev
          }
        },
      })
    }

    subscribeFriendDoc()

    return () => {
      unsub()
    }
  }, [bot.name, subscribeToMore, getFriend])

  if (error) {
    console.error(error)
  }

  const allFriends = (data && data.allFriends) || {}
  const friends = allFriends.friends || []
  const pagination = allFriends.pagination || {}

  const openDetail = uuid => {
    history.push(`/app/bot/${bot.name}/audience/friend/${uuid}`)
  }

  const handleScroll = value => {
    if (value.top === 1) {
      onLoadMore()
    }
  }

  const handleSearch = s => {
    setFilters({ ...filters, search: s })
    setPage({ next: '', size: page.size })
  }

  const handleTagChange = tags => {
    setFilters({ ...filters, tags })
    setPage({ next: '', size: page.size })
  }

  const onLoadMore = async () => {
    if (pagination.next == null) {
      return
    }
    setMoreLoading(true)
    try {
      await fetchMore({
        query: ALL_FRIENDS,
        variables: {
          botId: bot.name,
          page: { size: pagination.size, next: pagination.next },
          filters,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newAllFriends = fetchMoreResult.allFriends
          return {
            ...prev,
            allFriends: {
              ...newAllFriends,
              friends: [...friends, ...newAllFriends.friends],
            },
          }
        },
      })
    } catch (err) {
      console.error(err)
    }
    setMoreLoading(false)
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <h1 style={{ marginRight: 8 }}>เพื่อนทั้งหมด</h1>
          <span>
            (จำนวน <NumberRenderer value={allFriends.total || 0} />)
          </span>
        </div>
        <div>
          <Input.Search
            onSearch={handleSearch}
            placeholder="ค้นหาเพื่อน.."
            style={{ width: 180, marginRight: 8 }}
          />
          <Select
            placeholder={
              <span>
                <Icon type="tag" style={{ marginRight: 4 }} />
                <span>กรองตามแท็ก..</span>
              </span>
            }
            value={filters.tags}
            onChange={handleTagChange}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
            style={{ width: 200 }}
          >
            {(bot.friend_tags || []).map(t => (
              <Select.Option key={t}>{t}</Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div style={{ overflowY: 'auto', height: 'calc(100% - 97px - 42px)' }}>
        {/* <Menu
          onClick={val => setFilters({ ...filters, blocked: val.key === '1' })}
          selectedKeys={[filters.blocked ? '1' : '0']}
          mode="horizontal"
        >
          <Menu.Item key="0">
            <span>
              Active (<NumberRenderer value={allFriends.total - allFriends.blocked} />)
            </span>
          </Menu.Item>
          <Menu.Item key="1">
            <span>
              Blocked (<NumberRenderer value={allFriends.blocked} />)
            </span>
          </Menu.Item>
        </Menu> */}
        {loading ? (
          <div style={{ height: 'calc(100% - 97px - 42px)', position: 'relative' }}>
            <Loading />
          </div>
        ) : (
          <Scrollbars onScrollFrame={handleScroll} style={{ height: 'calc(100%)' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: 16,
              }}
            >
              {friends.length ? (
                friends.map(f => (
                  <AudienceCard key={f.id} data={f} onClick={f => openDetail(f.id)} />
                ))
              ) : (
                <NoData />
              )}
            </div>
            {moreLoading && pagination.next && (
              <div style={{ textAlign: 'center' }}>
                <Spin style={{ margin: '32px 0' }} />
              </div>
            )}
          </Scrollbars>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          videoLink="https://youtu.be/FuKrsQtJIK0"
          slideLink="https://drive.google.com/file/d/1K2q84d6Uwh-nK0R2kaLcATJKHnN141Yk/view"
        />
      </div>
    </div>
  )
}

export default AudienceList
