import React from 'react'
import Button from '../../../components/Button'
import { Icon } from 'antd'

const VAList = () => {
  return (
    <div style={{ textAlign: 'center', width: '100%', height: '100%', display: 'flow-root' }}>
      <div style={{ margin: '6rem auto 2rem' }}>
        <h1 style={{ color: '#1C70B0', fontWeight: 600 }}>ACRM VA</h1>
        <p>
          <div>Virtual Assistant (VA) เป็นระบบจัดการบอท ภายในแฟลตฟอร์ม ACRM</div>
          <div>ทำให้ง่ายต่อการจัดการบัญชีภายในองค์กร</div>
        </p>
      </div>
      <Button style={{ margin: 'auto', width: 160 }} type="primary" icon={<Icon type="plus" />}>
        เพิ่ม VA
      </Button>
    </div>
  )
}

export default VAList
