import React from 'react'
import { Icon, Input } from 'antd'
import styled from 'styled-components'
import PT from 'prop-types'

const ButtonContainer = styled.div`
  min-width: 220px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  color: #b7b7b7;
  cursor: pointer;
`

const AddGroupBtn = ({ onAdd }) => {
  const [editing, setEditing] = React.useState(false)
  const [input, setInput] = React.useState('')

  const handleSubmit = () => {
    if (input === '') {
      setEditing(false)
      return
    }
    onAdd(input)
    setEditing(false)
    setInput('')
  }

  return (
    <ButtonContainer onClick={() => setEditing(true)}>
      {editing ? (
        <Input
          value={input}
          onChange={e => setInput(e.target.value)}
          onBlur={() => handleSubmit()}
          onPressEnter={() => handleSubmit()}
          autoFocus
        />
      ) : (
        <>
          <Icon type="plus" style={{ marginRight: 4 }} />
          <span>เพิ่มกลุ่มเอเจนท์</span>
        </>
      )}
    </ButtonContainer>
  )
}

AddGroupBtn.propTypes = {
  onAdd: PT.func,
}

AddGroupBtn.defaultProps = {
  onAdd: () => {},
}

export default AddGroupBtn
