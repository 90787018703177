import React from 'react'
import PT from 'prop-types'
import { Input, Select, Radio, DatePicker, TimePicker, InputNumber } from 'antd'
import moment from 'moment'

import InputField from '../../../../components/InputField'
import InputFile from '../../../../components/InputFile'
import ProgressBar from '../../../../components/ProgressBar'

const SERVER_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const BroadcastConfigForm = ({
  loading,
  botId,
  allChannels,
  allSegments,
  allTags,
  broadcast,
  onConfigChange,
  readOnly,
  targetedNumber,
}) => {
  const [isLimit, limitChange] = React.useState(broadcast.limit !== 0)
  const [title, setTitle] = React.useState(broadcast.title)
  const [limit, setLimit] = React.useState(broadcast.limit)

  React.useEffect(() => {
    setTitle(broadcast.title)
    setLimit(broadcast.limit)
    limitChange(broadcast.limit !== 0)
  }, [broadcast.title, broadcast.limit])

  const handleModeChange = mode => {
    if (mode === 'now') {
      onConfigChange({ schedule: undefined, is_now: true })
    } else {
      onConfigChange({ is_now: false })
    }
  }

  const handleLimitChange = value => {
    if (value === 'all') {
      onConfigChange({ limit: 0 })
    }
    limitChange(value === 'limit')
  }

  const handleDateChange = date => {
    const curr = broadcast.schedule ? moment(broadcast.schedule, SERVER_DATE_FORMAT) : moment()
    const tmp = moment({
      y: date.year(),
      M: date.month(),
      d: date.date(),
      h: curr.hour(),
      m: curr.minute(),
      s: 0,
      ms: 0,
    })
    onConfigChange({ schedule: tmp.format(SERVER_DATE_FORMAT) })
  }

  const handleTimeChange = time => {
    const curr = broadcast.schedule ? moment(broadcast.schedule, SERVER_DATE_FORMAT) : moment()
    const tmp = moment({
      y: curr.year(),
      M: curr.month(),
      d: curr.date(),
      h: time.hour(),
      m: time.minute(),
      s: 0,
      ms: 0,
    })
    onConfigChange({ schedule: tmp.format(SERVER_DATE_FORMAT) })
  }

  return (
    <>
      <div style={{ padding: '12px 36px', borderBottom: '1px solid rgba(183, 183, 183, 1)' }}>
        <InputField title="ชื่อหัวข้อบรอดแคสต์" isRequired>
          <Input
            value={title}
            onChange={e => setTitle(e.target.value)}
            onBlur={() => onConfigChange({ title })}
            onPressEnter={() => onConfigChange({ title })}
            disabled={loading}
            readOnly={readOnly}
          />
        </InputField>
        <InputField title="เวลาส่งบรอดแคสต์" isRequired>
          <Radio.Group
            value={broadcast.is_now ? 'now' : 'schedule'}
            onChange={e => handleModeChange(e.target.value)}
            disabled={loading || readOnly}
          >
            <div>
              <Radio value="now">ส่งทันที</Radio>
            </div>
            <div>
              <Radio value="schedule">
                <span style={{ marginRight: 4 }}>ตั้งวันที่</span>
                <DatePicker
                  placeholder="DD / MM / YYYY"
                  style={{ width: 150, marginRight: 12 }}
                  disabled={loading || broadcast.is_now}
                  format="DD / MM / YYYY"
                  value={
                    broadcast.schedule ? moment(broadcast.schedule, SERVER_DATE_FORMAT) : undefined
                  }
                  onChange={handleDateChange}
                />
                <span style={{ marginRight: 4 }}>ตั้งเวลา</span>
                <TimePicker
                  placeholder="HH : mm"
                  style={{ width: 120 }}
                  disabled={loading || broadcast.is_now}
                  format="HH : mm"
                  value={
                    broadcast.schedule ? moment(broadcast.schedule, SERVER_DATE_FORMAT) : undefined
                  }
                  onChange={handleTimeChange}
                />
              </Radio>
            </div>
          </Radio.Group>
        </InputField>
        <InputField title="จำนวนผู้รับสูงสุด">
          <Radio.Group
            value={isLimit ? 'limit' : 'all'}
            onChange={e => handleLimitChange(e.target.value)}
            disabled={loading || readOnly}
          >
            <div>
              <Radio value="all">ไม่จำกัด</Radio>
            </div>
            <div>
              <Radio value="limit">
                <span style={{ marginRight: 8 }}>จำกัดที่</span>
                <InputNumber
                  value={limit}
                  onChange={val => setLimit(val)}
                  onBlur={() => onConfigChange({ limit: Number(limit) || 0 })}
                  onPressEnter={() => onConfigChange({ limit: Number(limit) || 0 })}
                  disabled={loading || !isLimit}
                  readOnly={readOnly}
                />
              </Radio>
            </div>
          </Radio.Group>
        </InputField>
      </div>
      <div style={{ padding: '12px 36px', borderBottom: '1px solid rgba(183, 183, 183, 1)' }}>
        <InputField title="ช่องทางการส่งบรอดแคสต์" isRequired>
          <Select
            style={{ width: '100%' }}
            placeholder="เลือกช่องทาง"
            value={broadcast.channel_id}
            onChange={key => onConfigChange({ channel_id: key })}
            loading={loading}
            disabled={readOnly}
          >
            <Select.OptGroup key="Line OA">
              {allChannels
                .filter(ch => ch.platform === 'LineBot')
                .map(ch => (
                  <Select.Option key={ch.id}>{ch.title}</Select.Option>
                ))}
            </Select.OptGroup>
            <Select.OptGroup key="Facebook Page">
              {allChannels
                .filter(ch => ch.platform === 'FacebookBot')
                .map(ch => (
                  <Select.Option key={ch.id}>{ch.title}</Select.Option>
                ))}
            </Select.OptGroup>
          </Select>
        </InputField>
        <InputField title="กลุ่มผู้รับ">
          <Select
            style={{ width: '100%', marginBottom: 8 }}
            value={broadcast.broadcast_type}
            onChange={val => onConfigChange({ broadcast_type: val })}
            loading={loading}
            disabled={!broadcast || readOnly}
          >
            <Select.Option key="ALL">เพื่อนทุกคน</Select.Option>
            <Select.Option key="TAG">เลือกตามแท็ก</Select.Option>
            <Select.Option key="SEGMENT">เลือกจากกลุ่มเป้าหมาย</Select.Option>
            <Select.Option key="FILE">อัพโหลดไฟล์เทมเพลต</Select.Option>
          </Select>
          {broadcast.broadcast_type === 'TAG' && (
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="เลือกตามแท็ก.."
              value={broadcast.target || []}
              onChange={tags => onConfigChange({ broadcast_type: 'TAG', target: tags })}
              loading={loading}
              disabled={readOnly}
            >
              {allTags.map(t => (
                <Select.Option key={t}>{t}</Select.Option>
              ))}
            </Select>
          )}
          {broadcast.broadcast_type === 'SEGMENT' && (
            <Select
              style={{ width: '100%' }}
              placeholder="เลือกจากกลุ่มเป้าหมาย.."
              value={broadcast.target}
              onChange={segment => onConfigChange({ broadcast_type: 'SEGMENT', target: segment })}
              loading={loading}
              disabled={readOnly}
            >
              {allSegments.map(s => (
                <Select.Option key={s.name}>{s.title}</Select.Option>
              ))}
            </Select>
          )}
          {broadcast.broadcast_type === 'FILE' && (
            <InputFile
              loading={loading}
              botId={botId}
              value={broadcast.target}
              onChange={url => onConfigChange({ broadcast_type: 'FILE', target: url })}
              newFilename={broadcast.id}
              dirname="broadcast"
            />
          )}
          <ProgressBar
            value={targetedNumber}
            max={broadcast.targetReach}
            style={{ marginTop: 8, marginBottom: 8, height: 20 }}
          />
        </InputField>
      </div>
    </>
  )
}

BroadcastConfigForm.propTypes = {
  allTags: PT.arrayOf(PT.string),
  allSegments: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
    }),
  ),
  allChannels: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
    }),
  ),
  broadcast: PT.shape({
    id: PT.string,
    title: PT.string,
    broadcast_type: PT.oneOf(['ALL', 'TAG', 'SEGMENT', 'FILE']),
    target: PT.any,
    schedule: PT.number,
    channel_id: PT.string,
    is_now: PT.bool,
    limit: PT.number,
    messages: PT.arrayOf(PT.object),
    targetReach: PT.number,
  }),
  onConfigChange: PT.func,
  readOnly: PT.bool,
  botId: PT.string,
  targetedNumber: PT.number,
  loading: PT.bool,
}

export default BroadcastConfigForm
