import React from 'react'
import Field from './Field'
import { Icon } from 'antd'

import SocialConnector from './SocialConnector'
import Button from '../../../components/Button'

const Account = () => {
  return (
    <div style={{ height: '100%', width: 420, padding: '24px 36px' }}>
      <h1 style={{ marginBottom: 32 }}>จัดการบัญชี</h1>
      <div>
        <Field title="ชื่อ">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>Username</span>
            <div>
              <Button type="primary">
                <Icon theme="filled" type="setting" />
              </Button>
            </div>
          </div>
        </Field>
        <Field title="อีเมล">
          <SocialConnector social="email" />
        </Field>
        <Field title="ไลน์">
          <SocialConnector social="line" />
        </Field>
        <Field title="เฟสบุ๊ค">
          <SocialConnector social="facebook" />
        </Field>
        <Field title="จำนวนเครดิต">
          <span>1,500 บาท</span>
        </Field>
      </div>
    </div>
  )
}

export default Account
