import React from 'react'
import PT from 'prop-types'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const InputDraft = ({ defaultValue, onChange, ...rest }) => {
  let initEditorState = EditorState.createEmpty()
  if (defaultValue) {
    const contentBlock = htmlToDraft(defaultValue)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      initEditorState = editorState
    }
  }
  const [editorState, setEditorState] = React.useState(initEditorState)

  const handleEditorStateChange = editorState => {
    setEditorState(editorState)
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange(html)
  }

  return (
    <Editor
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'embedded',
          'emoji',
          'image',
          'history',
        ],
      }}
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={handleEditorStateChange}
      editorStyle={{
        border: '1px solid rgba(217, 217, 217)',
        borderRadius: 4,
        padding: '4px 11px',
        resize: 'vertical',
        minHeight: 94,
      }}
      {...rest}
    />
  )
}

InputDraft.propTypes = {
  defaultValue: PT.string,
  onChange: PT.func,
}

InputDraft.defaultProps = {
  onChange: () => {},
}

export default InputDraft
