import React from 'react'
import PT from 'prop-types'
import { InputNumber, Icon } from 'antd'

import ActionItem from './ActionItem'
import Button from '../../../../../components/Button'
import InputField from '../../../../../components/InputField'

class ActionsEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      grid: props.initial.grid || { w: 1, h: 1 },
      actions: props.initial.actions || [],
    }
  }

  getAreaFromGridIndex = (grid, baseSize, index) => {
    const width = baseSize.width / grid.w
    const height = baseSize.height / grid.h
    return {
      x: width * (index % grid.w),
      y: height * Math.floor(index / grid.w),
      width,
      height,
    }
  }

  actionChange = (action, index) => {
    let tmp = [...this.state.actions]
    tmp[index] = { ...tmp[index], ...action }
    this.setState({ actions: tmp })
  }

  gridChange = item => {
    const { grid } = this.state
    this.setState({ grid: { ...grid, ...item } })
  }

  generateGrid = () => {
    const { grid } = this.state
    const num = grid.w * grid.h
    let tmp = []
    for (let i = 0; i < num; i++) {
      tmp.push({
        type: 'none',
        data: '',
      })
    }
    this.setState({ actions: tmp })
  }

  componentDidUpdate(prevProps, prevState) {
    const { baseSize, onChange } = this.props
    const { actions, grid } = this.state
    if (prevProps.baseSize !== baseSize || prevState.actions !== actions) {
      const tmp = actions
        .map((a, i) =>
          a.type === 'none'
            ? undefined
            : {
                type: a.type,
                [a.type === 'uri' ? 'linkUri' : 'text']: a.data,
                area: this.getAreaFromGridIndex(grid, baseSize, i),
              },
        )
        .filter(a => a != null)
      onChange(tmp, { _actions: actions, grid })
    }
  }

  render() {
    const { loading } = this.props
    const { grid, actions } = this.state
    return (
      <div>
        <InputField title="Grid (กว้าง x สูง)">
          <div>
            <InputNumber
              value={grid.w}
              onChange={val => this.gridChange({ w: val })}
              min={1}
              style={{ width: 60 }}
              disabled={loading}
            />
            <span style={{ marginLeft: 4, marginRight: 4 }}>x</span>
            <InputNumber
              value={grid.h}
              onChange={val => this.gridChange({ h: val })}
              min={1}
              style={{ width: 60, marginRight: 8 }}
              disabled={loading}
            />
            <Button
              onClick={this.generateGrid}
              type="primary"
              icon={<Icon type="setting" theme="filled" />}
              loading={loading}
            />
          </div>
        </InputField>
        {actions.map((a, i) => (
          <ActionItem
            key={i}
            index={i + 1}
            action={a}
            onChange={val => this.actionChange(val, i)}
            loading={loading}
          />
        ))}
      </div>
    )
  }
}

ActionsEditor.propTypes = {
  initial: PT.shape({
    grid: PT.shape({ w: PT.number, h: PT.number }),
    actions: PT.arrayOf(
      PT.shape({
        type: PT.string,
        data: PT.string,
      }),
    ),
  }),
  baseSize: PT.shape({
    width: PT.number,
    height: PT.number,
  }),
  onChange: PT.func,
  loading: PT.bool,
}

ActionsEditor.defaultProps = {
  onChange: () => {},
}

export default ActionsEditor
