import React from 'react'
import { Icon, Steps } from 'antd'

import BotForm from './BotForm'
import Confirmation from './Confirmation'
import Success from './Success'

import AIYA_LOGO from '../../../assets/images/aiya-logo-blue.png'
import ImageRenderer from '../../../components/ImageRenderer'

class BotCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currStep: 0,
      input: {
        title: '',
        image: undefined,
        category: '',
        business_type: '',
        template: 'default',
      },
      bot: undefined,
    }
  }

  handleCreateBot = bot => {
    this.setState({ bot })
    this.nextStep()
  }

  inputChange = item => {
    const { input } = this.state
    this.setState({ input: { ...input, ...item } })
  }

  nextStep = () => {
    const { currStep } = this.state
    this.setState({ currStep: currStep + 1 })
  }

  backStep = () => {
    const { currStep } = this.state
    this.setState({ currStep: currStep - 1 })
  }

  gotoBotList = () => {
    const { history } = this.props
    history.push('/app/bot/list')
  }

  gotoBot = () => {
    const { bot } = this.state
    const { history } = this.props
    history.push(`/app/bot/${bot.name}/setting/channel`)
  }

  render() {
    const { currStep, input } = this.state

    const contents = [
      {
        title: 'ลงทะเบียนข้อมูลบอทใหม่',
        icon: <Icon type="edit" style={{ fontSize: '0.8em' }} />,
        content: (
          <BotForm
            input={input}
            inputChange={this.inputChange}
            onNext={this.nextStep}
            gotoLogin={this.gotoLogin}
          />
        ),
      },
      {
        title: 'ตรวจสอบรายละเอียดบอท',
        icon: <Icon type="form" style={{ fontSize: '0.8em' }} />,
        content: <Confirmation data={input} onBack={this.backStep} onNext={this.handleCreateBot} />,
      },
      {
        title: 'เสร็จสิ้นการลงทะเบียนบอทใหม่',
        icon: <Icon type="check-circle" style={{ fontSize: '0.8em' }} />,
        content: <Success gotoBotList={this.gotoBotList} gotoBot={this.gotoBot} />,
      },
    ]

    const activeStyle = { color: 'rgba(38, 119, 180, 1)' }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column nowrap',
        }}
      >
        <ImageRenderer src={AIYA_LOGO} alt="AIYA" width={150} style={{ marginTop: 8 }} />
        <h1>สร้างบอท</h1>
        <div style={{ width: '900px', marginBottom: 36 }}>
          <Steps size="small" current={currStep}>
            {contents.map((ct, i) => (
              <Steps.Step
                key={i}
                title={<span style={currStep >= i ? activeStyle : {}}>{ct.title}</span>}
                icon={ct.icon}
              />
            ))}
          </Steps>
        </div>
        <div style={{ width: 468, marginBottom: 48 }}>{(contents[currStep] || {}).content}</div>
      </div>
    )
  }
}

export default BotCreate
