import React from 'react'
import { message, Icon } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'

import EventCard from './EventCard'
import PastEventTable from './PastEventTable'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import NoData from '../../../components/NoData'

const ALL_ACTIVITY = gql`
  query allActivity($botId: String!) {
    allActivity(bot_id: $botId) {
      name
      title
      image_url
      from
      to
      venue
    }
  }
`

const CREATE_ACTIVITY = gql`
  mutation createActivity($title: String!, $botId: String!) {
    createActivity(title: $title, bot_id: $botId) {
      name
    }
  }
`

const EventList = ({ botId, history, activities }) => {
  const openCreateEvent = () => {
    history.push(`/app/bot/${botId}/event/create`)
  }

  const openEvent = eventId => {
    history.push(`/app/bot/${botId}/event/${eventId}/editor/info`)
  }

  const upComingEvents = activities.filter(a =>
    a.to ? moment(a.to, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(moment()) : true,
  )

  const pastEvents = activities.filter(a =>
    a.to ? moment(a.to, 'YYYY-MM-DD HH:mm:ss').isBefore(moment()) : false,
  )

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <div
        style={{
          padding: '24px 36px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>รายการอีเว้นท์</h1>
        <div>
          <Button type="primary" ghost icon={<Icon type="plus" />} onClick={openCreateEvent}>
            สร้างอีเว้นท์
          </Button>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          minHeight: 240,
          display: 'flex',
          flexFlow: 'row wrap',
          padding: '24px 36px 16px',
        }}
      >
        {upComingEvents.length ? (
          upComingEvents.map((a, i) => (
            <EventCard
              key={i}
              data={a}
              style={{ margin: '0 24px 24px 0' }}
              onClick={() => openEvent(a.name)}
            />
          ))
        ) : (
          <NoData />
        )}
      </div>
      <div
        style={{
          padding: '24px 36px 16px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>อีเว้นท์ที่สิ้นสุดแล้ว</h1>
      </div>
      <PastEventTable
        data={pastEvents}
        openEvent={openEvent}
        height={window.innerHeight - 60 - 48 - 98 - 54}
      />
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const { data, error, loading } = useQuery(ALL_ACTIVITY, {
    variables: { botId },
    fetchPolicy: 'cache-and-network',
  })
  const [createActivity] = useMutation(CREATE_ACTIVITY, {
    refetchQueries: [{ query: ALL_ACTIVITY }],
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    message.error(error.message)
    console.error(error.message)
  }
  const allActivity = (data && data.allActivity) || []
  return (
    <EventList {...props} botId={botId} activities={allActivity} createActivity={createActivity} />
  )
}
