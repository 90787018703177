export default {
  ขายของออนไลน์: [],
  ระบบจัดการอีเวนท์: [],
  งานฝ่ายบุคคล: [],
  งานบัญชี: [],
  งานสถานพยาบาล: [],
  งานบริการ: [],
  งานประชาสัมพันธ์: [],
  บริการภาครัฐ: [],
}
