import React from 'react'
import PT from 'prop-types'

const ImageRenderer = ({ src, alt, errorImg, clickToOpen, style, ...rest }) => {
  const [imgUrl, setUrl] = React.useState(src)

  React.useEffect(() => {
    setUrl(src)
  }, [src])

  const handleError = () => {
    setUrl(errorImg)
  }

  return (
    <img
      src={imgUrl || errorImg}
      alt={alt}
      onError={handleError}
      onClick={clickToOpen ? () => window.open(imgUrl || errorImg, '_blank') : undefined}
      style={{ cursor: clickToOpen ? 'pointer' : 'inherit', userSelect: 'none', ...style }}
      {...rest}
    />
  )
}

ImageRenderer.propTypes = {
  src: PT.string,
  alt: PT.string.isRequired,
  errorImg: PT.string,
  clickToOpen: PT.bool,
}

export default ImageRenderer
