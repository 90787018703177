import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'
import { useDrop } from 'react-dnd'

import AgentItem from './AgentItem'
import { PT_AGENT } from '../propTypes'
import NoData from '../../../components/NoData'
import { ItemTypes } from './dndConstants'

const AgentPool = ({ agents, handleAgentDrop }) => {
  const [filter, setFilter] = React.useState('')
  const [, poolRef] = useDrop({
    accept: ItemTypes.AGENT,
    drop: () => ({ dropTarget: 'pool' }),
  })
  const filteredAgents = agents.filter(a =>
    a.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
  )
  return (
    <div style={{ position: 'relative', height: '100%' }} ref={poolRef}>
      <div style={{ fontSize: '1.5em', color: '#707070', textAlign: 'center', marginBottom: 16 }}>
        รายชื่อเอเจนท์
      </div>
      <Input.Search
        style={{ width: '100%', marginBottom: 8 }}
        placeholder="ค้นหาเอเจนท์"
        onSearch={s => setFilter(s)}
      />
      {filteredAgents.length ? (
        filteredAgents.map(a => (
          <AgentItem key={a.name} data={a} owner="pool" fullCard onDrop={handleAgentDrop} />
        ))
      ) : (
        <NoData />
      )}
    </div>
  )
}

AgentPool.propTypes = {
  agents: PT.arrayOf(PT_AGENT),
  handleAgentDrop: PT.func,
}

AgentPool.defaultProps = {
  handleAgentDrop: () => {},
}

export default AgentPool
