import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message, Modal } from 'antd'

import AddGroupBtn from './AddGroupBtn'
import AgentPool from './AgentPool'
import GroupItem from './GroupItem'
import Loading from '../../../components/Loading'
import { colorsPool } from '../../../constants/colors'
import BotContext from '../../../context/Bot'

const ALL_AGENT_GROUPS = gql`
  query allAgentGroups($botId: String!) {
    allAgentGroups(bot_id: $botId) {
      name
      bot
      bot_name
      title
      description
      users {
        email
        full_name
      }
      segments {
        name
        title
      }
    }
    allAgents(bot_id: $botId) {
      name
      title
      role
    }
    allFriendSegments(bot_id: $botId) {
      name
      title
    }
  }
`

const CREATE_AGENT_GROUP = gql`
  mutation createAgentGroup($botId: String!, $input: AgentGroupInput!) {
    createAgentGroup(bot_id: $botId, input: $input) {
      name
    }
  }
`

const UPDATE_AGENT_GROUP = gql`
  mutation updateAgentGrouop($botId: String!, $groupId: String!, $input: AgentGroupInput) {
    updateAgentGroup(bot_id: $botId, group_id: $groupId, input: $input) {
      name
    }
  }
`

const DELETE_AGENT_GROUP = gql`
  mutation deleteAgentGroup($botId: String!, $groupId: String!) {
    deleteAgentGroup(bot_id: $botId, group_id: $groupId)
  }
`

class AgentGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  moveAgent = (agent, fromGroupId, toGroupId) => {
    const { groups } = this.props
    const fromGroup = groups.find(g => g.name === fromGroupId)
    const toGroup = groups.find(g => g.name === toGroupId)
    if (fromGroupId !== 'pool') {
      // remove agent from group [from]
      let tmp = [...fromGroup.agents]
      const indexToDel = tmp.findIndex(a => a.name === agent.name)
      if (indexToDel !== -1) {
        tmp.splice(indexToDel, 1)
      }
      this.handleGroupUpdate(fromGroupId, { agents: tmp })
    }
    if (toGroupId !== 'pool') {
      // if toGroup have no agent yet then..
      // add agent to group [to]
      const hasAgent = toGroup.agents.findIndex(a => a.name === agent.name) !== -1
      if (!hasAgent) {
        const tmp = [...toGroup.agents, agent]
        this.handleGroupUpdate(toGroupId, { agents: tmp })
      }
    }
  }

  handleGroupCreate = async title => {
    const { bot, createAgentGroup } = this.props
    try {
      await createAgentGroup({ variables: { botId: bot.name, input: { title } } })
      message.success('สร้างกลุ่มเอเจนท์สำเร็จ')
    } catch (err) {
      console.error(err)
    }
  }

  handleGroupUpdate = async (groupId, item) => {
    const { bot, updateAgentGroup } = this.props
    try {
      const mappedInput = {
        ...item,
        users: item.agents
          ? item.agents.map(a => ({ email: a.name, full_name: a.title }))
          : undefined,
        agents: undefined,
      }
      await updateAgentGroup({ variables: { botId: bot.name, groupId, input: mappedInput } })
      message.success('บันทึก')
    } catch (err) {
      console.error(err)
    }
  }

  handleGroupDelete = group => {
    Modal.confirm({
      title: 'ยืนยันการลบกลุ่มเอเจนท์',
      content: (
        <div>
          กลุ่มเอเจนท์ <b>{group.title}</b> จะโดนลบไปอย่างถาวร
        </div>
      ),
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        const { bot, deleteAgentGroup } = this.props
        try {
          await deleteAgentGroup({ variables: { botId: bot.name, groupId: group.name } })
          message.success('บันทึก')
        } catch (err) {
          console.error(err)
        }
      },
    })
  }

  handleAgentDrop = (item, dropTarget) => {
    if (dropTarget && item.owner !== dropTarget) {
      this.moveAgent(item.data, item.owner, dropTarget)
    }
  }

  handleAgentDelete = (agentId, groupId) => {
    const { groups } = this.props
    const targetGroup = groups.find(g => g.name === groupId)
    let tmp = [...targetGroup.agents]
    const indexToDel = tmp.findIndex(a => a.name === agentId)
    if (indexToDel !== -1) {
      tmp.splice(indexToDel, 1)
    }
    this.handleGroupUpdate(groupId, { agents: tmp })
  }

  handleSegmentsChange = (segmentIds, groupId) => {
    const { allSegments } = this.props
    const tmp = segmentIds.map(id => allSegments.find(s => s.name === id))
    this.handleGroupUpdate(groupId, { segments: tmp })
  }

  render() {
    const { agents, groups, allSegments, loading: loadingProps } = this.props
    const { loading } = this.state
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            padding: '24px 36px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <h1>กลุ่มเอเจนท์</h1>
        </div>
        <div style={{ display: 'flex', height: 'calc(100% - 97px)', position: 'relative' }}>
          {loadingProps ? (
            <Loading />
          ) : (
            <>
              <div
                style={{
                  width: 'calc(100% - 280px)',
                  padding: 32,
                  overflow: 'auto',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <AddGroupBtn onAdd={this.handleGroupCreate} />
                {groups.map((g, i) => (
                  <GroupItem
                    key={g.name}
                    allAgents={agents}
                    allSegments={allSegments}
                    color={colorsPool[i % 20]}
                    data={g}
                    onChange={item => this.handleGroupUpdate(g.name, item)}
                    onDelete={() => this.handleGroupDelete(g)}
                    onDeleteAgent={this.handleAgentDelete}
                    onDrop={this.handleAgentDrop}
                    onSegmentsChange={ids => this.handleSegmentsChange(ids, g.name)}
                  />
                ))}
              </div>
              <div
                style={{
                  padding: '32px',
                  width: 480,
                  height: '100%',
                  overflow: 'auto',
                  backgroundColor: '#DBDBDB',
                }}
              >
                <AgentPool agents={agents} handleAgentDrop={this.handleAgentDrop} />
              </div>
              {loading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  }}
                >
                  <Loading />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default props => {
  const bot = React.useContext(BotContext)

  const { data, loading, error } = useQuery(ALL_AGENT_GROUPS, { variables: { botId: bot.name } })
  const [createAgentGroup] = useMutation(CREATE_AGENT_GROUP, {
    refetchQueries: [{ query: ALL_AGENT_GROUPS, variables: { botId: bot.name } }],
  })
  const [updateAgentGroup] = useMutation(UPDATE_AGENT_GROUP, {
    refetchQueries: [{ query: ALL_AGENT_GROUPS, variables: { botId: bot.name } }],
  })
  const [deleteAgentGroup] = useMutation(DELETE_AGENT_GROUP, {
    refetchQueries: [{ query: ALL_AGENT_GROUPS, variables: { botId: bot.name } }],
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }

  // const allAgents = [
  //   { name: 'mu@mu.com', title: 'มอม้า สระอุ' },
  //   { name: 'atchariya@gmail.com', title: 'The Boyd' },
  // ]
  // const allAgentGroups = [
  //   {
  //     name: 'g-1',
  //     title: 'กลุ่มเอเจนท์ A',
  //     description: 'คำอธิบายคร่าวๆ เกี่ยวกับ Group A ที่จะบอกวัตถุประสงค์และ ประเภทชัดเจน',
  //     agents: [{ name: 'mu', title: 'มอม้า สระอุ' }, { name: 'boy', title: 'The Boyd' }],
  //   },
  //   {
  //     name: 'g-2',
  //     title: 'กลุ่มเอเจนท์ B',
  //     description: 'คำอธิบายคร่าวๆ เกี่ยวกับ Group B ที่จะบอกวัตถุประสงค์และ ประเภทชัดเจน',
  //     agents: [],
  //   },
  // ]
  const allFriendSegments = (data && data.allFriendSegments) || []
  const allAgents = ((data && data.allAgents) || []).filter(a => a.role === 'Agent')
  const allAgentGroups = (data && data.allAgentGroups) || []
  const mapGroups = allAgentGroups.map(g => ({
    ...g,
    agents: (g.users || []).map(u => ({ name: u.email, title: u.full_name })),
    users: undefined,
  }))

  return (
    <AgentGroup
      {...props}
      bot={bot}
      createAgentGroup={createAgentGroup}
      updateAgentGroup={updateAgentGroup}
      deleteAgentGroup={deleteAgentGroup}
      allSegments={allFriendSegments}
      agents={allAgents}
      groups={mapGroups}
    />
  )
}
