import React from 'react'
import { message, Modal, Icon } from 'antd'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import BroadcastConfigForm from './BroadcastConfigForm'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import Tutorial from '../../../../components/Tutorial'
import BotContext from '../../../../context/Bot'
import { LineMessageComposer, FacebookMessageComposer } from '../../../../libs/MessageComposer'
import MessagesPreview from '../../../../libs/MessagesPreview'

// for refetch only
const ALL_BROADCAST = gql`
  query allBroadcast($botId: String!) {
    allBroadcast(bot_id: $botId) {
      id
      title
      schedule
      status
    }
  }
`

const GET_BROADCAST = gql`
  query getBroadcast($botId: String!, $bcId: String!) {
    getBroadcast(bot_id: $botId, broadcast_id: $bcId) {
      id
      title
      broadcast_type
      target
      status
      schedule
      channel_id
      is_now
      targetReach
      limit
      messages
    }
    allChannels(bot_id: $botId) {
      name
      title
      id
      platform
    }
    allFriendSegments(bot_id: $botId) {
      name
      title
    }
  }
`

const GET_TARGET_NUMBER = gql`
  query getBroadcastTargetNumber($botId: String!, $broadcastId: String!) {
    getBroadcastTargetNumber(bot_id: $botId, broadcast_id: $broadcastId)
  }
`

const SEND_BROADCAST = gql`
  mutation sendBroadcast($bcId: String!, $botId: String!) {
    sendBroadcast(broadcast_id: $bcId, bot_id: $botId)
  }
`

const UPDATE_BROADCAST = gql`
  mutation updateBroadcast($botId: String!, $bcId: String!, $input: BroadcastInput!) {
    updateBroadcast(bot_id: $botId, broadcast_id: $bcId, input: $input) {
      id
    }
  }
`

const DELETE_BROADCAST = gql`
  mutation deleteBroadcast($botId: String!, $bcId: String!) {
    deleteBroadcast(bot_id: $botId, broadcast_id: $bcId)
  }
`

const SUBSCRIBE_BROADCAST = gql`
  subscription onBroadcastChanged($botId: String!, $bcId: String) {
    onBroadcastChanged(bot_id: $botId, doc_id: $bcId)
  }
`

class BroadcastEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formLoading: false,
    }
  }

  getStatusWording = status => {
    switch (status) {
      case 'DRAFT':
        return 'แบบร่าง'
      case 'READY':
        return 'อยู่ในคิว'
      case 'SENDING':
        return 'กำลังส่ง'
      case 'SENT':
        return 'สิ้นสุดแล้ว'
      case 'CANCELLED':
        return 'ยกเลิกการส่ง'
      default:
        return ''
    }
  }

  getTargetNumber = async () => {
    const { broadcast, getTargetNumber, bot } = this.props
    if (broadcast.id) {
      try {
        await getTargetNumber({ variables: { botId: bot.name, broadcastId: broadcast.id } })
      } catch (err) {
        console.error(err.message)
      }
    }
  }

  handleConfigChange = async input => {
    const { updateBroadcast, broadcast, bot } = this.props
    this.setState({ formLoading: true })
    try {
      await updateBroadcast({
        variables: { botId: bot.name, bcId: broadcast.id, input },
      })
      message.success('บันทึกแล้ว')
    } catch (err) {
      console.error(err)
    }
    this.setState({ formLoading: false })
  }

  handleMessageChange = async messages => {
    this.handleConfigChange({ messages })
  }

  handleCancelClick = () => {
    const { updateBroadcast, broadcast } = this.props
    Modal.confirm({
      title: 'ยกเลิกบรอดแคสต์นี้ ?',
      content: 'เปลี่ยนสถาะของบรอดแคสต์นี้เป็น "แบบร่าง"',
      okText: 'ตกลง',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        try {
          await updateBroadcast({ variables: { bcId: broadcast.id, input: { status: 'DRAFT' } } })
        } catch (err) {
          console.error(err.message)
        }
      },
    })
  }

  handleDeleteClick = () => {
    const { history, bot, broadcast, deleteBroadcast } = this.props
    Modal.confirm({
      title: 'ลบบรอดแคสต์นี้ ?',
      okType: 'danger',
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        try {
          await deleteBroadcast({ variables: { botId: bot.name, bcId: broadcast.id } })
          history.push(`/app/bot/${bot.name}/main/broadcast/list`)
        } catch (err) {
          console.error(err.message)
        }
      },
    })
  }

  handleSendClick = async () => {
    const { sendBroadcast, broadcast, bot, history } = this.props
    if (broadcast.broadcast_type === 'TAG' && (broadcast.target || []).length === 0) {
      message.error('กรุณาใส่แท็กของผู้รับข้อความ')
      return
    }
    if (broadcast.broadcast_type === 'SEGMENT' && !broadcast.target) {
      message.error('กรุณาเลือกกลุ่มเป้าหมาย')
      return
    }
    if (broadcast.broadcast_type === 'FILE' && !broadcast.target) {
      message.error('กรุณาอัพโหลดไฟล์เทมเพลตก่อนส่ง')
      return
    }
    if (!broadcast.channel_id || !broadcast.title || (!broadcast.is_now && !broadcast.schedule)) {
      message.error('กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน')
      return
    }
    if (!broadcast.messages.length) {
      message.error('ต้องมีข้อความบรอดแคสต์อย่างน้อย หนึ่งข้อความ')
      return
    }
    try {
      await sendBroadcast({ variables: { bcId: broadcast.id, botId: bot.name } })
      message.success('บันทึกสำเร็จ')
      history.push(`/app/bot/${bot.name}/main/broadcast/list`)
    } catch (err) {
      console.error(err.message)
    }
  }

  componentDidMount() {
    this.getTargetNumber()
  }

  render() {
    const {
      bot,
      allTags,
      allSegments,
      allChannels,
      targetedNumber,
      broadcast,
      loading,
    } = this.props
    const { formLoading } = this.state
    const isDraft = broadcast.status === 'DRAFT'
    const selectedChannel = allChannels.find(ch => ch.id === broadcast.channel_id) || {}

    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            padding: '24px 36px 16px',
            borderBottom: '1px solid rgba(183, 183, 183, 1)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h1>
              {broadcast.title} ({this.getStatusWording(broadcast.status)})
            </h1>
            {broadcast.status === 'READY' && (
              <Button type="danger" ghost loading={loading} onClick={this.handleCancelClick}>
                ยกเลิกการส่ง
              </Button>
            )}
          </div>
          {isDraft && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Button
                  loading={loading}
                  onClick={() => this.handleSendClick()}
                  icon={<Icon type="notification" theme="filled" />}
                  type="primary"
                >
                  ส่งบรอดแคสต์
                </Button>
              </div>
              <Button loading={loading} type="danger" onClick={this.handleDeleteClick}>
                ลบบรอดแคสต์
              </Button>
            </div>
          )}
        </div>
        <div
          style={{ display: 'flex', height: `calc(100%  - 42px - ${isDraft ? '129px' : '97px'})` }}
        >
          <div style={{ width: 479, height: '100%', overflow: 'auto' }}>
            <BroadcastConfigForm
              allTags={allTags}
              allSegments={allSegments}
              allChannels={allChannels}
              broadcast={broadcast}
              onConfigChange={this.handleConfigChange}
              readOnly={!isDraft}
              botId={bot.name}
              targetedNumber={targetedNumber}
              loading={formLoading}
            />
            {isDraft ? (
              selectedChannel.name ? (
                <div>
                  {selectedChannel.platform === 'LineBot' && (
                    <LineMessageComposer
                      defaultValue={broadcast.messages}
                      onChange={msgs => this.handleMessageChange(msgs)}
                      botId={bot.name}
                      label="ข้อความบรอดแคสต์"
                    />
                  )}
                  {selectedChannel.platform === 'FacebookBot' && (
                    <FacebookMessageComposer
                      defaultValue={broadcast.messages}
                      onChange={msgs => this.handleMessageChange(msgs)}
                      botId={bot.name}
                      label="ข้อความบรอดแคสต์"
                    />
                  )}
                </div>
              ) : (
                <div style={{ margin: '16px', textAlign: 'center' }}>
                  <i>เลือกช่องทางการส่งก่อน จึงจะสามารถสร้างข้อความได้</i>
                </div>
              )
            ) : null}
          </div>
          <div style={{ width: 'calc(100% - 480px)' }}>
            <MessagesPreview
              loading={loading}
              platform={selectedChannel.platform}
              messages={broadcast.messages}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '42px',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            padding: '0 48px',
            backgroundColor: '#206EB0',
          }}
        >
          <Tutorial
            slideLink="https://drive.google.com/file/d/1jg4192JohXrWMbGp3oABdkensWSQM9NO/view"
            videoLink="https://youtu.be/sgWXlca_YgU"
          />
        </div>
      </div>
    )
  }
}

export default props => {
  const { history, match } = props
  const bcId = match.params.bcId
  const bot = React.useContext(BotContext)

  const { data, error, loading, subscribeToMore } = useQuery(GET_BROADCAST, {
    variables: { bcId, botId: bot.name },
  })

  const [
    getTargetNumber,
    { data: getTNData, called: isGetTNCalled },
  ] = useLazyQuery(GET_TARGET_NUMBER, { fetchPolicy: 'cache-and-network' })

  const [updateBroadcast, { loading: updateBCLoading }] = useMutation(UPDATE_BROADCAST)
  const [sendBroadcast, { loading: sendLoading }] = useMutation(SEND_BROADCAST)
  const [deleteBroadcast, { loading: deleteLoading }] = useMutation(DELETE_BROADCAST, {
    refetchQueries: [{ query: ALL_BROADCAST, variables: { botId: bot.name } }],
  })

  React.useEffect(() => {
    let unsub = () => {}

    const subscribeBroadcast = () =>
      subscribeToMore({
        document: SUBSCRIBE_BROADCAST,
        variables: { botId: bot.name, bcId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          const newSubData = subscriptionData.data.onBroadcastChanged || {}
          if (newSubData.op === 'DELETE') {
            message.warning('บรอดแคสต์ถูกลบ')
            history.push(`/app/bot/${bot.name}/main/broadcast/list`)
          } else if (newSubData.op === 'UPDATE') {
            if (newSubData.broadcast_type || newSubData.target) {
              getTargetNumber({ variables: { broadcastId: bcId, botId: bot.name } })
            }
            return {
              ...prev,
              getBroadcast: {
                ...prev.getBroadcast,
                ...newSubData,
                doctype: undefined,
                op: undefined,
              },
            }
          }

          return prev
        },
      })

    unsub = subscribeBroadcast()

    return () => {
      unsub()
    }
  }, [bot, bcId, subscribeToMore, getTargetNumber, history])

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error.message)
  }

  const allChannels = (data && data.allChannels) || []
  const allSegments = (data && data.allFriendSegments) || []

  const broadcast = (data && data.getBroadcast) || {}

  const targetedNumber = (isGetTNCalled && getTNData && getTNData.getBroadcastTargetNumber) || 0

  return (
    <BroadcastEditor
      {...props}
      bot={bot}
      loading={sendLoading || updateBCLoading || deleteLoading}
      targetedNumber={targetedNumber}
      allTags={bot.friend_tags || []}
      allSegments={allSegments}
      allChannels={allChannels}
      broadcast={broadcast}
      getTargetNumber={getTargetNumber}
      sendBroadcast={sendBroadcast}
      updateBroadcast={updateBroadcast}
      deleteBroadcast={deleteBroadcast}
    />
  )
}
