import React from 'react'
import { message } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'

import CampaignConfigForm from './CampaignConfigForm'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import BotContext from '../../../context/Bot'
import { LineMessageComposer } from '../../../libs/MessageComposer'
import MessagesPreview from '../../../libs/MessagesPreview'

const GET_CAMPAIGN = gql`
  query getBroadcast($botId: String!, $bcId: String!) {
    getBroadcast(broadcast_id: $bcId) {
      name
      title
      tags
      status
      schedule
      channel
      is_now
      keyword {
        name
        bot_action {
          name
          line_messages
        }
      }
    }
    allChannels(bot_id: $botId) {
      name
      title
      platform
    }
  }
`

const GET_RENDERED_MESSAGES = gql`
  mutation getRenderedMessage($keywordId: String!, $botId: String!, $platform: ChannelPlatform) {
    getRenderedMessage(keyword_id: $keywordId, bot_id: $botId, platform: $platform)
  }
`

const UPDATE_CAMPAIGN = gql`
  mutation updateBroadcast($bcId: String!, $input: BroadcastInput!) {
    updateBroadcast(broadcast_id: $bcId, input: $input) {
      name
    }
  }
`

const UPDATE_ACTION = gql`
  mutation updateAction($actionId: String!, $input: BotActionInput!) {
    updateAction(action_id: $actionId, input: $input) {
      name
    }
  }
`

class CreateCampaign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      config: {
        tags: props.campaign.tags || [],
        title: props.campaign.title || '',
        schedule: props.campaign.schedule ? moment(props.campaign.schedule) : undefined,
        is_now: !!props.campaign.is_now,
        channel: props.campaign.channel,
      },
    }
  }

  getStatusWording = status => {
    switch (status) {
      case 'Draft':
        return 'แบบร่าง'
      case 'Ready':
        return 'อยู่ในคิว'
      case 'Sending':
        return 'กำลังส่ง'
      case 'Sent':
        return 'สิ้นสุดแล้ว'
      case 'Canceled':
        return 'ล้มเหลว'
      default:
        return ''
    }
  }

  configChange = item => {
    const { config } = this.state
    this.setState({ config: { ...config, ...item } })
  }

  getRenderedMessage = async () => {
    const { bot, campaign, getRenderedMsg } = this.props
    try {
      await getRenderedMsg({
        variables: { keywordId: campaign.keyword.name, botId: bot.name, platform: 'LineBot' },
      })
    } catch (err) {
      console.error(err.message)
    }
  }

  handleMessageChange = async messages => {
    const { updateAction, campaign } = this.props
    const input = {
      line_messages: messages,
    }
    try {
      await updateAction({ variables: { actionId: campaign.keyword.bot_action.name, input } })
      this.getRenderedMessage()
    } catch (err) {
      console.error(err.message)
    }
  }

  handleSaveDraftClick = async () => {
    const { updateCampaign, campaign, bot, history } = this.props
    const { config } = this.state
    const input = {
      ...config,
      status: 'Draft',
    }
    try {
      await updateCampaign({ variables: { bcId: campaign.name, input } })
      message.success('บันทึกสำเร็จ')
      history.push(`/app/bot/${bot.name}/campaign/list`)
    } catch (err) {
      console.error(err.message)
    }
  }

  handleSaveClick = async () => {
    const { updateCampaign, campaign, messages, bot, history } = this.props
    const { config } = this.state
    if (
      !config.channel ||
      !config.title ||
      !(config.tags || []).length ||
      (!config.is_now && !config.schedule)
    ) {
      message.error('กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน')
      return
    }
    if (!messages.length) {
      message.error('ต้องมีข้อความแคมเปญอย่างน้อย หนึ่งข้อความ')
      return
    }
    const input = {
      ...config,
      status: 'Ready',
    }
    try {
      await updateCampaign({ variables: { bcId: campaign.name, input } })
      message.success('บันทึกสำเร็จ')
      history.push(`/app/bot/${bot.name}/campaign/list`)
    } catch (err) {
      console.error(err.message)
    }
  }

  componentDidMount() {
    this.getRenderedMessage()
  }

  render() {
    const { bot, allTags, campaign, messages, loading, renderedResponse, allChannels } = this.props
    const { config } = this.state
    const isDraft = campaign.status === 'Draft'
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(183, 183, 183, 1)' }}
        >
          <h1>
            {campaign.title} ({this.getStatusWording(campaign.status)})
          </h1>
          {isDraft && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 'calc(480px - 72px)',
              }}
            >
              <Button loading={loading} onClick={this.handleSaveDraftClick} type="primary" ghost>
                บันทึกแบบร่าง
              </Button>
              <Button loading={loading} onClick={this.handleSaveClick} type="primary">
                ส่งแคมเปญ
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', height: `calc(100% - ${isDraft ? '129px' : '97px'})` }}>
          <div style={{ width: 479, height: '100%', overflow: 'auto' }}>
            <div style={{ padding: '12px 36px', borderBottom: '1px solid rgba(183, 183, 183, 1)' }}>
              <CampaignConfigForm
                allTags={allTags}
                allChannels={allChannels}
                config={config}
                configChange={this.configChange}
                readOnly={!isDraft}
              />
            </div>
            {isDraft && (
              <div>
                <LineMessageComposer
                  defaultValue={messages}
                  onChange={this.handleMessageChange}
                  botId={bot.name}
                />
              </div>
            )}
          </div>
          <div style={{ width: 'calc(100% - 480px)' }}>
            <MessagesPreview
              loading={renderedResponse.loading}
              platform="LineBot"
              messages={((renderedResponse.data || {}).getRenderedMessage || {}).messages || []}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default props => {
  const bcId = props.match.params.bcId
  const bot = React.useContext(BotContext)

  const { data, error, loading } = useQuery(GET_CAMPAIGN, { variables: { bcId, botId: bot.name } })

  const [updateAction] = useMutation(UPDATE_ACTION, {
    refetchQueries: [{ query: GET_CAMPAIGN, variables: { bcId, botId: bot.name } }],
  })
  const [updateCampaign, { loading: updateCPLoading }] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: [{ query: GET_CAMPAIGN, variables: { bcId, botId: bot.name } }],
  })
  const [getRenderedMsg, renderedResponse] = useMutation(GET_RENDERED_MESSAGES)

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error.message)
  }

  const allChannels = (data && data.allChannels) || []

  const campaign = (data && data.getBroadcast) || {}
  const messages = ((campaign.keyword || {}).bot_action || {}).line_messages || []

  return (
    <CreateCampaign
      {...props}
      bot={bot}
      loading={updateCPLoading}
      allTags={bot.friend_tags || []}
      allChannels={allChannels.filter(ch => ch.platform === 'LineBot')}
      campaign={campaign}
      updateAction={updateAction}
      updateCampaign={updateCampaign}
      messages={messages}
      getRenderedMsg={getRenderedMsg}
      renderedResponse={renderedResponse}
    />
  )
}
