import React, { useContext } from 'react'
import { Icon, message } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../components/Button'
import ProgressBar from '../../../components/ProgressBar'
import NumberRenderer from '../../../components/NumberRenderer'
import BotContext from '../../../context/Bot'
import CampaignTable from './CampaignTable'
import Loading from '../../../components/Loading'
import TabMenu from './TabMenu'

const ALL_CAMPAIGN = gql`
  query allBroadcast($botId: String!) {
    allBroadcast(bot_id: $botId) {
      name
      title
      tags
      schedule
      status
    }
  }
`

const CREATE_CAMPAIGN = gql`
  mutation createBroadcast($botId: String!, $config: BroadcastInput!, $keyword: KeywordInput) {
    createBroadcast(bot_id: $botId, input_keyword: $keyword, config: $config) {
      name
    }
  }
`

const DELETE_CAMPAIGN = gql`
  mutation deleteBroadcast($bcId: String!) {
    deleteBroadcast(broadcast_id: $bcId) {
      name
    }
  }
`

const CampaignList = ({ history }) => {
  const bot = useContext(BotContext)
  const { data, loading, error } = useQuery(ALL_CAMPAIGN, {
    variables: { botId: bot.name },
    fetchPolicy: 'cache-and-network',
  })
  const [createBroadcast, { loading: createLoading }] = useMutation(CREATE_CAMPAIGN, {
    refetchQueries: [{ query: ALL_CAMPAIGN, variables: { botId: bot.name } }],
  })
  const [deleteBroadcast, { loading: deleteLoading }] = useMutation(DELETE_CAMPAIGN, {
    refetchQueries: [{ query: ALL_CAMPAIGN, variables: { botId: bot.name } }],
  })

  const [selected, changeSelected] = React.useState('Ready')

  const tabItems = [
    {
      key: 'Ready',
      menu: 'เปิดใช้งานอยู่',
    },
    {
      key: 'Draft',
      menu: 'แบบร่างแคมเปญ',
    },
    {
      key: 'Sent',
      menu: 'แคมเปญสิ้นสุดแล้ว',
    },
    {
      key: 'Canceled',
      menu: 'แคมเปญล้มเหลว',
    },
  ]

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error.message)
  }

  const allCampaign = (data && data.allBroadcast) || []

  let list = {
    Ready: [],
    Draft: [],
    Sent: [],
    Sending: [],
    Canceled: [],
  }

  allCampaign.forEach(cp => {
    list[cp.status].push(cp)
  })

  const handleCreateClick = async () => {
    try {
      const { data } = await createBroadcast({
        variables: { botId: bot.name, config: { title: 'New Campaign' } },
      })
      const newBroadcast = (data && data.createBroadcast) || {}
      history.push(`/app/bot/${bot.name}/campaign/${newBroadcast.name}`)
    } catch (err) {
      console.error(err.message)
    }
  }

  const handleDeleteClick = async bcId => {
    try {
      await deleteBroadcast({ variables: { bcId } })
      message.success('ลบแคมเปญสำเร็จ')
    } catch (err) {
      console.error(err.message)
    }
  }

  const gotoBroadcast = id => {
    history.push(`/app/bot/${bot.name}/campaign/${id}`)
  }

  return (
    <div style={{ padding: 36, height: '100%' }}>
      <h1>แคมเปญ</h1>
      <div style={{ marginBottom: 8 }}>
        <span>จำนวนข้อความที่ส่ง</span>
        <span style={{ marginLeft: 8, color: 'rgba(28, 112, 176, 1)', fontWeight: 700 }}>
          <NumberRenderer value={0} />
        </span>
        <span style={{ color: 'rgba(112, 112, 112, 1)', fontWeight: 700 }}>
          <span style={{ margin: '0 4px' }}>/</span>
          <NumberRenderer value={0} />
        </span>
        <span style={{ marginLeft: 8 }}>ข้อความ</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: 24, margin: 16 }}>
        <Icon type="notification" style={{ marginRight: 8 }} />
        <div style={{ width: 'calc(100% - 38px)', height: '100%' }}>
          <ProgressBar value={0} max={0} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TabMenu list={tabItems} onChange={key => changeSelected(key)} />
        <Button
          type="primary"
          ghost
          loading={createLoading}
          onClick={handleCreateClick}
          icon={<Icon type="plus" />}
        >
          สร้างใหม่
        </Button>
      </div>
      <CampaignTable
        dataSource={list[selected]}
        onDelete={handleDeleteClick}
        loading={deleteLoading || createLoading}
        height={window.innerHeight - 108 - 208 - 54 - 32}
        openCampaign={gotoBroadcast}
      />
    </div>
  )
}

export default CampaignList
