import React from 'react'
import { Input } from 'antd'

import InputField from '../../../../../components/InputField'

const LineNotifyForm = ({ chId, input, inputChange, loading }) => {
  return (
    <div>
      <InputField title="ชื่อ Channel">
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Access Token">
        <Input.TextArea
          value={input.access_token}
          onChange={e => inputChange({ access_token: e.target.value })}
          autoSize={{ minRows: 4 }}
          disabled={loading}
        />
      </InputField>
    </div>
  )
}

export default LineNotifyForm
