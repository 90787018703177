import React from 'react'

class HeightController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vh: window.innerHeight,
    }
  }

  setHeight = e => {
    this.setState({ vh: window.innerHeight })
  }

  componentDidMount() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('orientationchange', this.setHeight)
    window.addEventListener('resize', this.setHeight)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vh !== this.state.vh) {
      const vh = this.state.vh * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.setHeight)
    window.removeEventListener('resize', this.setHeight)
  }

  render() {
    return this.props.children
  }
}

export default HeightController
