import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import AIYA_BOT_BLANK from '../../assets/images/AIYA_Bot_Blank.jpg'
import Loading from '../../components/Loading'
import { queryStrToObj } from '../../helpers/queryStr'
import { jsonParse } from '../../helpers/utils'
import ImageRenderer from '../../components/ImageRenderer'
import { Button, Input, Row, Col } from 'antd'
import InputField from '../../components/InputField'

const GET_USER = gql`
  query verifyUser($uid: String!) {
    verifyUser(uid: $uid) {
      name
      frappe_token
    }
  }
`

const CREATE_USER = gql`
  mutation createUser($input: UserInput!, $uid: String!) {
    createUser(input: $input, uid: $uid) {
      name
      frappe_token
    }
  }
`

const ACTIVATE_TOKEN = gql`
  mutation activateToken($email: String!, $token: String!) {
    activateToken(email: $email, token: $token)
  }
`

const liff = window.liff

const LIFF_ID = '1553392709-plEQ4LKz'

const ActivateInvitation = ({ history, location }) => {
  const params = queryStrToObj(location.search)
  const token = params.token
  const bot = jsonParse(params.bot ? decodeURIComponent(atob(decodeURIComponent(params.bot))) : '')

  const [profile, setProfile] = React.useState({})
  const [isReady, setReady] = React.useState(false)
  const [input, setInput] = React.useState({})
  const [btnLoading, setBtnLoading] = React.useState(false)

  const [createUser] = useMutation(CREATE_USER)
  const [activateToken] = useMutation(ACTIVATE_TOKEN)
  const { data, loading, error } = useQuery(GET_USER, { variables: { uid: profile.userId } })
  if (error) {
    console.error(error)
  }

  const verifyUser = (data && data.verifyUser) || {}

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const initLiff = () => {
    console.log('initial liff app ID:', LIFF_ID)
    liff.init(
      { liffId: LIFF_ID },
      async () => {
        if (liff.isLoggedIn()) {
          try {
            const profile = await liff.getProfile()
            setProfile(profile)
            setReady(true)
          } catch (err) {
            console.log(err)
          }
        } else {
          // liff.login dosen't work if use LINE in-app browser
          if (!liff.isInClient()) {
            liff.login({ redirectUri: window.location.href })
          }
        }
      },
      err => {
        console.error(err)
      },
    )
  }

  const activateInvitation = async user => {
    try {
      setBtnLoading(true)
      await activateToken({ variables: { email: user.name, token } })
      localStorage.setItem('access_token', user.frappe_token)
      localStorage.setItem('user_id', user.name)
      history.push('/app/bot/list')
    } catch (err) {
      console.error(err)
    }
    setBtnLoading(false)
  }

  const handleRegisterClick = async () => {
    try {
      setBtnLoading(true)
      const { data: newUserData } = await createUser({ variables: { input, uid: profile.userId } })
      activateInvitation(newUserData.createUser)
    } catch (err) {
      console.error(err)
    }
    setBtnLoading(false)
  }

  const handleAcceptInviteClick = () => {
    activateInvitation(verifyUser)
  }

  React.useEffect(() => {
    initLiff()
  }, [])

  if (!isReady || loading) {
    return <Loading />
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          maxWidth: 480,
          width: '100%',
          padding: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '32px auto 16px',
        }}
      >
        <img
          style={{ width: 192, height: 192, borderRadius: '50%', margin: '16px 0' }}
          src={profile.pictureUrl}
          alt={profile.userId}
        />
        <b style={{ fontSize: '1.4em' }}>{profile.displayName}</b>
        <span>คุณได้รับคำเชิญเป็น Admin ของ VA</span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '32px 0',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            padding: '4px 8px',
            borderRadius: 8,
          }}
        >
          <ImageRenderer
            src={bot.picture_url}
            errorImg={AIYA_BOT_BLANK}
            alt="bot"
            style={{ width: 56, height: 56, borderRadius: '50%', marginRight: 16 }}
          />
          <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <span style={{ fontWeight: 600 }}>{bot.title || 'BOT NAME'}</span>
            <span style={{ color: '#b7b7b7', fontSize: '0.9em' }}>@{bot.name || 'BOT ID'}</span>
          </div>
        </div>
        {verifyUser.name ? (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <div style={{ marginBottom: 16 }}>กดยืนยันเพื่อรับสิทธิ์เข้าใช้งานระบบ</div>
            <Button loading={btnLoading} onClick={handleAcceptInviteClick} type="primary" block>
              ยืนยัน
            </Button>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'center', marginBottom: 16 }}>
              กรอกข้อมูลด้านล่างและกดลงทะเบียนเพื่อใช้งานระบบ
            </div>
            <InputField title="ชื่อ-สกุล">
              <Input.Group>
                <Row gutter={16}>
                  <Col span={12}>
                    <Input
                      placeholder="ชื่อ"
                      onChange={e => inputChange({ first_name: e.target.value })}
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      placeholder="สกุล"
                      onChange={e => inputChange({ last_name: e.target.value })}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </InputField>
            <InputField title="อีเมลล์">
              <Input
                type="email"
                placeholder="email@example.com"
                onChange={e => inputChange({ email: e.target.value })}
              />
            </InputField>
            <InputField title="เบอร์ติดต่อ">
              <Input
                type="tel"
                placeholder="0912345678"
                onChange={e => inputChange({ phone: e.target.value })}
              />
            </InputField>
            <Button
              loading={btnLoading}
              onClick={handleRegisterClick}
              type="primary"
              style={{ marginTop: 16 }}
              block
            >
              ลงทะเบียน
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivateInvitation
