import React from 'react'
import PT from 'prop-types'
import { Icon, Modal } from 'antd'

import FlexEditor from './FlexEditor'
import ImageEditor from './ImageEditor'
import ImagemapEditor from './ImagemapEditor'
import RichMessageEditor from './RichMessageEditor'
import TextEditor from './TextEditor'
import IconButton from '../../components/IconButton'
import IconMenu from '../../components/IconMenu'
import { defaultLineData } from '../../constants/defaultData'
import { defaultLineMsg } from '../../constants/defaultMsg'
import { lineMsgType } from '../../constants/msgType'

class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messageType: this.classifyMsg(props.message),
      // editingMessage: props.message,
    }
  }

  classifyMsg = msg => {
    if (!msg) {
      return
    }
    if (msg.type === 'text' && msg.text) {
      return lineMsgType.TEXT
    }
    if (msg.type === 'image') {
      return lineMsgType.IMAGE
    }
    if (msg.type === 'imagemap') {
      return lineMsgType.IMAGEMAP
    }
    if (msg.type === 'flex' && msg.altText && msg.contents) {
      return lineMsgType.FLEX
    }
    return lineMsgType.FLEX
  }

  handleMsgTypeChange = type => {
    const { onChange, loading } = this.props
    if (!loading) {
      Modal.confirm({
        title: 'ยืนยันการแก้ไข',
        content: (
          <div>
            <div>การแก้ไขข้อความจะทำให้ข้อความในกล่องนี้หายไป</div>
            <div>เพื่อความปลอดภัยกรุณาสำรองข้อความเก่าไว้ก่อน</div>
          </div>
        ),
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          this.setState({ messageType: type })
          onChange(defaultLineMsg[type], defaultLineData[type])
        },
        onCancel: () => {},
      })
    }
  }

  handleMessageChange = (newMsg, data) => {
    const { onChange } = this.props
    onChange(newMsg, data)
  }

  render() {
    const { index, message, data, delMessage, botId, loading } = this.props
    const { messageType } = this.state
    return (
      <div
        style={{
          width: '360px',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          borderRadius: 4,
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 32,
            borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
            padding: '0 4px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <IconButton style={{ marginRight: 8 }}>
              <Icon type="menu" />
            </IconButton>
            <IconMenu
              onClick={() => this.handleMsgTypeChange(lineMsgType.TEXT)}
              active={messageType === lineMsgType.TEXT}
              style={{ marginRight: 4 }}
            >
              <Icon type="message" />
            </IconMenu>
            <IconMenu
              onClick={() => this.handleMsgTypeChange(lineMsgType.IMAGE)}
              active={messageType === lineMsgType.IMAGE}
              style={{ marginRight: 4 }}
            >
              <Icon type="picture" />
            </IconMenu>
            <IconMenu
              onClick={() => this.handleMsgTypeChange(lineMsgType.IMAGEMAP)}
              active={messageType === lineMsgType.IMAGEMAP}
              style={{ marginRight: 4 }}
            >
              <Icon type="appstore" />
            </IconMenu>
            {/* <IconMenu
              onClick={() => this.handleMsgTypeChange(lineMsgType.RICH_MESSAGE)}
              active={messageType === lineMsgType.RICH_MESSAGE}
              style={{ marginRight: 4 }}
            >
              <Icon type="appstore" />
            </IconMenu> */}
            <IconMenu
              onClick={() => this.handleMsgTypeChange(lineMsgType.FLEX)}
              active={messageType === lineMsgType.FLEX}
            >
              <Icon type="control" />
            </IconMenu>
          </div>
          <IconButton hoverColor="red" onClick={delMessage}>
            <Icon type="close" />
          </IconButton>
        </div>
        {messageType === lineMsgType.TEXT && (
          <TextEditor
            message={message}
            onMessageChange={this.handleMessageChange}
            loading={loading}
          />
        )}
        {messageType === lineMsgType.IMAGE && (
          <ImageEditor
            message={message}
            onMessageChange={this.handleMessageChange}
            botId={botId}
            loading={loading}
          />
        )}
        {messageType === lineMsgType.IMAGEMAP && (
          <ImagemapEditor
            message={message}
            data={data}
            onMessageChange={this.handleMessageChange}
            botId={botId}
            loading={loading}
          />
        )}
        {/* {messageType === lineMsgType.RICH_MESSAGE && (
          <RichMessageEditor
            defaultRichMessage={(data || {}).richMessageId}
            onMessageChange={this.handleMessageChange}
            botId={botId}
            loading={loading}
          />
        )} */}
        {messageType === lineMsgType.FLEX && (
          <FlexEditor
            message={message}
            onMessageChange={this.handleMessageChange}
            loading={loading}
          />
        )}
        <div
          style={{
            fontSize: '0.85em',
            borderTop: '1px solid rgba(0, 0, 0, 0.15)',
            padding: '0 8px',
          }}
        >
          <span style={{ fontWeight: 600, color: 'rgba(28, 112, 176, 1)' }}>{index + 1}</span>
        </div>
      </div>
    )
  }
}

Editor.propTypes = {
  botId: PT.string.isRequired,
  index: PT.number,
  message: PT.object,
  data: PT.object,
  onChange: PT.func,
  delMessage: PT.func,
  loading: PT.bool,
}

Editor.defaultProps = {
  onChange: () => {},
  delMessage: () => {},
}

export default Editor
