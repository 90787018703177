import React from 'react'
import PT from 'prop-types'
import ImageRenderer from '../../../components/ImageRenderer'
import NO_IMG_BOT from '../../../assets/images/AIYA_Bot_Blank.jpg'

const BotCard = ({ data, onClick }) => {
  const { name, title, image } = data
  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        width: 322,
        marginRight: 8,
        marginBottom: 8,
        cursor: 'pointer',
      }}
      onClick={() => onClick(name)}
    >
      <div style={{ width: 320, height: 320 }}>
        <ImageRenderer
          width={320}
          height={320}
          src={image || NO_IMG_BOT}
          alt={title}
          errorImg={NO_IMG_BOT}
          style={{ objectFit: 'contain', cursor: 'pointer' }}
        />
      </div>
      <div style={{ padding: 16, borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <span>{title}</span>
      </div>
    </div>
  )
}

BotCard.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    image: PT.string,
  }).isRequired,
  onClick: PT.func,
}

BotCard.defaultProps = {
  onClick: () => {},
}

export default BotCard
