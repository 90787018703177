import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Loading from '../../components/Loading'
import { queryStrToObj } from '../../helpers/queryStr'

const CONFIRM_LINEPAY = gql`
  mutation confirmLinepay($userId: String!, $requestId: String!, $transactionId: String!) {
    confirmLinepay(user_id: $userId, request_id: $requestId, transaction_id: $transactionId)
  }
`

class LinepayConfirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false,
    }
  }
  confirmLinepay = async () => {
    const { history, confirmLinepay, params } = this.props
    const userId = localStorage.getItem('user_id')
    try {
      await confirmLinepay({
        variables: { transactionId: params.transactionId, requestId: params.orderId, userId },
      })
      history.replace('/app/billing')
    } catch (err) {
      console.error(err)
    }
  }

  componentDidMount() {
    this.confirmLinepay()
  }

  render() {
    const { isReady } = this.state
    if (!isReady) {
      return <Loading />
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <span>ทำรายการสำเร็จ ท่านสามารถปิดหน้าต่างนี้ได้</span>
        </div>
      )
    }
  }
}

export default props => {
  const params = queryStrToObj(props.location.search)
  const [confirmLinepay] = useMutation(CONFIRM_LINEPAY)
  return <LinepayConfirm {...props} params={params} confirmLinepay={confirmLinepay} />
}
