import React from 'react'
import { Icon, Tooltip } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import TransactionTable from './TransactionTable'
import Modal from '../../../components/Modal'
import NumberRenderer from '../../../components/NumberRenderer'
import CreditTopup from './CreditTopup'
import Button from '../../../components/Button'

const ALL_PAYMENT = gql`
  query Billing($userId: String!) {
    getCredit(user_id: $userId)
    allPaymentHistory(user_id: $userId) {
      user
      payment_type
      transaction_date
      total
    }
  }
`

const REQUEST_LINEPAY = gql`
  mutation requestLinepay($input: PaymentRequestInput, $confirmUrl: String, $cancelUrl: String) {
    requestLinepay(
      bot_id: "f0d5fcfb70"
      input: $input
      confirm_url: $confirmUrl
      cancel_url: $cancelUrl
    )
  }
`

const DisplayCard = styled.div`
  width: 480px;
  margin: 0 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px 24px;
`

// const TextButton = styled.span`
//   cursor: pointer;
//   user-select: none;
//   &:hover {
//     color: #1c70b0;
//     text-decoration: underline;
//     font-weight: bold;
//   }
// `

const Finance = () => {
  const userId = localStorage.getItem('user_id')
  const [modalState, setModal] = React.useState(false)

  const { data, error, loading } = useQuery(ALL_PAYMENT, { variables: { userId } })
  const [requestLinepay, { loading: loadingRequest }] = useMutation(REQUEST_LINEPAY)

  if (error) {
    console.error(error)
  }

  const currCredit = (data && data.getCredit) || 0
  const transactions = (data && data.allPaymentHistory) || []
  const mappedTransaction = transactions.map(t => ({
    title:
      t.payment_type === 'DR'
        ? `ค่าแพ็กเกจประจำเดือน ${moment(t.transaction_date).format('MMM YYYY')}`
        : `เติมเครดิต`,
    amount: t.payment_type === 'DR' ? t.total * -1 : t.total,
    timestamp: moment(t.transaction_date).valueOf(),
    detail: [
      {
        bot: 'Bot 1',
        package: '2,500 Subscriber',
        amount: 800,
      },
      {
        bot: 'Bot 2',
        package: '500 Subscriber',
        amount: 350,
      },
    ],
  }))

  const handleTopup = async ({ payMethod, credit, amount }) => {
    if (payMethod === 'linepay') {
      const confirmUrl = `${window.location.origin}/linepay-confirm`
      const cancelUrl = `${window.location.origin}/app/billing`
      const { data } = await requestLinepay({
        variables: {
          input: { title: 'Top up', credit, amount, user_id: userId },
          confirmUrl,
          cancelUrl,
        },
      })
      const linepayResult = (data && data.requestLinepay) || {}
      const redirectLink = (linepayResult.paymentUrl || {}).web
      window.location = redirectLink
    }
  }

  return (
    <div>
      <div
        style={{
          padding: '24px 36px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <h1>Billing Information</h1>
        <Button
          type="primary"
          icon={<Icon type="dollar" />}
          color="#00D196"
          hoverColor="#62f3ca"
          onClick={() => setModal(true)}
        >
          เติมเครดิต
        </Button>
      </div>
      <div
        style={{
          margin: 'auto',
          maxWidth: 992,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <DisplayCard>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <h2>เครดิตคงเหลือ</h2>
            {/* <TextButton onClick={() => setModal(true)}>
              <Icon type="dollar" style={{ marginRight: 4 }} />
              <span>เติมเครดิต</span>
            </TextButton> */}
          </div>
          <div style={{ textAlign: 'right' }}>
            <NumberRenderer
              value={currCredit}
              suffix="฿"
              style={{
                fontSize: '2em',
                fontWeight: 600,
                color: currCredit >= 0 ? '#00D196' : '#FF5C5C',
              }}
            />
          </div>
        </DisplayCard>
        <DisplayCard>
          <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h2>ยอดของรอบบิลนี้</h2>
              <Tooltip title="ระบบจะหักเครดิตอัตโนมัติภายในวันที่ 28 ของทุกเดือน">
                <Icon type="info-circle" style={{ marginLeft: 8 }} />
              </Tooltip>
            </div>
            {/* <TextButton>
              <Icon type="file-search" style={{ marginRight: 4 }} />
              <span>ดูรายละเอียด</span>
            </TextButton> */}
          </div>
          <div style={{ textAlign: 'right' }}>
            <NumberRenderer
              value={0}
              suffix="฿"
              style={{ fontSize: '2em', fontWeight: 600, color: '#FFB700' }}
            />
          </div>
        </DisplayCard>
      </div>
      <TransactionTable
        loading={loading}
        dataSource={mappedTransaction}
        height={window.innerHeight - 60 - 96 - 134 - 54}
      />
      <Modal visible={modalState} onClose={() => setModal(false)} disabledDimmerClick showCloseIcon>
        <CreditTopup onSubmit={handleTopup} loading={loadingRequest} />
      </Modal>
    </div>
  )
}

export default Finance
