import React from 'react'
import { Input, message, Divider } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../components/Button'
import LineLoginBtn from '../../../components/LineLoginBtn'
import InputField from '../../../components/InputField'

const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      user {
        name
        email
        full_name
      }
      token
    }
  }
`

const Login = ({ history }) => {
  const [login] = useMutation(LOGIN)

  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleLogin = async () => {
    setLoading(true)
    try {
      const { data } = await login({ variables: { email, password } })
      const user = ((data && data.login) || {}).user || ''
      const token = ((data && data.login) || {}).token || ''
      localStorage.setItem('user_id', user.email)
      localStorage.setItem('access_token', token)
      history.push('/')
    } catch (err) {
      message.error(err.message)
      setLoading(false)
    }
  }

  const handleForgotPwd = () => {
    history.push('/forgot-password')
  }

  const handleLineLogin = () => {
    window.location = `http://access.aiya.ai/oauth2/line/authorize?client_id=1553392709&redirect_uri=${window.location.origin}/linelogin&error_uri=${window.location.origin}/login`
  }

  const handleRegister = () => {
    // window.location = 'https://aiya.ai/R/7ed4a615ce'
    window.location = 'https://aiya.ai/R/eb7bcf7a6a'
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 468,
          borderRadius: 4,
        }}
      >
        <h1 style={{ textAlign: 'center' }}>เข้าสู่ระบบ</h1>
        <div style={{ padding: 16 }}>
          <InputField title="ชื่อผู้ใช้งาน หรือ อีเมล">
            <Input
              onChange={e => setEmail(e.target.value)}
              style={{ marginBottom: 8 }}
              onPressEnter={handleLogin}
            />
          </InputField>
          <InputField title="รหัสผ่าน">
            <Input
              onChange={e => setPassword(e.target.value)}
              type="password"
              style={{ marginBottom: 24 }}
              onPressEnter={handleLogin}
            />
          </InputField>
          <Button
            size="large"
            type="primary"
            onClick={handleLogin}
            loading={loading}
            style={{ marginBottom: 8 }}
            block
          >
            เข้าสู่ระบบ
          </Button>
          <Divider style={{ color: 'rgba(183, 183, 183, 1)' }}>หรือเข้าสู่ระบบด้วยวิธีอื่น</Divider>
          <LineLoginBtn onClick={handleLineLogin} />
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                padding: '0 16px',
                cursor: 'pointer',
                color: 'rgba(28, 112, 176, 1)',
                fontWeight: 600,
              }}
              onClick={handleRegister}
            >
              ลงทะเบียนใหม่
            </div>
            <Divider type="vertical" />
            <div
              onClick={handleForgotPwd}
              style={{ padding: '0 16px', cursor: 'pointer', color: 'rgba(183, 183, 183, 1)' }}
            >
              ลืมรหัสผ่าน
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

// export default props => {
//   const [activated, setActivated] = React.useState(false)
//   const [codeInput, codeChange] = React.useState('')

//   const ACTIVATE_CODE = 'LETDIPWITHACRM'

//   const checkCode = () => {
//     if (codeInput === ACTIVATE_CODE) {
//       setActivated(true)
//     }
//   }

//   if (activated) {
//     return <Login {...props} />
//   } else {
//     return (
//       <div
//         style={{
//           position: 'absolute',
//           top: '20%',
//           left: '50%',
//           transform: 'translateX(-50%)',
//           width: 468,
//           textAlign: 'center',
//         }}
//       >
//         <ImageRenderer src={ACRM_LOGO} alt="logo" height={60} style={{ marginBottom: 16 }} />
//         <h1 style={{ marginBottom: 32 }}>กรอกโค้ดเพื่อใช้งานระบบ</h1>
//         <div>
//           <Input
//             value={codeInput}
//             onChange={e => codeChange(e.target.value.toUpperCase())}
//             style={{ marginBottom: 16 }}
//             onPressEnter={checkCode}
//             autoFocus
//           />
//           <Button onClick={checkCode} type="primary">
//             เปิดใช้งานโค้ด
//           </Button>
//         </div>
//       </div>
//     )
//   }
// }
