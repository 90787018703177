import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../components/Button'

const Field = styled.div`
  display: flex;
  font-size: 1.3em;
  margin-bottom: 16px;
  .key {
    width: 120px;
    color: rgba(0, 0, 0, 0.35);
  }
`

const REGISTER = gql`
  mutation register($input: UserInput!, $uid: String!) {
    register(input: $input, uid: $uid) {
      name
    }
  }
`

const Confirmation = ({ onBack, onNext, data, lineData }) => {
  const [register, { loading }] = useMutation(REGISTER)
  const handleConfirm = async () => {
    try {
      const input = {
        // username: data.username,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        occupation: data.occupation,
        business_type: data.businessType,
      }
      await register({ variables: { input, uid: lineData.uid } })
      onNext()
    } catch (err) {
      console.error(err.message)
    }
  }
  return (
    <div>
      {/* <Field>
        <span className="key">ชื่อผู้ใช้งาน</span>
        <span>{data.username}</span>
      </Field> */}
      <Field style={{ marginBottom: 32 }}>
        <span className="key">LINE ID</span>
        <span>{lineData.displayName}</span>
      </Field>
      <Field>
        <span className="key">ชื่อ</span>
        <span>{data.firstName}</span>
      </Field>
      <Field>
        <span className="key">นามสกุล</span>
        <span>{data.lastName}</span>
      </Field>
      <Field>
        <span className="key">อีเมล</span>
        <span>{data.email}</span>
      </Field>
      <Field>
        <span className="key">เบอร์โทรศัพท์</span>
        <span>{data.phone}</span>
      </Field>
      <Field>
        <span className="key">อาชีพ</span>
        <span>{data.occupation}</span>
      </Field>
      <Field stlye={{ marginBottom: 32 }}>
        <span className="key">ประเภทธุรกิจ</span>
        <span>{data.businessType}</span>
      </Field>
      <Row gutter={16}>
        <Col span={12}>
          <Button size="large" onClick={onBack} type="secondary" block>
            แก้ไขข้อมูล
          </Button>
        </Col>
        <Col span={12}>
          <Button size="large" type="primary" onClick={handleConfirm} loading={loading} block>
            ลงทะเบียน
          </Button>
        </Col>
      </Row>
    </div>
  )
}

Confirmation.propTypes = {
  onBack: PT.func,
  onNext: PT.func,
  lineData: PT.shape({
    uid: PT.string,
    displayName: PT.string,
  }),
  data: PT.shape({
    firstName: PT.string,
    lastName: PT.string,
    email: PT.string,
    phone: PT.string,
    businessType: PT.string,
    occupation: PT.string,
  }),
}

Confirmation.defaultProps = {
  onBack: () => {},
  onNext: () => {},
}

export default Confirmation
