import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { Modal } from 'antd'

import ACRM_LOGO from '../assets/images/ACRM_LOGO_outline.png'
import ImageRenderer from '../components/ImageRenderer'
import AppSidenav from '../layouts/sidenav/AppSidenav'
import appRoutes from '../routes/appRoutes'
import AppbarMenu from '../components/AppbarMenu'

const AppContainer = props => {
  const { match, location, history } = props
  const initPath = match.path

  let showNavRoutes = []
  let hideNavRoutes = []

  appRoutes.forEach(route => {
    if (route.hideSidenav) {
      hideNavRoutes.push(route)
    } else {
      showNavRoutes.push(route)
    }
  })

  const appbarMenus = [
    // {
    //   key: 'va-setting',
    //   title: 'ตั้งค่า VA',
    //   icon: 'setting',
    // },
    // {
    //   key: 'billing',
    //   title: 'การชำระเงิน',
    //   icon: 'credit-card',
    // },
    {
      key: 'logout',
      title: 'ออกจากระบบ',
    },
  ]

  const handleMenuClick = (key, payload) => {
    if (key === 'logout') {
      openModalConfirm()
    }
  }

  const openModalConfirm = () => {
    Modal.confirm({
      title: 'ออกจากระบบตอนนี้?',
      okText: 'ใช่',
      cancelText: 'ไม่',
      okType: 'danger',
      onOk: () => {
        history.push('/logout')
      },
    })
  }

  return (
    <>
      <Switch>
        <Redirect from={initPath} to={initPath + '/bot'} exact />
        <Redirect from={initPath + '/bot'} to={initPath + '/bot/list'} exact />
      </Switch>
      {showNavRoutes.some(route => location.pathname === initPath + route.path) ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100vw',
              height: 60,
              padding: '6px 0px 6px 48px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
              boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
            }}
          >
            <Link to="/app">
              <ImageRenderer height={48} src={ACRM_LOGO} alt="logo" style={{ cursor: 'pointer' }} />
            </Link>
            <AppbarMenu menus={appbarMenus} onMenuClick={handleMenuClick} />
          </div>
          <div style={{ height: 'calc(100% - 60px)', width: '100%', display: 'flex' }}>
            <div style={{ width: 280, height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
              <AppSidenav routeProps={props} />
            </div>
            <div style={{ width: 'calc(100% - 280px)' }}>
              <Switch>
                {showNavRoutes.map(route => (
                  <Route key={route.path} path={initPath + route.path} component={route.page} />
                ))}
              </Switch>
            </div>
          </div>
          {/* <div style={{ height: 'calc(100% - 60px)', width: '100%' }}>
            <Switch>
              {showNavRoutes.map(route => (
                <Route key={route.path} path={initPath + route.path} component={route.page} />
              ))}
            </Switch>
          </div> */}
        </>
      ) : (
        <Switch>
          {hideNavRoutes.map(route => (
            <Route key={route.path} path={initPath + route.path} component={route.page} />
          ))}
        </Switch>
      )}
    </>
  )
}

export default AppContainer
