import React from 'react'
import PT from 'prop-types'
import { Row, Col } from 'antd'

import Button from '../../../components/Button'

const Success = ({ gotoLogin, gotoCreateBot, data }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ marginBottom: 32, fontSize: '1.7em', fontWeight: 600 }}>
        ลงทะเบียนเข้าสู่ระบบเรียบร้อย
      </div>
      <div
        style={{
          color: 'rgba(183, 183, 183, 1)',
          fontSize: '1.2em',
          marginBottom: 32,
          textAlign: 'center',
        }}
      >
        {`คุณ ${data.firstName} ${data.lastName} สามารถเข้าสู่ระบบจัดการแชทบอทได้ที่ https://manager.aiya.ai`}
      </div>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <Button size="large" onClick={gotoLogin} type="secondary" block>
            กลับหน้าเข้าสู่ระบบ
          </Button>
        </Col>
        <Col span={12}>
          <Button size="large" onClick={gotoCreateBot} type="primary" block>
            สร้างบอทใหม่
          </Button>
        </Col>
      </Row>
    </div>
  )
}

Success.propTypes = {
  gotoLogin: PT.func,
  gotoCreateBot: PT.func,
  data: PT.shape({
    firstName: PT.string,
    lastName: PT.string,
  }),
}

Success.defaultProps = {
  gotoLogin: () => {},
  gotoCreateBot: () => {},
}

export default Success
