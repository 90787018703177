import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import AddSegmentBtn from './AddSegmentBtn'
import SegmentItem from './SegmentItem'
import TagsPool from './TagsPool'
import Loading from '../../../components/Loading'
import { colorsPool } from '../../../constants/colors'
import BotContext from '../../../context/Bot'

const ALL_SEGMENTS = gql`
  query allFriendSegments($botId: String!) {
    allFriendSegments(bot_id: $botId) {
      name
      title
      includes
      excludes
      union
    }
  }
`

const CREATE_SEGMENT = gql`
  mutation createFriendSegment($botId: String!, $input: FriendSegmentInput!) {
    createFriendSegment(bot_id: $botId, input: $input) {
      name
    }
  }
`

const UPDATE_SEGMENT = gql`
  mutation updateFriendSegment($segmentId: String!, $input: FriendSegmentInput!) {
    updateFriendSegment(segment_id: $segmentId, input: $input) {
      name
    }
  }
`

const DELETE_SEGMENT = gql`
  mutation deleteFriendSegment($segmentId: String!) {
    deleteFriendSegment(segment_id: $segmentId) {
      name
    }
  }
`

class Segment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  createSegment = async input => {
    const { bot, createSegment } = this.props
    this.setState({ loading: true })
    try {
      await createSegment({ variables: { botId: bot.name, input } })
    } catch (err) {
      console.error(err)
    }
    this.setState({ loading: false })
  }

  updateSegment = async (segmentId, input) => {
    const { updateSegment } = this.props
    this.setState({ loading: true })
    try {
      await updateSegment({ variables: { segmentId, input } })
    } catch (err) {
      console.error(err)
    }
    this.setState({ loading: false })
  }

  deleteSegment = async segmentId => {
    const { deleteSegment } = this.props
    this.setState({ loading: true })
    try {
      await deleteSegment({ variables: { segmentId } })
    } catch (err) {
      console.error(err)
    }
    this.setState({ loading: false })
  }

  addTag = (segmentId, key, tag) => {
    const { segments } = this.props
    const segmentToAdd = segments.find(s => s.name === segmentId)

    if (!segmentToAdd) {
      console.error('Cannot find segment.')
      return
    }

    if (segmentToAdd[key].includes(tag)) {
      console.error(`[${key}] in segment already have ${tag}.`)
      return
    }

    const newSegment = {
      ...segmentToAdd,
      name: undefined,
      __typename: undefined,
      [key]: [...segmentToAdd[key], tag],
    }
    this.updateSegment(segmentId, newSegment)
  }

  changeKeyTag = (segmentId, oldKey, newKey, tag) => {
    const { segments } = this.props
    const segmentToChange = segments.find(s => s.name === segmentId)

    if (!segmentToChange) {
      console.error('Cannot find segment.')
      return
    }

    const newSegment = {
      ...segmentToChange,
      name: undefined,
      __typename: undefined,
      [newKey]: segmentToChange[newKey].includes(tag)
        ? segmentToChange[newKey]
        : [...segmentToChange[newKey], tag],
      [oldKey]: segmentToChange[oldKey].filter(t => t !== tag),
    }
    this.updateSegment(segmentId, newSegment)
  }

  removeTag = (segmentId, key, tag) => {
    const { segments } = this.props
    const segmentToRemove = segments.find(s => s.name === segmentId)

    if (!segmentToRemove) {
      console.error('Cannot find segment.')
      return
    }

    const newSegment = {
      ...segmentToRemove,
      name: undefined,
      __typename: undefined,
      [key]: segmentToRemove[key].filter(t => t !== tag),
    }
    this.updateSegment(segmentId, newSegment)
  }

  moveTag = async (tag, from = {}, to = {}) => {
    if (to.segment === from.segment) {
      if (to.key === from.key) {
        return
      }
      this.changeKeyTag(to.segment, from.key, to.key, tag)
    } else if (to.segment === '') {
      this.removeTag(from.segment, from.key, tag)
    } else if (from.segment === '') {
      this.addTag(to.segment, to.key, tag)
    } else {
      this.removeTag(from.segment, from.key, tag)
      this.addTag(to.segment, to.key, tag)
    }
  }

  handleSegmentCreate = title => {
    const input = {
      title: title,
      includes: [],
      excludes: [],
    }
    this.createSegment(input)
  }

  handleSegmentUpdate = (segmentId, item) => {
    this.updateSegment(segmentId, item)
  }

  handleSegmentDelete = segmentId => {
    this.deleteSegment(segmentId)
  }

  handleTagDrop = (item, dropZone) => {
    if (dropZone) {
      this.moveTag(item.tag, item.owner, dropZone)
    }
  }

  render() {
    const { bot, loading: loadingProps, segments } = this.props
    const { loading } = this.state
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            padding: '24px 36px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <h1>รายชื่อกลุ่มเป้าหมาย</h1>
        </div>
        <div style={{ display: 'flex', height: 'calc(100% - 97px)', position: 'relative' }}>
          {loadingProps ? (
            <Loading />
          ) : (
            <>
              <div
                style={{
                  width: 'calc(100% - 280px)',
                  padding: 32,
                  overflow: 'auto',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                {(segments || []).map((s, i) => (
                  <SegmentItem
                    key={s.title}
                    color={colorsPool[i % 20]}
                    bot={bot}
                    segment={s}
                    onChange={item => this.handleSegmentUpdate(s.name, item)}
                    onDrop={this.handleTagDrop}
                    onDelete={() => this.handleSegmentDelete(s.name)}
                    onTagDelete={(key, tag) => this.removeTag(s.name, key, tag)}
                    loading={loading}
                  />
                ))}
                <AddSegmentBtn onAdd={this.handleSegmentCreate} />
              </div>
              <div
                style={{
                  padding: '32px',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
                  width: 280,
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <TagsPool allTags={bot.friend_tags} handleTagDrop={this.handleTagDrop} />
              </div>
              {loading && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  }}
                >
                  <Loading />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default props => {
  const bot = React.useContext(BotContext)
  const { data, error, loading } = useQuery(ALL_SEGMENTS, { variables: { botId: bot.name } })
  const [createSegment] = useMutation(CREATE_SEGMENT, {
    refetchQueries: [{ query: ALL_SEGMENTS, variables: { botId: bot.name } }],
  })
  const [updateSegment] = useMutation(UPDATE_SEGMENT, {
    refetchQueries: [{ query: ALL_SEGMENTS, variables: { botId: bot.name } }],
  })
  const [deleteSegment] = useMutation(DELETE_SEGMENT, {
    refetchQueries: [{ query: ALL_SEGMENTS, variables: { botId: bot.name } }],
  })
  if (error) {
    console.error(error)
  }
  const allSegments = (data && data.allFriendSegments) || []
  return (
    <Segment
      {...props}
      bot={bot}
      loading={loading}
      segments={allSegments}
      createSegment={createSegment}
      updateSegment={updateSegment}
      deleteSegment={deleteSegment}
    />
  )
}
