import { withApollo } from 'react-apollo'

const Logout = ({ client, history }) => {
  localStorage.removeItem('user_id')
  localStorage.removeItem('access_token')
  client.resetStore()
  history.replace('/login')
  return null
}

export default withApollo(Logout)
