import React from 'react'
import { message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import EventForm from '../shared/EventForm'
import SideMenu from '../shared/SideMenu'
import ImageForm from '../shared/ImageForm'
import Button from '../../../components/Button'

const CREATE_EVENT = gql`
  mutation createActivity($botId: String!, $input: ActivityInput!) {
    createActivity(bot_id: $botId, input: $input) {
      name
    }
  }
`

const CreateEvent = ({ match, history }) => {
  const botId = match.params.botId
  const menu = [
    {
      key: 'info',
      title: 'ข้อมูลอีเว้นท์',
    },
  ]

  const [loading, setLoading] = React.useState(false)
  const [currMenu, setCurrMenu] = React.useState('info')
  const [input, setInput] = React.useState({
    title: '',
    from: undefined,
    to: undefined,
    venue: '',
    description: '',
    description_paid: '',
  })

  const [createEvent] = useMutation(CREATE_EVENT)

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const validateInput = () => {
    if (!(input.title && input.from && input.to && input.venue)) {
      message.error('กรุณากรอกข้อมูลที่มี่เครื่องหมาย * สีแดงให้ครบถ้วน')
      return false
    }
    return true
  }

  const handleSaveClick = async () => {
    if (validateInput()) {
      setLoading(true)
      try {
        const { data } = await createEvent({ variables: { botId, input } })
        const newActivity = (data && data.createActivity) || {}
        history.push(`/app/bot/${botId}/event/${newActivity.name}/editor`)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>สร้างอีเว้นท์ใหม่</h1>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 97px)' }}>
        <div style={{ width: 320, borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <SideMenu menu={menu} value={currMenu} onChange={setCurrMenu} />
        </div>
        <div style={{ width: 'calc(100% - 320px)', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 36px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            }}
          >
            <h2 style={{ marginBottom: 0, color: '#1C70B0', fontWeight: 400 }}>ข้อมูลอีเว้นท์</h2>
            <Button type="primary" onClick={handleSaveClick} loading={loading}>
              บันทึก
            </Button>
          </div>
          <div style={{ height: 'calc(100% - 56px)', overflowY: 'auto' }}>
            {currMenu === 'info' && (
              <div style={{ width: 640, padding: '24px 36px 16px' }}>
                <EventForm loading={loading} input={input} inputChange={inputChange} />
              </div>
            )}
            {currMenu === 'image' && (
              <ImageForm loading={loading} botId={botId} input={input} inputChange={inputChange} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEvent
