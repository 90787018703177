export default [
  'นักเรียน/นักศึกษา',
  'เจ้าของธุรกิจ/ผู้บริหาร',
  'นักการตลาด',
  'IT/Developer',
  'ธุรกิจออนไลน์',
  'พนักงานราชการ/ รัฐวิสาหกิจ',
  'เกษตรกร',
  'ผู้จัดการ',
  'พนักงานขาย',
  'Content Creator',
  'เจ้าหน้าที่ฝ่ายบุคคล',
  'พนักงานประกัน',
  'เลขานุการ',
  'นักการบัญชี',
  'นักการสินเชื่อ',
  'วิศวกร',
  'พนักงานอิสระ',
]
