import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'

const AppSidenav = ({ routeProps }) => {
  const initUrl = routeProps.match.url
  return (
    <div style={{ padding: 36, display: 'flex', flexDirection: 'column' }}>
      <NavLink to={initUrl + '/bot/list'} className="sidenav-item" activeClassName="active">
        รายชื่อบอท
      </NavLink>
      <NavLink to={initUrl + '/billing'} className="sidenav-item" activeClassName="active">
        Billing
      </NavLink>
      {/* <NavLink to={initUrl + '/account'} className="sidenav-item" activeClassName="active">
        จัดการบัญชี
      </NavLink> */}
    </div>
  )
}

AppSidenav.propTypes = {
  routeProps: PT.object.isRequired,
}

export default AppSidenav
