import React from 'react'
import PT from 'prop-types'
import { Row, Col } from 'antd'
import ReactPlayer from 'react-player'

import Button from '../../../components/Button'

const Success = ({ gotoBotList, gotoBot }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ marginBottom: 32, fontSize: '1.7em', fontWeight: 600 }}>
        ลงทะเบียนบอทเข้าสู่ระบบเรียบร้อย
      </div>
      <div
        style={{
          color: 'rgba(183, 183, 183, 1)',
          fontSize: '1.2em',
          marginBottom: 8,
          textAlign: 'center',
        }}
      >
        สามารถดูวิธีการเชื่อมต่อได้จากวีดีโอด้านล่าง
      </div>
      <ReactPlayer
        url="https://youtu.be/jaA6p6eeZwE"
        width={640}
        height={360}
        style={{ marginBottom: 32 }}
        controls
      />
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <Button size="large" onClick={gotoBotList} type="secondary" block>
            กลับหน้าเลือกบอท
          </Button>
        </Col>
        <Col span={12}>
          <Button size="large" type="primary" onClick={gotoBot} block>
            ไปหน้าเชื่อมต่อบอท
          </Button>
        </Col>
      </Row>
    </div>
  )
}

Success.propTypes = {
  gotoBotList: PT.func,
  gotoBot: PT.func,
}

Success.defaultProps = {
  gotoBotList: () => {},
  gotoBot: () => {},
}

export default Success
