import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'
import styled from 'styled-components'

import PromoCodeForm from './PromoCodeForm'
import Button from '../../../../components/Button'
import NumberRenderer from '../../../../components/NumberRenderer'

const PromoCodeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &:hover {
    background-color: #206eb017;
    color: #1c70b0;
    font-weight: 500;
  }
`

const PromoCodeList = ({ promoCodes, onChange, loading, readOnly }) => {
  const [formState, setFormState] = React.useState('')

  const handleCreatePromo = promo => {
    onChange([...promoCodes, promo])
    setFormState('')
  }

  const handleUpdatePromo = (promo, promoId) => {
    let tmp = [...promoCodes]
    const indexToUpdate = tmp.findIndex(pc => pc.title === promoId)
    tmp[indexToUpdate] = promo
    onChange(tmp)
    setFormState('')
  }

  const handleDeletePromo = promoId => {
    let tmp = [...promoCodes]
    const indexToDel = tmp.findIndex(pc => pc.title === promoId)
    tmp.splice(indexToDel, 1)
    onChange(tmp)
    setFormState('')
  }

  return (
    <div>
      {promoCodes.map(pc => (
        <div key={pc.title}>
          {!readOnly && formState === pc.title ? (
            <PromoCodeForm
              data={pc}
              onDelete={handleDeletePromo}
              onSave={promo => handleUpdatePromo(promo, pc.title)}
            />
          ) : (
            <PromoCodeItem onClick={() => setFormState(pc.title)}>
              <span>
                {pc.title} ({pc.max_use})
              </span>
              <NumberRenderer
                value={pc.discount}
                prefix="ลด"
                suffix={pc.type === 'FIX' ? 'บาท' : '%'}
              />
            </PromoCodeItem>
          )}
        </div>
      ))}
      {formState === 'create' && (
        <PromoCodeForm onDelete={() => setFormState('')} onSave={handleCreatePromo} />
      )}
      {!readOnly && (
        <Button
          icon={<Icon type="plus" />}
          type="primary"
          onClick={() => setFormState('create')}
          ghost
          block
          loading={loading}
        >
          เพิ่มโปรโมโค้ด
        </Button>
      )}
    </div>
  )
}

PromoCodeList.propTypes = {
  promoCodes: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      type: PT.oneOf(['FIX', 'PERCENT', 'PRIVATE']),
      discount: PT.number,
      max_use: PT.number,
    }),
  ),
  onChange: PT.func,
  loading: PT.bool,
  readOnly: PT.bool,
}

PromoCodeList.defaultProps = {
  promoCodes: [],
  onChange: () => {},
}

export default PromoCodeList
