import React from 'react'
import PT from 'prop-types'
import { Input, Switch } from 'antd'

import InputField from '../../../../components/InputField'

const KeywordConfigForm = ({ data, onChange, loading }) => {
  const [input, setInput] = React.useState({
    title: data.title,
    published: data.published,
  })
  const isGetStarted = data.title === 'Get Started'

  React.useEffect(() => {
    setInput({ title: data.title, published: data.published })
  }, [data])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <div style={{ padding: '24px 36px', borderBottom: '1px solid rgba(0, 0, 0, 0.5)' }}>
      <InputField title="หัวข้อ">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ width: 'calc(100% - 80px)' }}>
            <Input
              value={input.title}
              onChange={e => inputChange({ title: e.target.value })}
              onBlur={isGetStarted ? undefined : () => onChange({ title: input.title })}
              onPressEnter={isGetStarted ? undefined : () => onChange({ title: input.title })}
              readOnly={isGetStarted}
              disabled={loading}
            />
          </div>
          <div>
            <label style={{ marginRight: 8 }}>ปิด</label>
            <Switch
              checked={input.published}
              onChange={e => onChange({ published: e })}
              loading={loading}
            />
          </div>
        </div>
      </InputField>
    </div>
  )
}

KeywordConfigForm.propsTypes = {
  data: PT.shape({
    title: PT.string,
    published: PT.bool,
  }),
  onChange: PT.func,
  loading: PT.bool,
}

KeywordConfigForm.defaultProps = {
  data: {},
  onChange: () => {},
}

export default KeywordConfigForm
