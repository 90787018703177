import React from 'react'
import ReactApexChart from 'react-apexcharts'
import PT from 'prop-types'

const LineChart = ({ color }) => {
  const options = {
    annotations: {
      position: 'front',
      points: [
        {
          x: 9,
          y: 148,
          marker: {
            size: 6,
            fillColor: '#fff',
            strokeColor: color,
            radius: 2,
          },
          label: {
            text: '148',
            offsetY: 0,
            borderWidth: 0,
            style: {
              color: color,
            },
          },
        },
      ],
    },
    colors: [color],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) =>
        `<div class="linechart-tooltip">${series[seriesIndex][dataPointIndex]}</div>`,
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
  }
  const series = [
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]
  return (
    <div
      style={{
        width: 320,
        borderRadius: 4,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        padding: '16px 8px',
        marginRight: 16,
        marginBottom: 16,
      }}
    >
      <div style={{ padding: '0 8px' }}>
        <div style={{ fontWeight: 600, fontSize: '1.3em' }}>เพื่อน</div>
        <div>
          <span style={{ fontSize: '1.8em', fontWeight: 500, marginRight: 8, color }}>1,234</span>
          <small>32%</small>
        </div>
      </div>
      <ReactApexChart options={options} series={series} type="line" />
    </div>
  )
}

LineChart.propTypes = {
  color: PT.string,
}

LineChart.defaultProps = {
  color: '#1C70B0',
}

export default LineChart
