import React from 'react'
import { Input, Row, Col, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import InputField from '../../components/InputField'
import Button from '../../components/Button'

const SENT_RESET_LINK = gql`
  mutation sendResetPwdLink($email: String!) {
    sendResetPwdLink(email: $email)
  }
`

const ForgotPwd = ({ history }) => {
  const [sendLink, { loading, error: mutationError }] = useMutation(SENT_RESET_LINK)
  const [email, emailChange] = React.useState('')
  const [errorMsg, setError] = React.useState('')

  const gotoLogin = () => {
    history.push('/login')
  }

  const validateEmail = mail => {
    return /([\S]+@[\S]+\.[\S]+)/.test(mail)
  }

  const handleResetPwdClick = async () => {
    if (!validateEmail(email)) {
      setError('กรุณาใส่อีเมลที่ถูกต้อง')
      return
    }

    setError('')
    try {
      await sendLink({ variables: { email } })
      message.success('กรุณาตรวจสอบอีเมลของคุณ.')
    } catch (err) {
      console.error(err.message)
    }
  }

  if (mutationError && mutationError.message === 'GraphQL error: Not Found User') {
    const msg = 'ไม่มีอีเมลนี้ในระบบ'
    errorMsg !== msg && setError(msg)
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 468,
        }}
      >
        <h1 style={{ textAlign: 'center' }}>ลืมรหัสผ่าน</h1>
        <div style={{ color: 'rgba(183, 183, 183, 1)', textAlign: 'center', marginBottom: 32 }}>
          กรุณากรอกอีเมลที่ลงทะเบียนไว้ เพื่อให้ทางระบบส่งอีเมล รีเซ็ตรหัสผ่านกลับ{' '}
        </div>
        <InputField title="อีเมล" style={{ marginBottom: 24 }}>
          <Input
            value={email}
            onChange={e => emailChange(e.target.value)}
            placeholder="example@mail.com"
            onPressEnter={handleResetPwdClick}
            autoFocus
          />
          {errorMsg && <small style={{ color: 'red', marginLeft: 4 }}>{errorMsg}</small>}
        </InputField>
        <Row gutter={16}>
          <Col span={12}>
            <Button size="large" onClick={gotoLogin} type="secondary" block>
              ยกเลิก
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="large"
              onClick={handleResetPwdClick}
              loading={loading}
              type="primary"
              block
            >
              ตั้งค่ารหัสผ่านใหม่
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ForgotPwd
