import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Icon, Input, Button } from 'antd'

import Loading from '../../../components/Loading'
import { queryStrToObj } from '../../../helpers/queryStr'

const HAS_USER = gql`
  query hasUser($socialId: String) {
    hasUser(social_id: $socialId) {
      name
      email
      full_name
    }
  }
`

const LINE_REGISTER = gql`
  mutation lineRegister($uid: String, $displayName: String, $email: String, $phone: String) {
    lineRegister(uid: $uid, display_name: $displayName, email: $email, phone: $phone) {
      name
      email
      full_name
    }
  }
`

const LINE_LOGIN = gql`
  mutation lineLogin($uid: String!) {
    lineLogin(uid: $uid) {
      user {
        name
        email
        full_name
      }
      token
    }
  }
`

class LineLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: {
        email: '',
        phone: '',
      },
      loading: false,
    }
  }

  inputChange = item => {
    const { input } = this.state
    this.setState({ input: { ...input, ...item } })
  }

  login = async () => {
    const { login, lineData, history } = this.props
    try {
      this.setState({ loading: true })
      const { data } = await login({ variables: { uid: lineData.uid } })
      const lineLogin = (data && data.lineLogin) || {}
      const user = lineLogin.user || {}
      localStorage.setItem('access_token', lineLogin.token)
      localStorage.setItem('user_id', user.name)
      setTimeout(() => {
        this.setState({ loading: false })
        history.push('/')
      }, 1000)
    } catch (err) {
      console.error(err.message)
    }
  }

  register = async () => {
    const { register, lineData } = this.props
    const { input } = this.state
    try {
      this.setState({ loading: true })
      await register({ variables: { ...input, ...lineData } })
      this.login()
    } catch (err) {
      console.error(err.message)
    }
  }

  componentDidMount() {
    const { history, location, hasUser } = this.props
    if (hasUser) {
      this.login()
    } else {
      history.push({ pathname: '/register', search: location.search })
    }
  }

  render() {
    const { hasUser } = this.props
    const { input, loading } = this.state
    if (hasUser) {
      return <Loading />
    }
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            maxWidth: 360,
            border: '1px solid rgba(0, 0, 0, 0.17)',
            borderRadius: 4,
            boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)', padding: '8px 16px' }}>
            <Icon type="form" style={{ marginRight: 8 }} />
            <b>Register</b>
          </div>
          <div style={{ padding: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <small>Please input some information for craete Account.</small>
            </div>
            <Input
              value={input.email}
              onChange={e => this.inputChange({ email: e.target.value })}
              style={{ marginBottom: 8 }}
              placeholder="Email address"
              onPressEnter={this.register}
              disabled={loading}
            />
            <Input
              value={input.phone}
              onChange={e => this.inputChange({ phone: e.target.value })}
              style={{ marginBottom: 24 }}
              placeholder="Phone Number"
              onPressEnter={this.register}
              disabled={loading}
            />
            <Button
              type="primary"
              onClick={this.register}
              loading={loading}
              disabled={!(input.email && input.phone)}
              block
            >
              Register
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default props => {
  const params = queryStrToObj(props.location.search)
  const tmp = queryStrToObj('?' + atob(decodeURIComponent(params.q)))
  const lineData = {
    ...tmp,
    displayName: decodeURIComponent(tmp.displayName),
  }

  const { data, loading, error } = useQuery(HAS_USER, {
    variables: { socialId: lineData.uid },
  })
  const [register] = useMutation(LINE_REGISTER)
  const [login] = useMutation(LINE_LOGIN)

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error.message)
  }

  const hasUser = data && data.hasUser
  return (
    <LineLogin {...props} register={register} login={login} hasUser={hasUser} lineData={lineData} />
  )
}
