import AgentContainer from '../container/Agent'
import AudienceContainer from '../container/Audience'
import CampaignContainer from '../container/Campaign'
import EventContainer from '../container/Event'
import MainContainer from '../container/Main'
import SalesContainer from '../container/Sales'
import SettingContainer from '../container/Setting'
import WisibleContainer from '../container/Wisible'

export default [
  {
    path: '/agent',
    page: AgentContainer,
    module: 'AGENT',
  },
  {
    path: '/audience',
    page: AudienceContainer,
  },
  {
    path: '/event',
    page: EventContainer,
    module: 'EVENT',
  },
  {
    path: '/main',
    page: MainContainer,
  },
  {
    path: '/campaign',
    page: CampaignContainer,
  },
  {
    path: '/sales',
    page: SalesContainer,
  },
  {
    path: '/setting',
    page: SettingContainer,
  },
  {
    path: '/wisible',
    page: WisibleContainer,
    module: 'WISIBLE',
  },
]
