import React from 'react'
import PT from 'prop-types'
import { Switch } from 'antd'

import LineBotForm from './LineBotForm'
import LineLoginForm from './LineLoginForm'
import LineNotifyForm from './LineNotifyForm'
import Button from '../../../../../components/Button'
import InputField from '../../../../../components/InputField'
import FacebookPageForm from './FacebookPageForm'

const ChannelForm = ({ channel, loading, onSave, onDelete, onToggle }) => {
  const platform = channel.platform

  const [input, setInput] = React.useState({
    ...channel,
    name: undefined,
    platform: undefined,
    __typename: undefined,
  })

  React.useEffect(() => {
    setInput({
      ...channel,
      name: undefined,
      platform: undefined,
      __typename: undefined,
    })
  }, [channel])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const getTitle = () => {
    switch (platform) {
      case 'FacebookBot':
        return 'Facebook Page'
      case 'LineBot':
        return 'LINE Bot'
      case 'LineLogin':
        return 'LINE Login'
      case 'LineNotify':
        return 'LINE Notify'
      default:
        return ''
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px 16px',
        }}
      >
        <h1>{getTitle()}</h1>
      </div>
      <div style={{ padding: '0 36px 16px', width: '100%', maxWidth: '480px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InputField title="ID">
            <span style={{ fontWeight: 500 }}>{channel.name}</span>
          </InputField>
          <div>
            <span style={{ marginRight: 8, color: '#B7B7B7' }}>เปิดใช้งาน</span>
            <Switch loading={loading} checked={input.enabled} onChange={e => onToggle(e)} />
          </div>
        </div>
        {platform === 'FacebookBot' && <FacebookPageForm data={channel} />}
        {platform === 'LineBot' && (
          <LineBotForm
            loading={loading}
            chId={channel.name}
            input={input}
            inputChange={inputChange}
          />
        )}
        {platform === 'LineLogin' && (
          <LineLoginForm
            loading={loading}
            chId={channel.name}
            input={input}
            inputChange={inputChange}
          />
        )}
        {platform === 'LineNotify' && (
          <LineNotifyForm
            loading={loading}
            chId={channel.name}
            input={input}
            inputChange={inputChange}
          />
        )}
        {platform !== 'FacebookBot' && (
          <Button
            style={{ margin: '16px 0 8px' }}
            block
            loading={loading}
            onClick={() => onSave(input)}
            type="primary"
          >
            บันทึก
          </Button>
        )}
        <Button block loading={loading} onClick={() => onDelete(channel.name)} type="danger" ghost>
          ลบแชลแนลนี้
        </Button>
      </div>
    </>
  )
}

ChannelForm.propTypes = {
  channel: PT.shape({
    name: PT.string,
    title: PT.string,
    platform: PT.oneOf(['FacebookBot', 'LineBot', 'LineLogin', 'LineNotify']),
    enabled: PT.bool,
    channel_secret: PT.string,
    id: PT.string,
    access_token: PT.string,
  }),
  loading: PT.bool,
  onSave: PT.func,
  onDelete: PT.func,
  onToggle: PT.func,
}

ChannelForm.defaultProps = {
  onSave: () => {},
  onDelete: () => {},
  onToggle: () => {},
}

export default ChannelForm
