import React from 'react'
import PT from 'prop-types'
import { Input, DatePicker, TimePicker } from 'antd'
import moment from 'moment'

import InputField from '../../../components/InputField'
import InputDraft from '../../../components/InputDraft'

const EventForm = ({ input, inputChange, loading, readOnly }) => {
  const handleDateChange = (date, key) => {
    const curr = input[key] ? moment(input[key], 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: date.year(),
      M: date.month(),
      d: date.date(),
      h: curr.hour(),
      m: curr.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ [key]: tmp.format('YYYY-MM-DD HH:mm:ss') })
  }

  const handleTimeChange = (time, key) => {
    const curr = input[key] ? moment(input[key], 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: curr.year(),
      M: curr.month(),
      d: curr.date(),
      h: time.hour(),
      m: time.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ [key]: tmp.format('YYYY-MM-DD HH:mm:ss') })
  }

  return (
    <>
      <InputField title="ชื่ออีเว้นท์" isRequired>
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value })}
          disabled={loading}
          readOnly={readOnly}
        />
      </InputField>
      <InputField title="อีเว้นท์วันที่" isRequired>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <span style={{ color: '#b7b7b7' }}>วัน/เวลาเริ่ม</span>
          <div>
            <DatePicker
              format="DD / MM / YYYY"
              value={input.from ? moment(input.from, 'YYYY-MM-DD HH:mm:ss') : undefined}
              onChange={obj => handleDateChange(obj, 'from')}
              placeholder="DD / MM / YYYY"
              style={{ marginRight: 8 }}
              disabled={loading || readOnly}
            />
            <TimePicker
              format="HH : mm"
              placeholder="HH : mm"
              value={input.from ? moment(input.from, 'YYYY-MM-DD HH:mm:ss') : undefined}
              onChange={obj => handleTimeChange(obj, 'from')}
              disabled={loading || readOnly}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ color: '#b7b7b7' }}>วัน/เวลาสิ้นสุด</span>
          <div>
            <DatePicker
              format="DD / MM / YYYY"
              value={input.to ? moment(input.to, 'YYYY-MM-DD HH:mm:ss') : undefined}
              onChange={obj => handleDateChange(obj, 'to')}
              placeholder="DD / MM / YYYY"
              style={{ marginRight: 8 }}
              disabled={loading || readOnly}
            />
            <TimePicker
              format="HH : mm"
              placeholder="HH : mm"
              value={input.to ? moment(input.to, 'YYYY-MM-DD HH:mm:ss') : undefined}
              onChange={obj => handleTimeChange(obj, 'to')}
              disabled={loading || readOnly}
            />
          </div>
        </div>
      </InputField>
      <InputField title="สถานที่จัดงาน" isRequired>
        <Input
          value={input.venue}
          onChange={e => inputChange({ venue: e.target.value })}
          disabled={loading}
          readOnly={readOnly}
        />
      </InputField>
      <InputField title="รายละเอียดกิจกรรม(ก่อนจองตั๋ว)">
        <InputDraft
          defaultValue={input.description}
          onChange={val => inputChange({ description: val })}
          readOnly={loading || readOnly}
        />
      </InputField>
      <InputField title="รายละเอียดกิจกรรม(หลังจองตั๋ว)">
        <InputDraft
          defaultValue={input.description_paid}
          onChange={val => inputChange({ description_paid: val })}
          readOnly={loading || readOnly}
        />
      </InputField>
    </>
  )
}

EventForm.propTypes = {
  input: PT.shape({
    title: PT.string,
    from: PT.string,
    to: PT.string,
    venue: PT.string,
    description: PT.string,
    description_paid: PT.string,
    image_url: PT.string,
    image_url_line: PT.string,
    image_url_facebook: PT.string,
    cover_url: PT.string,
  }),
  inputChange: PT.func,
  loading: PT.bool,
  readOnly: PT.bool,
}

EventForm.defaultProps = {
  input: {},
  inputChange: () => {},
}

export default EventForm
