import React from 'react'
import { Icon, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import InputField from '../../../../components/InputField'
import RichmenuTable from '../../../richmenu/RichmenuList/RichmenuTable'

const ALL_CHANNELS = gql`
  query allChannels($botId: String!) {
    allChannels(bot_id: $botId) {
      name
      title
      platform
    }
  }
`

const RichmenuList = ({ history, botId, lineChannels }) => {
  const [currChannel, setCurrChannel] = React.useState(
    lineChannels.length === 1 ? lineChannels[0].name : undefined,
  )

  const openCreate = () => {
    history.push(`/app/bot/${botId}/main/richmenu/create`)
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 16,
          }}
        >
          <h1>ริชเมนู</h1>
          <div>
            <Button type="primary" ghost icon={<Icon type="plus" />} onClick={openCreate}>
              เพิ่มใหม่
            </Button>
          </div>
        </div>
        <InputField title="Channel" compact>
          <Select
            style={{ width: 180 }}
            value={currChannel}
            onChange={val => setCurrChannel(val)}
            placeholder="เลือกแชลแนล.."
          >
            {lineChannels.map(ch => (
              <Select.Option key={ch.name}>{ch.title}</Select.Option>
            ))}
          </Select>
        </InputField>
      </div>
      {currChannel && (
        <RichmenuTable chId={currChannel} height={window.innerHeight - 60 - 48 - 161 - 54} />
      )}
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const { data, loading, error } = useQuery(ALL_CHANNELS, { variables: { botId } })

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }

  const allChannels = (data && data.allChannels) || []
  const lineChannels = allChannels.filter(ch => ch.platform === 'LineBot')
  return <RichmenuList {...props} botId={botId} lineChannels={lineChannels} />
}
