import React from 'react'
import PT from 'prop-types'
import { Input, InputNumber, Divider, DatePicker, TimePicker, Select } from 'antd'
import moment from 'moment'

import PromoCodeList from './PromoCodeList'
import Button from '../../../../components/Button'
import InputField from '../../../../components/InputField'

const TicketTypeForm = ({ input, inputChange, onSave, onDelete, loading, readOnly }) => {
  const handleDateChange = (date, key) => {
    const curr = input[key] ? moment(input[key], 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: date.year(),
      M: date.month(),
      d: date.date(),
      h: curr.hour(),
      m: curr.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ [key]: tmp.format('YYYY-MM-DD HH:mm:ss') })
  }

  const handleTimeChange = (time, key) => {
    const curr = input[key] ? moment(input[key], 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: curr.year(),
      M: curr.month(),
      d: curr.date(),
      h: time.hour(),
      m: time.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ [key]: tmp.format('YYYY-MM-DD HH:mm:ss') })
  }
  return (
    <>
      <div
        style={{
          maxWidth: 480,
          padding: '12px 24px',
        }}
      >
        <InputField title="ชื่อตั๋ว">
          <Input
            value={input.title}
            onChange={e => inputChange({ title: e.target.value })}
            readOnly={readOnly}
            disabled={loading}
          />
        </InputField>
        <InputField title="วัน/เวลาขายตั๋ว">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <span style={{ color: '#b7b7b7' }}>เริ่มขายตั๋ว</span>
            <div>
              <DatePicker
                format="DD / MM / YYYY"
                value={input.from ? moment(input.from, 'YYYY-MM-DD HH:mm:ss') : undefined}
                onChange={obj => handleDateChange(obj, 'from')}
                placeholder="DD / MM / YYYY"
                style={{ marginRight: 8 }}
                disabled={loading || readOnly}
              />
              <TimePicker
                format="HH : mm"
                placeholder="HH : mm"
                value={input.from ? moment(input.from, 'YYYY-MM-DD HH:mm:ss') : undefined}
                onChange={obj => handleTimeChange(obj, 'from')}
                disabled={loading || readOnly}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ color: '#b7b7b7' }}>สิ้นสุดขายตั๋ว</span>
            <div>
              <DatePicker
                format="DD / MM / YYYY"
                value={input.to ? moment(input.to, 'YYYY-MM-DD HH:mm:ss') : undefined}
                onChange={obj => handleDateChange(obj, 'to')}
                placeholder="DD / MM / YYYY"
                style={{ marginRight: 8 }}
                disabled={loading || readOnly}
              />
              <TimePicker
                format="HH : mm"
                placeholder="HH : mm"
                value={input.to ? moment(input.to, 'YYYY-MM-DD HH:mm:ss') : undefined}
                onChange={obj => handleTimeChange(obj, 'to')}
                disabled={loading || readOnly}
              />
            </div>
          </div>
        </InputField>
        <InputField title="จำนวนตั๋วที่เปิดขาย">
          <InputNumber
            value={input.qty}
            onChange={val => inputChange({ qty: val })}
            style={{ width: '100%' }}
            disabled={loading}
            readOnly={readOnly}
          />
        </InputField>
        <InputField title="ราคาตั๋ว">
          <InputNumber
            value={input.rate}
            onChange={val => inputChange({ rate: val })}
            style={{ width: '100%' }}
            disabled={loading}
            readOnly={readOnly}
          />
        </InputField>
        <InputField title="ภาษีมูลค่าเพิ่ม (VAT)">
          <Select
            value={input.vat_type}
            onChange={val => inputChange({ vat_type: val })}
            style={{ width: 200 }}
          >
            <Select.Option key="IN_VAT">รวมภาษีแล้ว</Select.Option>
            <Select.Option key="EX_VAT">ไม่รวมภาษี</Select.Option>
            <Select.Option key="NO_VAT">ไม่มีภาษี</Select.Option>
          </Select>
        </InputField>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div
        style={{
          maxWidth: 480,
          padding: '12px 24px',
        }}
      >
        <InputField title="โปรโมโค้ด">
          <PromoCodeList
            promoCodes={input.promo_codes}
            onChange={val => inputChange({ promo_codes: val })}
            loading={loading}
            readOnly={readOnly}
          />
        </InputField>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      {!readOnly && (
        <div
          style={{
            maxWidth: 480,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 32,
          }}
        >
          {onDelete && (
            <Button onClick={onDelete} style={{ marginRight: 8 }} type="danger" loading={loading}>
              ลบ
            </Button>
          )}
          <Button onClick={() => onSave(input)} type="primary" loading={loading}>
            บันทึก
          </Button>
        </div>
      )}
    </>
  )
}

TicketTypeForm.propTypes = {
  input: PT.shape({
    name: PT.string,
    title: PT.string,
    from: PT.string,
    to: PT.string,
    qty: PT.number,
    rate: PT.number,
    promo_codes: PT.arrayOf(
      PT.shape({
        name: PT.string,
        title: PT.string,
        type: PT.oneOf(['FIX', 'PERCENT', 'PRIVATE']),
        discount: PT.number,
        max_use: PT.number,
      }),
    ),
  }),
  inputChange: PT.func,
  onSave: PT.func,
  onDelete: PT.func,
  loading: PT.bool,
  readOnly: PT.bool,
}

TicketTypeForm.defaultProps = {
  inputChange: () => {},
  onSave: () => {},
}

export default TicketTypeForm
