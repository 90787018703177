import React from 'react'

import Divider from '../../../components/Divider'
import LineChart from '../../../components/LineChart'
import LineChartDouble from '../../../components/LineChartDouble'

const Dashboard = () => {
  return (
    <div>
      <div style={{ padding: '24px 36px 16px' }}>
        <h1>แดชบอร์ด</h1>
      </div>
      <div style={{ padding: '0 36px 16px' }}>
        <Divider>ข้อมูลบอท</Divider>
        <div style={{ display: 'flex' }}>
          <LineChart />
          <LineChartDouble />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
