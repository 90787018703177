import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'

const SalesSidenav = ({ routeProps }) => {
  const initUrl = routeProps.match.url

  return (
    <div style={{ padding: 36, display: 'flex', flexDirection: 'column' }}>
      <NavLink to={initUrl + '/leads/list'} className="sidenav-item" activeClassName="active">
        รายชื่อ Leads
      </NavLink>
    </div>
  )
}

SalesSidenav.propTypes = {
  routeProps: PT.object.isRequired,
}

export default SalesSidenav
