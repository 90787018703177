import React from 'react'
import PT from 'prop-types'
import { Icon, Input, Menu, Dropdown, Progress, Radio } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import TagDropZone from './TagDropZone'
import TagItem from './TagItem'
import NumberRenderer from '../../../components/NumberRenderer'

const GET_SEGMENT_STATS = gql`
  query getSegmentStats($botId: String!, $segmentId: String!) {
    getSegmentStats(bot_id: $botId, segment_id: $segmentId)
  }
`

const IconBtn = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${({ active }) => active && 'background-color: rgba(0, 0, 0, 0.05)'};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const SegmentItem = ({ bot, color, segment, onChange, onDrop, onDelete, onTagDelete, loading }) => {
  const { name, title, includes, excludes } = segment

  const [menuState, setMenuState] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [input, inputChange] = React.useState(title)

  const { data, error, refetch } = useQuery(GET_SEGMENT_STATS, {
    variables: { botId: bot.name, segmentId: segment.name },
  })

  React.useEffect(() => {
    refetch()
  }, [segment, refetch])

  if (error) {
    console.error(error)
  }

  const segmentStat = (data && data.getSegmentStats) || {}

  const handleSubmit = () => {
    onChange({ title: input })
    setEditing(false)
  }

  const menu = (
    <Menu onClick={() => setMenuState(false)}>
      <Menu.Item onClick={() => setEditing(true)}>
        <Icon type="edit" style={{ marginRight: 4 }} />
        <span>แก้ไขชื่อกลุ่ม</span>
      </Menu.Item>
      <Menu.Item onClick={() => onDelete()} style={{ color: '#FF5C5C' }}>
        <Icon type="delete" style={{ marginRight: 4 }} />
        <span>ลบกลุ่ม</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div
      style={{
        minWidth: 220,
        textAlign: 'center',
        border: '1px solid #DBDBDB',
        borderRadius: 4,
        padding: '16px 8px 8px',
        marginRight: 16,
        marginBottom: 16,
      }}
    >
      <TagDropZone
        title={title}
        style={{ borderBottom: '1px solid #DBDBDB', marginBottom: 16 }}
        zone={{ segment: name, key: 'includes' }}
      >
        <div style={{ position: 'relative', marginBottom: 16 }}>
          <Progress
            type="circle"
            showInfo={false}
            strokeColor={color}
            percent={((segmentStat.total || 0) / bot.friend_num) * 100}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div style={{ fontWeight: 500, fontSize: '1.6em', lineHeight: '0.6em' }}>
              <NumberRenderer value={segmentStat.total} />
            </div>
            <div style={{ fontSize: '1.4em' }}>คน</div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              visible={menuState}
              onVisibleChange={val => setMenuState(val)}
            >
              <IconBtn active={menuState}>
                <Icon type="more" />
              </IconBtn>
            </Dropdown>
          </div>
          {editing ? (
            <Input
              value={input}
              onChange={e => inputChange(e.target.value)}
              style={{ marginBottom: 16 }}
              onBlur={handleSubmit}
              onPressEnter={handleSubmit}
              autoFocus
            />
          ) : (
            <div style={{ marginBottom: 8, padding: '0 16px' }}>
              <div className="text-wrap-ellipsis" style={{ width: 170 }}>
                {title || 'Segment'}
              </div>
            </div>
          )}
        </div>
        <div>
          <Radio.Group
            size="small"
            buttonStyle="solid"
            value={segment.union ? 1 : 0}
            onChange={e => onChange({ union: e.target.value === 1 })}
            style={{ width: '100%', marginBottom: 16 }}
            disabled={loading}
          >
            <Radio.Button style={{ width: '50%' }} value={0}>
              Intersect
            </Radio.Button>
            <Radio.Button style={{ width: '50%' }} value={1}>
              Union
            </Radio.Button>
          </Radio.Group>
        </div>
        {includes.map(tag => (
          <TagItem
            key={tag}
            tag={tag}
            owner={{ segment: name, key: 'includes' }}
            onDrop={(item, zone) => onDrop(item, zone)}
            onDelete={() => onTagDelete('includes', tag)}
          />
        ))}
      </TagDropZone>
      {segment.union && (
        <div>
          <TagDropZone title="ยกเว้นเพื่อนที่มีแท็ก" zone={{ segment: name, key: 'excludes' }}>
            <div style={{ marginBottom: 16, padding: '0 16px' }}>
              <span>ยกเว้นเพื่อนที่มีแท็ก</span>
            </div>
            {excludes.map(tag => (
              <TagItem
                key={tag}
                tag={tag}
                owner={{ segment: name, key: 'excludes' }}
                onDrop={(item, zone) => onDrop(item, zone)}
                onDelete={() => onTagDelete('excludes', tag)}
                danger
              />
            ))}
          </TagDropZone>
        </div>
      )}
    </div>
  )
}

SegmentItem.propTypes = {
  bot: PT.shape({
    name: PT.string,
    friend_num: PT.number,
  }),
  color: PT.string,
  segment: PT.shape({
    name: PT.string,
    title: PT.string,
    includes: PT.arrayOf(PT.string),
    excludes: PT.arrayOf(PT.string),
    union: PT.bool,
  }),
  onChange: PT.func,
  onDrop: PT.func,
  onDelete: PT.func,
  onTagDelete: PT.func,
  loading: PT.bool,
}

SegmentItem.defaultProps = {
  onChange: () => {},
  onDrop: () => {},
  onDelete: () => {},
}

export default SegmentItem
