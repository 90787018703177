import React from 'react'
import { Input, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import InputField from '../../components/InputField'
import Button from '../../components/Button'
import { queryStrToObj } from '../../helpers/queryStr'

const SET_PWD = gql`
  mutation setPassword($token: String!, $pwd: String!) {
    setPassword(token: $token, pwd: $pwd) {
      token
    }
  }
`

const SetPassword = ({ location, history }) => {
  const params = queryStrToObj(location.search)
  const [setPassword, { loading }] = useMutation(SET_PWD)
  const [pwd, pwdChange] = React.useState('')
  const [confirmPwd, confirmPwdChange] = React.useState('')
  const [errorMsg, setError] = React.useState('')

  const validateConfirmPwd = () => {
    return pwd === confirmPwd
  }

  const handleSetPwdClick = async () => {
    if (!validateConfirmPwd()) {
      setError('กรุณากรอกรหัสให้ตรงกัน')
      return
    }

    if (!params.token) {
      message.error('ไม่พบ token!! กรุณาตรวจสอบ url อีกครั้ง')
      return
    }

    setError('')
    try {
      await setPassword({ variables: { token: params.token, pwd } })
      message.success('สำเร็จ')
      history.push('/login')
    } catch (err) {
      console.error(err.message)
    }
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 468,
        }}
      >
        <h1 style={{ textAlign: 'center' }}>ยืนยันตัวตนสำเร็จ</h1>
        <div style={{ color: 'rgba(183, 183, 183, 1)', textAlign: 'center', marginBottom: 32 }}>
          กรุณากรอกรหัสผ่านใหม่
        </div>
        <InputField title="รหัสผ่าน">
          <Input type="password" value={pwd} onChange={e => pwdChange(e.target.value)} />
        </InputField>
        <InputField title="ยืนยันรหัสผ่าน" style={{ marginBottom: 36 }}>
          <Input
            type="password"
            value={confirmPwd}
            onChange={e => confirmPwdChange(e.target.value)}
          />
          {errorMsg && <small style={{ color: 'red', marginLeft: 4 }}>{errorMsg}</small>}
        </InputField>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="large" onClick={handleSetPwdClick} loading={loading} type="primary">
            ตั้งค่ารหัสผ่านใหม่
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SetPassword
