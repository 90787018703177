import React from 'react'
import { Icon, Modal, message } from 'antd'

import FbPageCard from './FbPageCard'
import Button from '../../../../components/Button'
import Fb from '../../../../services/_facebookClient'

const AddFacebookChannelBtn = ({ botId, createChannel }) => {
  const [loading, setLoading] = React.useState(true)
  const [modalState, setModal] = React.useState(false)
  const [pages, setPages] = React.useState([])

  React.useEffect(() => {
    Fb.initialFbSdk(() => setLoading(false))
  })

  const addChannel = async page => {
    try {
      setLoading(true)
      const input = {
        enabled: true,
        title: page.name,
        id: page.id,
        access_token: page.access_token,
      }
      await createChannel({ variables: { botId, platform: 'FacebookBot', input } })
      setModal(false)
      message.success('เชื่อมต่อ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const onLoginStateChange = res => {
    if (res.status === 'connected') {
      Fb.getUserAccount(res => {
        setPages(res.data)
        setModal(true)
      })
    } else if (res.status === 'not_authorized') {
      Fb.login(onLoginStateChange)
    } else if (res.status === 'unknown') {
      Fb.login(onLoginStateChange)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
      }}
    >
      <div>
        <span>Facebook Page</span>
      </div>
      <Button
        loading={loading}
        onClick={() => Fb.login(onLoginStateChange)}
        icon={<Icon type="facebook" theme="filled" />}
      >
        เชื่อมต่อ
      </Button>
      <Modal
        width={792}
        visible={modalState}
        title="เลือกเพจของคุณ"
        onCancel={() => setModal(false)}
        footer={false}
        confirmLoading={loading}
      >
        {pages.length ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {pages.map(page => (
              <FbPageCard key={page.id} page={page} onClick={() => addChannel(page)} />
            ))}
          </div>
        ) : (
          <span>ไม่มีเพจที่คุณดูแลอยู่ โปรดตรวจสอบสิทธิ์การเข้าถึงอีกครั้ง</span>
        )}
      </Modal>
    </div>
  )
}

export default AddFacebookChannelBtn
