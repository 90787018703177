import LeadsList from '../pages/sales/LeadsList/index'
import LeadsDetail from '../pages/sales/LeadsDetail'

export default [
  {
    path: '/leads/list',
    page: LeadsList,
  },
  {
    path: '/leads/:leadsId',
    page: LeadsDetail,
  },
]
