import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message } from 'antd'

import Loading from '../../components/Loading'

const ACTIVATE_TOKEN = gql`
  mutation activateToken($token: String!) {
    activateToken(token: $token)
  }
`

const ActivateAdmin = ({ history, match }) => {
  const [activateToken] = useMutation(ACTIVATE_TOKEN, {
    onCompleted: () => {
      message.success('Success')
      history.replace('/app/bot/list')
    },
    onError: ({ graphQLErrors }) => {
      console.log(graphQLErrors)
      const err = graphQLErrors[0]
      const errorMsg = err.message
      const statusCode = err.extensions.code

      if (statusCode === 404) {
        message.error('Invalid invitation link.')
      } else {
        message.error(errorMsg)
      }
      history.replace('/app/bot/list')
    },
  })
  const token = match.params.token

  React.useEffect(() => {
    const activate = async () => {
      const accessToken = window.localStorage.getItem('access_token')
      if (accessToken) {
        await activateToken({ variables: { token } })
      } else {
        message.warning('Please login and open invitation link again.')
      }
    }

    activate()
  }, [])

  return <Loading />
}

export default ActivateAdmin
