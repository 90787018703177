import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'
import { NavLink } from 'react-router-dom'

import { objToQueryStr } from '../../helpers/queryStr'

const AppTopNav = ({ routeProps, bot }) => {
  const { match } = routeProps
  const initUrl = match.url

  const botId = match.params.botId
  const accessToken = localStorage.getItem('access_token')
  const userId = localStorage.getItem('user_id')

  const getEnv = () => {
    if (window.location.hostname === 'localhost') {
      return 'LOCAL'
    } else if (window.location.host.substr(0, 4) === 'uat-') {
      return 'UAT'
    } else if (window.location.host.substr(0, 4) === 'dev-') {
      return 'DEV'
    } else {
      return 'PRODUCTION'
    }
  }

  const openChat = () => {
    const paramToSend = {
      destination: `/${botId}/chat`,
      access_token: accessToken,
      user_id: userId,
    }
    if (window.location.origin.includes('manager')) {
      if (getEnv() === 'DEV') {
        window.open(`https://dev-chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      } else if (getEnv() === 'UAT') {
        window.open(`https://uat-chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      } else {
        window.open(`https://chat.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
      }
    } else {
      window.open(`https://chat-shell.aiya.ai/b/p${objToQueryStr(paramToSend)}`, '_blank')
    }
  }

  const openMy = () => {
    const paramToSend = {
      bot: botId,
      access_token: accessToken,
      user_id: userId,
    }
    if (getEnv() === 'DEV') {
      window.open(`https://dev-my.aiya.ai/portal${objToQueryStr(paramToSend)}`, '_blank')
    } else if (getEnv() === 'UAT') {
      window.open(`https://uat-my.aiya.ai/portal${objToQueryStr(paramToSend)}`, '_blank')
    } else {
      window.open(`https://my.aiya.ai/portal${objToQueryStr(paramToSend)}`, '_blank')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      }}
    >
      <div>
        <NavLink to={initUrl + '/main'} className="topnav-item" activeClassName="active">
          หน้าหลัก
        </NavLink>
        {/* <NavLink to={initUrl + '/campaign'} className="topnav-item" activeClassName="active">
          แคมเปญ
        </NavLink> */}
        <NavLink to={initUrl + '/audience'} className="topnav-item" activeClassName="active">
          เพื่อน
        </NavLink>
        <span onClick={openChat} className="topnav-item">
          แชท
        </span>
        {/* {bot.modules.includes('BOT') && ( */}
        <span onClick={openMy} className="topnav-item">
          บอท
        </span>
        {/* )} */}
        {/* <NavLink to={initUrl + '/sales'} className="topnav-item" activeClassName="active">
          งานขาย
        </NavLink> */}
        {(bot.modules || []).includes('AGENT') && (
          <NavLink to={initUrl + '/agent'} className="topnav-item" activeClassName="active">
            เอเจนท์
          </NavLink>
        )}
        {(bot.modules || []).includes('EVENT') && (
          <NavLink to={initUrl + '/event'} className="topnav-item" activeClassName="active">
            อีเว้นท์
          </NavLink>
        )}
        {(bot.modules || []).includes('WISIBLE') && (
          <NavLink to={initUrl + '/wisible'} className="topnav-item" activeClassName="active">
            Wisible
          </NavLink>
        )}
      </div>
      <div>
        <NavLink to={initUrl + '/setting'} className="topnav-item" activeClassName="active">
          <Icon type="setting" style={{ marginRight: 8 }} />
          ตั้งค่า
        </NavLink>
      </div>
    </div>
  )
}

AppTopNav.propTypes = {
  bot: PT.object,
  routeProps: PT.object.isRequired,
}

export default AppTopNav
