import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'
import { useDrop } from 'react-dnd'

import { ItemTypes } from './dndConstants'
import TagItem from './TagItem'

const TagsPool = ({ allTags, handleTagDrop }) => {
  const [filter, setFilter] = React.useState('')
  const [, binRef] = useDrop({
    accept: ItemTypes.TAG,
    drop: () => ({ zone: { segment: '', key: 'bin' } }),
  })

  const filterTags = allTags.filter(t => t.toLowerCase().includes(filter.toLowerCase()))

  return (
    <div ref={binRef}>
      <div style={{ color: '#B7B7B7', textAlign: 'center', marginBottom: 16 }}>รายชื่อแท็ก</div>
      <Input.Search
        style={{ width: '100%', marginBottom: 8 }}
        placeholder="ค้นหาแท็ก"
        onSearch={s => setFilter(s)}
      />
      {(filterTags || []).map(tag => (
        <TagItem
          key={tag}
          tag={tag}
          owner={{ segment: '', key: 'includes' }}
          onDrop={(item, zone) => handleTagDrop(item, zone)}
        />
      ))}
    </div>
  )
}

TagsPool.propTypes = {
  allTags: PT.arrayOf(PT.string),
  handleTagDrop: PT.func,
}

TagsPool.defaultProps = {
  handleTagDrop: () => {},
}

export default TagsPool
