import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
import { message } from 'antd'
import { Switch, Route } from 'react-router-dom'

import EventForm from '../shared/EventForm'
import ImageForm from '../shared/ImageForm'
import SideMenu from '../shared/SideMenu'
import TicketTypeEditor from '../shared/TicketTypeEditor'
import Loading from '../../../components/Loading'
import Button from '../../../components/Button'

const GET_EVENT = gql`
  query getActivity($eventId: String!) {
    getActivity(activity_id: $eventId) {
      name
      title
      description
      description_paid
      from
      to
      venue
      image_url
      image_url_line
      image_url_facebook
      cover_url
    }
  }
`

const UPDATE_EVENT = gql`
  mutation updateActivity($eventId: String!, $input: ActivityInput!) {
    updateActivity(activity_id: $eventId, input: $input) {
      name
    }
  }
`

const EventEditor = ({ match, history, location, botId, event, readOnly, updateEvent }) => {
  const menu = [
    {
      key: '/info',
      title: 'ข้อมูลอีเว้นท์',
    },
    {
      key: '/image',
      title: 'รูปภาพอีเว้นท์',
    },
    {
      key: '/ticket-type',
      title: 'บัตรเข้างาน / โปรโมโค้ด',
    },
  ]

  const [loading, setLoading] = React.useState(false)
  const [input, setInput] = React.useState({
    ...event,
    name: undefined,
    __typename: undefined,
  })

  const pathAfterMatch = location.pathname.replace(match.url, '')
  const currMenu = menu.map(m => m.key).find(m => m === pathAfterMatch.substr(0, m.length))

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const handleUpdateClick = async () => {
    setLoading(true)
    try {
      await updateEvent({ variables: { eventId: event.name, input } })
      message.success('บันทึกแล้ว')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleMenuChange = key => {
    history.replace(match.url + key)
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        }}
      >
        <h1>{event.title}</h1>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 97px)' }}>
        <div style={{ width: 320, borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <SideMenu menu={menu} value={currMenu} onChange={handleMenuChange} />
        </div>
        <div style={{ position: 'relative', width: 'calc(100% - 320px)', height: '100%' }}>
          <Switch>
            <Route
              path={match.path + '/info'}
              render={() => (
                <>
                  <div
                    style={{
                      padding: '8px 36px 6px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h2 style={{ marginBottom: 0, color: '#1c70b0', fontWeight: 400 }}>
                      ข้อมูลอีเว้นท์
                    </h2>
                    {!readOnly && (
                      <Button loading={loading} onClick={handleUpdateClick} type="primary">
                        บันทึก
                      </Button>
                    )}
                  </div>
                  <div style={{ height: 'calc(100% - 56px)', overflowY: 'auto' }}>
                    <div style={{ width: 640, padding: '24px 36px 16px' }}>
                      <EventForm
                        loading={loading}
                        input={input}
                        inputChange={inputChange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                </>
              )}
            />
            <Route
              path={match.path + '/image'}
              render={() => (
                <>
                  <div
                    style={{
                      padding: '8px 36px 6px',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h2 style={{ marginBottom: 0, color: '#1c70b0', fontWeight: 400 }}>
                      รูปภาพอีเว้นท์
                    </h2>
                    {!readOnly && (
                      <Button loading={loading} onClick={handleUpdateClick} type="primary">
                        บันทึก
                      </Button>
                    )}
                  </div>
                  <div style={{ height: 'calc(100% - 56px)', overflowY: 'auto' }}>
                    <ImageForm
                      loading={loading}
                      botId={botId}
                      input={input}
                      inputChange={inputChange}
                      readOnly={readOnly}
                    />
                  </div>
                </>
              )}
            />
            <Route
              path={match.path + '/ticket-type'}
              render={props => (
                <TicketTypeEditor eventId={event.name} readOnly={readOnly} {...props} />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const eventId = props.match.params.eventId
  const { data, loading, error } = useQuery(GET_EVENT, { variables: { eventId } })
  const [updateEvent] = useMutation(UPDATE_EVENT, {
    refetchQueries: [{ query: GET_EVENT, variables: { eventId } }],
    awaitRefetchQueries: true,
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }
  const getEvent = (data && data.getActivity) || {}

  const readOnly = moment(getEvent.to, 'YYYY-MM-DD HH:mm:ss').isBefore(moment())

  return (
    <EventEditor
      {...props}
      botId={botId}
      event={getEvent}
      updateEvent={updateEvent}
      readOnly={readOnly}
    />
  )
}
