import React from 'react'
import PT from 'prop-types'

import SideMenu from '../SideMenu'
import NumberRenderer from '../../../../components/NumberRenderer'

const TicketTypeList = ({ selected, ticketTypes, onTicketTypeClick }) => {
  const menu = ticketTypes.map(tt => ({
    key: '/' + tt.name,
    title: (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{tt.title}</span>
        <NumberRenderer value={tt.rate} suffix="บาท" />
      </div>
    ),
  }))
  return (
    <div>
      <SideMenu value={'/' + selected} menu={menu} onChange={onTicketTypeClick} />
    </div>
  )
}

TicketTypeList.propTypes = {
  ticketTypes: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      rate: PT.number,
    }),
  ),
  selected: PT.string,
  onChange: PT.func,
}

TicketTypeList.defaultProps = {
  onTicketTypeClick: () => {},
}

export default TicketTypeList
