import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Icon, message, Modal } from 'antd'

import RichMessageForm from './RichMessageForm'
import Loading from '../../../../components/Loading'
import Button from '../../../../components/Button'
import Tutorial from '../../../../components/Tutorial'
import MessagesPreview from '../../../../libs/MessagesPreview'

// for refetch after update only
const ALL_RICHMESSAGE = gql`
  query allRichMessages($botId: String!) {
    allRichMessages(bot_id: $botId) {
      name
      title
      line_messages
      facebook_messages
      actions
      modified
    }
  }
`

const GET_RICHMESSAGE = gql`
  query getRichMessage($richMessageId: String!) {
    getRichMessage(richmsg_id: $richMessageId) {
      name
      title
      facebook_messages
      line_messages
      actions
    }
  }
`

const UPDATE_RICHMESSAGE = gql`
  mutation updateRichMessage($richMessageId: String!, $input: RichMessageInput) {
    updateRichMessage(richmsg_id: $richMessageId, input: $input) {
      name
    }
  }
`

const DELETE_RICHMESSAGE = gql`
  mutation deleteRichMessage($richMessageId: String!) {
    deleteRichMessage(richmsg_id: $richMessageId) {
      name
    }
  }
`

const RichMessageEditor = ({
  history,
  botId,
  richMessage,
  updateRichMessage,
  deleteRichMessage,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [input, setInput] = React.useState({
    ...richMessage,
    name: undefined,
    __typename: undefined,
  })

  React.useEffect(() => {
    setInput({
      ...richMessage,
      name: undefined,
      __typename: undefined,
    })
  }, [richMessage])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const previewMessages = input.line_messages.map((msg, i) => ({
    name: `msg${i}`,
    type: 'box',
    message: msg,
  }))

  const handleSaveBtnClick = async () => {
    setLoading(true)
    const mappedInput = {
      ...input,
      line_messages: [
        {
          ...input.line_messages[0],
          altText: input.title,
        },
      ],
    }
    try {
      await updateRichMessage({
        variables: { richMessageId: richMessage.name, input: mappedInput },
      })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleDeleteBtnClick = () => {
    Modal.confirm({
      title: 'ลบริชเมสเสจนี้ ?',
      okType: 'danger',
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        setLoading(true)
        try {
          await deleteRichMessage({ variables: { richMessageId: richMessage.name } })
          history.push(`/app/bot/${botId}/main/rich-message/list`)
        } catch (err) {
          setLoading(false)
          console.error(err)
        }
      },
      okButtonProps: { loading },
      cancelButtonProps: { loading },
    })
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          padding: '24px 36px 16px',
          borderBottom: '1px solid #DBDBDB',
        }}
      >
        <h1>{richMessage.title}</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            icon={<Icon type="save" />}
            onClick={handleSaveBtnClick}
            loading={loading}
          >
            บันทึก
          </Button>
          <Button type="danger" loading={loading} onClick={handleDeleteBtnClick}>
            ลบริชเมสเสจ
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 129px - 42px)' }}>
        <div
          style={{
            width: '480px',
            borderRight: '1px solid #DBDBDB',
            padding: 36,
            height: '100%',
            overflowY: 'auto',
          }}
          className="smooth-scroll"
        >
          <RichMessageForm loading={loading} data={input} onChange={inputChange} botId={botId} />
        </div>
        <div style={{ width: 'calc(100% - 480px)' }}>
          <MessagesPreview messages={previewMessages} platform="LineBot" />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          slideLink="https://drive.google.com/file/d/14esvmU1Z75DDFhwbeDrbkq5G1Xj_JiAT/view"
          videoLink="https://youtu.be/3H9mhpG8NRs"
        />
      </div>
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const richMessageId = props.match.params.richMessageId

  const { data, error, loading } = useQuery(GET_RICHMESSAGE, { variables: { richMessageId } })
  const [updateRichMessage] = useMutation(UPDATE_RICHMESSAGE, {
    refetchQueries: [
      { query: GET_RICHMESSAGE, variables: { richMessageId } },
      { query: ALL_RICHMESSAGE, variables: { botId } },
    ],
  })
  const [deleteRichMessage] = useMutation(DELETE_RICHMESSAGE, {
    refetchQueries: [{ query: ALL_RICHMESSAGE, variables: { botId: botId } }],
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  const getRichMessage = (data && data.getRichMessage) || {}
  return (
    <RichMessageEditor
      {...props}
      botId={botId}
      richMessage={getRichMessage}
      updateRichMessage={updateRichMessage}
      deleteRichMessage={deleteRichMessage}
    />
  )
}
