import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { Row, Col, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Button from '../../../components/Button'
import ImageRenderer from '../../../components/ImageRenderer'
import bot_templates from '../../../constants/bot_templates'
import { getDataUrl } from '../../../helpers/file'

const Field = styled.div`
  display: flex;
  font-size: 1.3em;
  margin-bottom: 16px;
  .key {
    width: 150px;
    color: rgba(0, 0, 0, 0.35);
  }
`

const CREATE_BOT = gql`
  mutation createBot($input: BotInput!) {
    createBot(input: $input) {
      name
    }
  }
`

const Confirmation = ({ data, onBack, onNext }) => {
  const [previewUrl, setPreview] = React.useState('')

  const [createBot] = useMutation(CREATE_BOT)

  const templates = bot_templates[data.category] || []
  const selectedTemplate = templates.find(t => t.name === data.template)

  const handleConfirm = async () => {
    const closeLoading = message.loading('รอสักครู่..')
    try {
      const input = {
        title: data.title,
        category: data.category,
        business_type: data.business_type,
        image_file: data.image,
      }
      const { data: createBotData } = await createBot({ variables: { input } })
      onNext((createBotData || {}).createBot)
    } catch (err) {
      console.error(err.message)
    }
    closeLoading()
  }

  getDataUrl(data.image, url => setPreview(url))

  return (
    <div>
      <Field>
        <span className="key">ชื่อแชทบอท</span>
        <span>{data.title}</span>
      </Field>
      <Field>
        <span className="key">รูปบอท</span>
        <span>{data.image.name}</span>
      </Field>
      {previewUrl && (
        <ImageRenderer
          style={{ marginBottom: 16, border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 4 }}
          src={previewUrl}
          alt="preview"
          height={300}
        />
      )}
      <Field>
        <span className="key">ประเภทธุรกิจ</span>
        <span>{data.business_type}</span>
      </Field>
      <Field>
        <span className="key">นำไปใช้ใน</span>
        <span>{data.category}</span>
      </Field>
      {selectedTemplate && (
        <>
          <Field>
            <span className="key">เทมเพลต</span>
            <span>{selectedTemplate.title}</span>
          </Field>
          <ImageRenderer
            style={{ marginBottom: 16, border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 4 }}
            src={selectedTemplate.imageUrl}
            alt="template"
            height={240}
          />
        </>
      )}
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <Button size="large" onClick={onBack} type="secondary" block>
            แก้ไขข้อมูล
          </Button>
        </Col>
        <Col span={12}>
          <Button size="large" onClick={handleConfirm} type="primary" block>
            ลงทะเบียน
          </Button>
        </Col>
      </Row>
    </div>
  )
}

Confirmation.propTypes = {
  onNext: PT.func,
  onBack: PT.func,
  data: PT.shape({
    title: PT.string,
    image: PT.object,
    category: PT.string,
    business_type: PT.string,
  }),
}

Confirmation.defaultProps = {
  onNext: () => {},
  onBack: () => {},
}

export default Confirmation
