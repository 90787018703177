import React from 'react'
import { Route } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message } from 'antd'

import KeywordEditor from './KeywordEditor'
import KeywordDiagram from '../../../libs/KeywordDiagram'
import Loading from '../../../components/Loading'
import Tutorial from '../../../components/Tutorial'

const ALL_KEYWORDS = gql`
  query allKeywordWithLinks($botId: String!) {
    allKeywords(bot_id: $botId, source: MAP) {
      name
      title
    }
    allKeywordLinks(bot_id: $botId) {
      name
      from
      to
    }
  }
`

const CREATE_KEYWORD = gql`
  mutation createKeyword($botId: String!, $input: KeywordInput!) {
    createKeyword(bot_id: $botId, input: $input) {
      name
    }
  }
`

const DELETE_KEYWORD = gql`
  mutation deleteKeyword($keywordId: String!) {
    deleteKeyword(keyword_id: $keywordId) {
      name
    }
  }
`

const CREATE_LINK = gql`
  mutation createKeywordLink($botId: String!, $input: KeywordLinkInput!) {
    createKeywordLink(bot_id: $botId, input: $input) {
      name
    }
  }
`

const DELETE_LINK = gql`
  mutation deleteKeywordLink($linkId: String!) {
    deleteKeywordLink(link_id: $linkId) {
      name
    }
  }
`

const AutoReply = ({
  botId,
  history,
  match,
  location,
  keywords,
  links,
  createKeyword,
  createLink,
  deleteKeyword,
  deleteLink,
}) => {
  const selectedKeyword = location.pathname.substr(match.url.length + 1)
  const isSelectedGetStarted =
    (keywords.find(k => k.name === selectedKeyword) || {}).title === 'Get Started'
  const model = {
    nodeDataArray: keywords.map(k => ({
      key: k.name,
      title: k.title,
    })),
    linkDataArray: links.map(l => ({
      name: l.name,
      from: l.from,
      to: l.to,
    })),
  }

  const handleCreateKeyword = async (input, cb) => {
    if (input.title === 'Get Started') {
      message.error('ไม่สามารถสร้างคีย์เวิร์ดชื่อ Get Started ได้')
      return
    }
    try {
      const { data } = await createKeyword({
        variables: { botId, input: { ...input, source: 'MAP', default_sentences: [input.title] } },
      })
      const newKeyword = (data && data.createKeyword) || {}
      await createLink({
        variables: { botId, input: { from: selectedKeyword, to: newKeyword.name } },
      })
      // handleNodeSelect(newKeyword.name)
      cb && cb(newKeyword.name)
      message.success('สร้างคีย์เวิร์ดสำเร็จ')
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeleteKeyword = async keywordId => {
    try {
      let dependLinks = []
      links.forEach(l => {
        if (l.from === keywordId) {
          dependLinks.push(l.name)
        } else if (l.to === keywordId) {
          dependLinks.push(l.name)
        }
      })
      for (let i = 0; i < dependLinks.length; i++) {
        await deleteLink({ variables: { linkId: dependLinks[i] } })
      }
      await deleteKeyword({ variables: { keywordId } })
      handleNodeSelect('')
      message.success('ลบคีย์เวิร์ดสำเร็จ')
    } catch (err) {
      console.error(err)
    }
  }

  const handleNodeSelect = key => {
    const url = key ? `${match.url}/${key}` : match.url
    history.replace(url)
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: 'calc(100% - 42px)', overflowY: 'auto' }}>
        <div style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
          <h1>ข้อความอัตโนมัติ</h1>
        </div>
        <KeywordDiagram
          model={model}
          onNodeSelect={handleNodeSelect}
          onCreateKeyword={(input, cb) => handleCreateKeyword(input, cb)}
          onDeleteKeyword={isSelectedGetStarted ? undefined : id => handleDeleteKeyword(id)}
          defaultSelect={selectedKeyword}
          style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}
        />

        <Route path={`${match.path}/:keywordId`} component={KeywordEditor} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          slideLink="https://drive.google.com/file/d/11I2iAWmCLeZVpxGAYiN7JFcvL2_Zhb6L/view"
          videoLink="https://youtu.be/5QkYNZiU9fs"
        />
      </div>
    </div>
  )
}

export default props => {
  const botId = props.match.params.botId
  const { data, error, loading } = useQuery(ALL_KEYWORDS, { variables: { botId } })
  const [createKeyword] = useMutation(CREATE_KEYWORD)
  const [deleteKeyword] = useMutation(DELETE_KEYWORD, {
    refetchQueries: [{ query: ALL_KEYWORDS, variables: { botId } }],
  })

  const [createLink] = useMutation(CREATE_LINK, {
    refetchQueries: [{ query: ALL_KEYWORDS, variables: { botId } }],
  })
  const [deleteLink] = useMutation(DELETE_LINK)

  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }

  const allKeywords = (data && data.allKeywords) || []
  const allKeywordLinks = (data && data.allKeywordLinks) || []
  return (
    <AutoReply
      {...props}
      botId={botId}
      keywords={allKeywords}
      links={allKeywordLinks}
      createKeyword={createKeyword}
      createLink={createLink}
      deleteKeyword={deleteKeyword}
      deleteLink={deleteLink}
    />
  )
}
