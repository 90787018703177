import React from 'react'
import PT from 'prop-types'

const Field = ({ children, title }) => {
  return (
    <div
      style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}
    >
      <div>
        <span style={{ color: '#B7B7B7' }}>{title}</span>
      </div>
      <div style={{ width: '50%' }}>{children}</div>
    </div>
  )
}

Field.propTypes = {
  children: PT.oneOfType([PT.string, PT.node]),
  title: PT.string,
}

export default Field
