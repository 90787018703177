import BotContainer from '../container/Bot'
import BotList from '../pages/app/BotList'
import BotCreate from '../pages/app/BotCreate'
import Account from '../pages/app/Account'
import Billing from '../pages/app/Billing'
import VAList from '../pages/app/VAList'

export default [
  {
    path: '/va/list',
    page: VAList,
  },
  {
    path: '/bot/list',
    page: BotList,
  },
  {
    path: '/bot/create',
    page: BotCreate,
    hideSidenav: true,
  },
  {
    path: '/bot/:botId',
    page: BotContainer,
    hideSidenav: true,
  },
  {
    path: '/account',
    page: Account,
  },
  {
    path: '/billing',
    page: Billing,
  },
]
