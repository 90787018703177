import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'

import SettingSidenav from '../layouts/sidenav/SettingSidenav'
import settingRoutes from '../routes/settingRoutes'

const SettingContainer = props => {
  const initPath = props.match.path
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div style={{ width: 280, height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
        <SettingSidenav routeProps={props} />
      </div>
      <div
        style={{
          width: 'calc(100% - 280px)',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Switch>
          <Redirect from={initPath} to={initPath + '/bot'} exact />
          {settingRoutes.map(route => (
            <Route key={route.path} path={initPath + route.path} component={route.page} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default SettingContainer
