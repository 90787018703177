import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Icon, Input, Switch, message } from 'antd'

import AIYA_BOT_BLANK from '../../../assets/images/AIYA_Bot_Blank.jpg'
import Button from '../../../components/Button'
import ImageRenderer from '../../../components/ImageRenderer'
import InputField from '../../../components/InputField'
import BotContext from '../../../context/Bot'

// for refetch only
const GET_BOT = gql`
  query getBot($botId: String!) {
    getBot(bot_id: $botId) {
      name
      title
      image
      friend_tags
      friend_num
      modules
      enabled
    }
  }
`

const UPDATE_BOT = gql`
  mutation updateBot($botId: String!, $input: BotInput!) {
    updateBot(bot_id: $botId, input: $input) {
      name
    }
  }
`

const IMAGE_UPLOAD = gql`
  mutation imageUpload($file: Upload!, $botId: String) {
    imageUpload(file: $file, bot_id: $botId) {
      id
      filename
      mimetype
      encoding
      url
    }
  }
`

const BotSetting = ({ bot }) => {
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [updateBot] = useMutation(UPDATE_BOT, {
    refetchQueries: [{ query: GET_BOT, variables: { botId: bot.name } }],
  })

  const inputFileRef = React.createRef()
  const [loading, setLoading] = React.useState(false)
  const [input, setInput] = React.useState({
    title: bot.title,
    enabled: bot.enabled,
    image_url: bot.image,
  })

  const handleImageUpload = async file => {
    if (file.size > 10 * 1024 * 1024) {
      message.error('รูปภาพต้องมีขนาดไม่เกิน 10MB!!')
      return
    }
    setLoading(true)
    try {
      const { data } = await imageUpload({ variables: { file, botId: bot.name } })
      const imageUploadData = (data && data.imageUpload) || {}
      const imageUrl = imageUploadData.url
      inputChange({ image_url: imageUrl })
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleSubmit = async () => {
    if (!input.title) {
      message.error('')
      return
    }
    setLoading(true)
    try {
      await updateBot({ variables: { botId: bot.name, input } })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <div style={{ padding: '24px 36px 16px' }}>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <h1>ตั้งค่าบอท</h1>
      </div>
      <div style={{ maxWidth: 480, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
          <div
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => inputFileRef.current.click()}
          >
            <ImageRenderer
              src={input.image_url}
              alt={bot.title}
              width={240}
              height={240}
              style={{ borderRadius: '50%' }}
              errorImg={AIYA_BOT_BLANK}
            />
            <div
              style={{
                width: 36,
                height: 36,
                borderRadius: '50%',
                backgroundColor: '#b7b7b7',
                color: 'white',
                position: 'absolute',
                bottom: 18,
                right: 18,
              }}
            >
              <Icon
                type="camera"
                style={{
                  fontSize: 24,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </div>
          </div>
          <input
            ref={inputFileRef}
            onChange={e => handleImageUpload(e.target.files[0])}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
          />
        </div>
        <InputField title="Bot ID">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{bot.name}</span>
            {/* <div>
              <span style={{ color: '#B7B7B7', fontSize: '0.9em', marginRight: 8 }}>
                Bot Enabled
              </span>
              <Switch
                checked={input.enabled}
                onChange={val => inputChange({ enabled: val })}
                loading={loading}
              />
            </div> */}
          </div>
        </InputField>
        <InputField title="ชื่อบอท">
          <Input value={input.title} onChange={e => inputChange({ title: e.target.value })} />
        </InputField>
        <Button
          style={{ marginTop: 32 }}
          type="primary"
          block
          onClick={handleSubmit}
          loading={loading}
        >
          บันทึก
        </Button>
        {/* <Button style={{ marginTop: 8 }} type="danger" ghost block loading={loading}>
          ลบบอท
        </Button> */}
      </div>
    </div>
  )
}

export default props => {
  const bot = React.useContext(BotContext)
  return <BotSetting {...props} bot={bot} />
}
