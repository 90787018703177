import React from 'react'
import PT from 'prop-types'
import { MessageRender } from '@megenius/aiya-chat-ui'
import { Icon } from 'antd'

const MessagesPreview = ({ messages, platform, loading, errorMsg }) => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: 'rgba(103, 111, 132, 1)',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {loading ? (
        <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
          <Icon
            type="loading"
            style={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      ) : messages.length ? (
        <div style={{ padding: '16px 32px' }}>
          {messages.map(msg => (
            <div key={msg.name} style={{ marginBottom: 8 }}>
              <MessageRender
                channel={platform === 'LineBot' ? 'line' : 'facebook'}
                data={msg}
                align="left"
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            color: 'white',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i>{errorMsg || 'ไม่สามารแสดงข้อความได้ กรุณาตรวจสอบข้อความของท่านอีกครั้ง'}</i>
        </div>
      )}
    </div>
  )
}

MessagesPreview.propTypes = {
  messages: PT.arrayOf(PT.object),
  platform: PT.oneOf(['LineBot', 'FacebookBot']),
  errorMsg: PT.string,
  loading: PT.bool,
}

MessagesPreview.defaultProps = {
  messages: [],
}

export default MessagesPreview
