import React from 'react'
import PT from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'

import NoData from '../../../../components/NoData'

const BroadcastItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: #ecf7ff;
  }
`

const BroadcastListCard = ({ style, title, data, onItemClick }) => {
  return (
    <div
      style={{
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        height: '100%',
        minHeight: 0,
        ...style,
      }}
    >
      <h4
        style={{ marginBottom: 0, padding: '12px', borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}
      >
        {title}
      </h4>
      <div style={{ position: 'relative', height: 'calc(100% - 46px)', overflowY: 'auto' }}>
        {data.length ? (
          data.map(b => (
            <BroadcastItem key={b.id} onClick={() => onItemClick(b.id)}>
              <span>{b.title}</span>
              <span>
                {b.schedule
                  ? moment(b.schedule, 'YYYY-MM-DD HH:mm:ss').format('HH:mm DD-MMM-YYYY')
                  : '-'}
              </span>
            </BroadcastItem>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

BroadcastListCard.propTypes = {
  title: PT.oneOfType([PT.string, PT.node]),
  style: PT.object,
  data: PT.arrayOf(
    PT.shape({
      id: PT.string,
      title: PT.string,
      schedule: PT.string,
    }),
  ),
  onItemClick: PT.func,
}

BroadcastListCard.defaultProps = {
  data: [],
  style: {},
  onItemClick: () => {},
}

export default BroadcastListCard
