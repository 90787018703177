import React from 'react'
import PT from 'prop-types'
import { Input, Icon, message } from 'antd'

import ActionsEditor from './ActionsEditor'
import InputField from '../../../../../components/InputField'
import InputImage from '../../../../../components/InputImage'
import { getImageResolution } from '../../../../../helpers/file'

const RichMessageForm = ({ botId, data, onChange, loading }) => {
  const handleImageChange = async url => {
    const size = await getImageResolution(url)
    if (size.width !== 1040) {
      message.error('รูปจำเป็นต้องมีความกว้าง 1,040 pixels !!')
      return
    }
    const imagemap = {
      type: 'imagemap',
      baseUrl: url + '?=ignore',
      altText: 'This is an imagemap',
      baseSize: {
        width: size.width,
        height: size.height,
      },
      actions: [],
    }
    onChange({
      line_messages: [imagemap],
      actions: { ...data.actions, imageUrl: url, baseSize: size },
    })
  }

  const handleActionsChange = (actions, _data) => {
    const imagemap = data.line_messages.length && data.line_messages[0]
    const newImagemap = { ...imagemap, actions }
    onChange({ line_messages: [newImagemap], actions: { ...data.actions, ..._data } })
  }

  return (
    <div>
      <InputField title="ชื่อริชเมสเสจ">
        <Input
          value={data.title}
          onChange={e => onChange({ title: e.target.value })}
          maxLength={140}
          disabled={loading}
        />
        <div
          style={{
            color: '#B7B7B7',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 4,
          }}
        >
          <small>ชื่อจะถูกแสดงในการแจ้งเตือนแบบ push และรายชื่อแชท</small>
          <small>{data.title.length} / 140</small>
        </div>
      </InputField>
      <div style={{ borderBottom: '1px solid #dbdbdb', marginBottom: 8 }}>
        <div style={{ borderBottom: '1px solid #dbdbdb', marginBottom: 16 }}>ข้อความ</div>
        <InputField title="รูปภาพ">
          <small style={{ color: '#b7b7b7', marginBottom: 4 }}>
            <Icon type="info-circle" theme="filled" style={{ marginRight: 4 }} />
            <span>รูปภาพ .jpg หรือ .png ที่มีความกว้าง 1,040 px</span>
          </small>
          <InputImage
            loading={loading}
            botId={botId}
            value={data.actions.imageUrl}
            onChange={handleImageChange}
            btnProps={{ block: true }}
          />
        </InputField>
      </div>
      {data.actions.imageUrl && (
        <ActionsEditor
          initial={{ grid: data.actions.grid, actions: data.actions._actions }}
          baseSize={data.actions.baseSize}
          onChange={handleActionsChange}
          loading={loading}
        />
      )}
    </div>
  )
}

RichMessageForm.propTypes = {
  botId: PT.string,
  data: PT.shape({
    title: PT.string,
    facebook_messages: PT.arrayOf(PT.object),
    line_messages: PT.arrayOf(PT.object),
    actions: PT.object,
  }),
  onChange: PT.func,
  loading: PT.bool,
}

RichMessageForm.defaultProps = {
  onChange: () => {},
}

export default RichMessageForm
