import React from 'react'

import Loading from '../../components/Loading'
import { queryStrToObj } from '../../helpers/queryStr'

const Portal = ({ location, history }) => {
  const params = queryStrToObj(location.search)

  React.useEffect(() => {
    localStorage.setItem('access_token', params.tk)
    localStorage.setItem('botId', params.botId)
    setTimeout(() => {
      // only for richmenu redirect
      history.replace(`/app/bot/${params.botId}/main/richmenu`)
    }, 3000)
  })

  return <Loading />
}

export default Portal
