import React from 'react'
import PT from 'prop-types'
import { Card } from 'antd'

import NO_IMG from '../../../../assets/images/no_img.png'
import Fb from '../../../../services/_facebookClient'

const FbPageCard = ({ page, onClick }) => {
  const [loading, setLoading] = React.useState(true)
  const [image, setImage] = React.useState('')
  React.useEffect(() => {
    Fb.getPagePicture(page.id, res => {
      if (res.error) {
        console.error(res.error.message)
        return
      }
      setImage(res.data.url)
      setLoading(false)
    })
  }, [page])

  return (
    <Card
      hoverable
      loading={loading}
      onClick={() => onClick()}
      style={{ marginRight: 8, marginBottom: 8, width: 240 }}
      cover={
        !loading && (
          <img width={240} height={240} sizes="cover" src={image || NO_IMG} alt="channel" />
        )
      }
    >
      <Card.Meta
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h4>{page.name}</h4>
          </div>
        }
      />
    </Card>
  )
}

FbPageCard.propTypes = {
  page: PT.object,
  onClick: PT.func,
}

FbPageCard.defaultProps = {
  onClick: () => {},
}

export default FbPageCard
