import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'

const MainSidenav = ({ routeProps }) => {
  const initUrl = routeProps.match.url

  return (
    <div style={{ padding: 36, display: 'flex', flexDirection: 'column' }}>
      {/* <NavLink to={initUrl + '/dashboard'} className="sidenav-item" activeClassName="active">
        แดชบอร์ด
      </NavLink> */}
      <NavLink to={initUrl + '/broadcast/list'} className="sidenav-item" activeClassName="active">
        บรอดแคสต์
      </NavLink>
      <NavLink to={initUrl + '/autoreply'} className="sidenav-item" activeClassName="active">
        ข้อความอัตโนมัติ
      </NavLink>
      {/* <NavLink
        to={initUrl + '/rich-message/list'}
        className="sidenav-item"
        activeClassName="active"
      >
        ริชเมสเสจ
      </NavLink> */}
      {/* <NavLink to={initUrl + '/richmenu/list'} className="sidenav-item" activeClassName="active">
        ริชเมนู
      </NavLink> */}
    </div>
  )
}

MainSidenav.propTypes = {
  routeProps: PT.object.isRequired,
}

export default MainSidenav
