import React from 'react'
import { Input, Icon } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import RichMessageTable from './RichMessageTable'
import Button from '../../../../components/Button'
import Tutorial from '../../../../components/Tutorial'

const ALL_RICHMESSAGE = gql`
  query allRichMessages($botId: String!) {
    allRichMessages(bot_id: $botId) {
      name
      title
      line_messages
      facebook_messages
      actions
      modified
    }
  }
`

const CREATE_RICHMESSAGE = gql`
  mutation createRichMessage($botId: String!) {
    createRichMessage(bot_id: $botId) {
      name
    }
  }
`

const RichMessageList = ({ history, match }) => {
  const botId = match.params.botId
  const [loadingState, setLoading] = React.useState(false)

  const { data, error, loading } = useQuery(ALL_RICHMESSAGE, { variables: { botId: botId } })
  const [createRichMessage] = useMutation(CREATE_RICHMESSAGE, {
    refetchQueries: [{ query: ALL_RICHMESSAGE, variables: { botId: botId } }],
  })
  if (error) {
    console.error(error)
  }

  const allRichMessages = (data && data.allRichMessages) || []

  const openEditor = richMessageId => {
    history.push(`/app/bot/${botId}/main/rich-message/${richMessageId}`)
  }

  const handleCreateClick = async () => {
    try {
      setLoading(true)
      const { data: createRichMessageData } = await createRichMessage({
        variables: { botId: botId },
      })
      const newRichMessage =
        (createRichMessageData && createRichMessageData.createRichMessage) || {}
      setLoading(false)
      history.push(`/app/bot/${botId}/main/rich-message/${newRichMessage.name}`)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const tableDataSource = allRichMessages.map(rm => ({
    name: rm.name,
    title: rm.title,
    imageUrl: rm.actions.imageUrl,
    modified: rm.modified,
    hits: 0,
  }))

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '24px 36px',
        }}
      >
        <div>
          <h1 style={{ marginBottom: 4 }}>ริชเมสเสจ</h1>
          <span>ข้อความแบบรูปภาพที่ดึงความสนใจจากลูกค้าได้เป็นอย่างดี</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Input.Search
            placeholder="ค้นหาข้อความ.."
            style={{ width: 180, marginRight: 8 }}
            disabled={loadingState}
          />
          <Button
            onClick={handleCreateClick}
            type="primary"
            ghost
            icon={<Icon type="plus" />}
            loading={loadingState}
          >
            ข้อความใหม่
          </Button>
        </div>
      </div>
      <RichMessageTable
        dataSource={tableDataSource}
        height={window.innerHeight - 60 - 48 - 115 - 54 - 42}
        loading={loading || loadingState}
        openEditor={id => openEditor(id)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          slideLink="https://drive.google.com/file/d/14esvmU1Z75DDFhwbeDrbkq5G1Xj_JiAT/view"
          videoLink="https://youtu.be/3H9mhpG8NRs"
        />
      </div>
    </div>
  )
}

export default RichMessageList
