import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.min.css'
import 'animate.css'
import './index.css'
import App from './App'
import * as serviceWorker from './services/serviceWorker'

window.go.licenseKey =
  '73f041e7b31c28c702d90776423d6bf919a52e63ce8549a30e0040f6eb086b06329fbb2b03d38f9381ac4dff1f7dc3d188c06a79c4480c3db331d7db42e086aeb73320e5410b479cb40573939ffa78f1fd6a61f1c3b57fbddf678cf4'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
