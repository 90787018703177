import React from 'react'
import PT from 'prop-types'
import { Input, DatePicker, TimePicker, Switch, Icon } from 'antd'
import moment from 'moment'

import InputField from '../../../../components/InputField'
import InputImage from '../../../../components/InputImage'

const RichmenuForm = ({ botId, loading, input, inputChange }) => {
  const configChange = item => {
    const payload = {
      ...input.payload,
      input: { ...input.payload.input, ...item },
    }
    inputChange({ ...input, payload: { ...payload } })
  }

  const handleDateChange = date => {
    const curr = input.schedule ? moment(input.schedule, 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: date.year(),
      M: date.month(),
      d: date.date(),
      h: curr.hour(),
      m: curr.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ schedule: tmp.format('DD-MM-YYYY HH:mm:ss') })
  }

  const handleTimeChange = time => {
    const curr = input.schedule ? moment(input.schedule, 'YYYY-MM-DD HH:mm:ss') : moment()
    const tmp = moment({
      y: curr.year(),
      M: curr.month(),
      d: curr.date(),
      h: time.hour(),
      m: time.minute(),
      s: 0,
      ms: 0,
    })
    inputChange({ schedule: tmp.format('YYYY-MM-DD HH:mm:ss') })
  }

  const config = (input.payload || {}).input || {}
  return (
    <div>
      <InputField title="ชื่อริชเมนู">
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="การใช้งาน">
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <div style={{ width: 91, color: '#b7b7b7' }}>ตั้งเวลา</div>
          <DatePicker
            value={input.schedule ? moment(input.schedule, 'YYYY-MM-DD HH:mm:ss') : undefined}
            placeholder="DD / MM / YYYY"
            format="DD / MM / YYYY"
            style={{ width: 180, marginRight: 8 }}
            onChange={date => handleDateChange(date)}
            disabled={loading}
          />
          <TimePicker
            value={input.schedule ? moment(input.schedule, 'YYYY-MM-DD HH:mm:ss') : undefined}
            format="HH : mm"
            onChange={time => handleTimeChange(time)}
            placeholder="HH : mm"
            disabled={loading}
          />
        </div>
      </InputField>
      <InputField title="ข้อความบนเมนูบาร์">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Input
            style={{ width: 'calc(100% - 60px)' }}
            disabled={loading}
            value={config.chatBarText}
            onChange={e => configChange({ chatBarText: e.target.value })}
            maxLength={14}
          />
          <div style={{ width: 52 }}>{(config.chatBarText || '').length} / 14</div>
        </div>
      </InputField>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <span style={{ fontWeight: 500, color: 'rgba(136, 136, 136)' }}>
          การแสดงเมนูแบบเริ่มต้น
        </span>
        <div>
          <span style={{ color: '#1C70B0', marginRight: 4 }}>แสดง</span>
          <Switch
            checked={config.selected}
            loading={loading}
            onChange={e => configChange({ selected: e })}
          />
        </div>
      </div>
      <InputField title="รูปภาพ" isRequired>
        <small style={{ display: 'flex', alignItems: 'center', marginBottom: 8, color: '#B7B7B7' }}>
          <Icon type="info-circle" style={{ marginRight: 4 }} />
          <span>รูปภาพ .png หรือ .jpg และมีขนาดน้อยกว่า 1 MB</span>
        </small>
        <InputImage
          botId={botId}
          value={input.image_url}
          onChange={url => inputChange({ image_url: url })}
          loading={loading}
          maxSize={1024 * 1024}
        />
      </InputField>
    </div>
  )
}

RichmenuForm.propTypes = {
  input: PT.shape({
    title: PT.string,
    schedule: PT.string,
    payload: PT.shape({
      templateSize: PT.shape({
        width: PT.number,
        height: PT.number,
        size: PT.number,
      }),
      actions: PT.arrayOf(
        PT.shape({
          key: PT.number,
          type: PT.oneOf(['No Action', 'Message', 'Link']),
          data: PT.string,
        }),
      ),
      input: PT.shape({
        selected: PT.bool,
        chatBarText: PT.string,
      }),
    }),
    line_payload: PT.object,
    image_url: PT.string,
  }),
  inputChange: PT.func,
  botId: PT.string,
  loading: PT.bool,
}

export default RichmenuForm
