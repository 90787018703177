import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const SideItem = styled.div`
  cursor: pointer;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  ${({ selected }) =>
    selected &&
    `
    color: white;
    background-color: #1C70B0;
    font-weight: 600;
  `}
`

const LeadsSideMenu = ({ selected, onSelect }) => {
  return (
    <div>
      <SideItem selected={selected === 'friendInfo'} onClick={() => onSelect('friendInfo')}>
        ข้อมูลเพื่อน
      </SideItem>
      <SideItem selected={selected === 'leadsInfo'} onClick={() => onSelect('leadsInfo')}>
        ข้อมูล Leads
      </SideItem>
      <SideItem selected={selected === 'notes'} onClick={() => onSelect('notes')}>
        โน๊ต
      </SideItem>
    </div>
  )
}

LeadsSideMenu.propTypes = {
  selected: PT.oneOf(['friendInfo', 'leadsInfo', 'notes']),
  onSelect: PT.func,
}

LeadsSideMenu.defaultProps = {
  onSelect: () => {},
}

export default LeadsSideMenu
