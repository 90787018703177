import React from 'react'
import PT from 'prop-types'
import { Icon } from 'antd'

import Button from '../../../components/Button'

const SocialConnector = ({ social }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span>{social}</span>
      <div>
        <Button type="primary">
          <Icon theme="filled" type="setting" />
        </Button>
      </div>
    </div>
  )
}

SocialConnector.propTypes = {
  social: PT.oneOf(['email', 'facebook', 'line']),
}

export default SocialConnector
