import Admin from '../pages/setting/Admin'
import Channel from '../pages/setting/Channel'
import BotSetting from '../pages/setting/Bot'
// import Package from '../pages/setting/Package'

export default [
  {
    path: '/channel',
    page: Channel,
  },
  {
    path: '/bot',
    page: BotSetting,
  },
  // {
  //   path: '/package',
  //   page: Package,
  // },
  {
    path: '/permission',
    page: Admin,
  },
]
