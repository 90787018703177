import React from 'react'
import { Input, message, Tooltip, Icon } from 'antd'

import InputField from '../../../../../components/InputField'

const LineBotForm = ({ chId, input, inputChange, loading }) => {
  const webhookRef = React.createRef()
  const webhookLink = `https://aiya.ai/webhook/line/${chId}`

  const copyInviteLink = () => {
    webhookRef.current.select()
    document.execCommand('copy')
    message.success('คัดลอกแล้ว')
  }

  return (
    <div>
      <InputField title="Webhook">
        <Input
          ref={webhookRef}
          value={webhookLink}
          addonAfter={
            document.queryCommandSupported('copy') && (
              <Tooltip title="คัดลอก">
                <Icon onClick={copyInviteLink} style={{ cursor: 'pointer' }} type="copy" />
              </Tooltip>
            )
          }
          readOnly
        />
      </InputField>
      <InputField title="ชื่อ Channel">
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Channel ID">
        <Input
          value={input.id}
          onChange={e => inputChange({ id: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Channel Secret">
        <Input
          value={input.channel_secret}
          onChange={e => inputChange({ channel_secret: e.target.value })}
          disabled={loading}
        />
      </InputField>
      <InputField title="Access Token">
        <Input.TextArea
          value={input.access_token}
          onChange={e => inputChange({ access_token: e.target.value })}
          autoSize={{ minRows: 4 }}
          disabled={loading}
        />
      </InputField>
    </div>
  )
}

export default LineBotForm
