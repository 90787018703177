import React from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { message } from 'antd'

import AudienceForm from './AudienceForm'
import AudienceInfo from './AudienceInfo'
import AudienceMenuTab from './AudienceMenuTab'
import Loading from '../../../components/Loading'
import Tutorial from '../../../components/Tutorial'

const GET_FRIEND = gql`
  query getFriend($botId: String!, $uuid: String!) {
    getFriend(bot_id: $botId, uuid: $uuid) {
      id
      full_name
      picture_url
      tags
      rating
      social_id
      platform
      about
      bc_data
    }
  }
`

const UPDATE_FRIEND = gql`
  mutation updateFriend($botId: String!, $uuid: String!, $input: FriendInput!) {
    updateFriend(bot_id: $botId, uuid: $uuid, input: $input)
  }
`

const RELOAD_DATA = gql`
  mutation reloadFriendData($uuid: String!, $botId: String) {
    reloadFriendData(uuid: $uuid, bot_id: $botId)
  }
`

const SUB_FRIEND_DOC = gql`
  subscription onFriendChanged($botId: String!, $uuid: String) {
    onFriendChanged(bot_id: $botId, doc_id: $uuid) {
      id
      doctype
      op
    }
  }
`

const AudienceDetail = ({ match }) => {
  const botId = match.params.botId
  const uuid = match.params.uuid

  const [currMenu, menuChange] = React.useState('editor')
  const [loading, setLoading] = React.useState(false)

  const { data, error, loading: queryLoading, subscribeToMore } = useQuery(GET_FRIEND, {
    variables: { botId, uuid },
  })
  const [updateFriend] = useMutation(UPDATE_FRIEND, {
    refetchQueries: [{ query: GET_FRIEND, variables: { botId, uuid } }],
  })
  const [reloadData] = useMutation(RELOAD_DATA, {
    refetchQueries: [{ query: GET_FRIEND, variables: { botId, uuid } }],
  })
  const [getFriend] = useLazyQuery(GET_FRIEND, { fetchPolicy: 'network-only' })

  React.useEffect(() => {
    let unsub = () => {}
    const subscribeFriendDoc = () => {
      unsub = subscribeToMore({
        document: SUB_FRIEND_DOC,
        variables: { botId: botId },
        updateQuery: async (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }
          try {
            getFriend({
              variables: { botId: botId, uuid: subscriptionData.data.onFriendChanged.id },
            })
            return prev
          } catch (err) {
            console.error(err)
            return prev
          }
        },
      })
    }

    subscribeFriendDoc()

    return () => {
      unsub()
    }
  }, [botId, subscribeToMore, getFriend])

  if (queryLoading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
  }

  const friendData = (data && data.getFriend) || {}

  const handleFormSubmit = async input => {
    setLoading(true)
    try {
      await updateFriend({ variables: { botId, uuid, input } })
      message.success('บันทึกสำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleReloadData = async () => {
    setLoading(true)
    try {
      await reloadData({ variables: { uuid, botId } })
      message.success('โหลดโปรไฟล์สำเร็จ')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const colorTheme = friendData.platform === 'LineBot' ? '#32B159' : '#1480FF'

  const menus = [
    {
      key: 'editor',
      title: 'แก้ไขข้อมูล',
    },
    {
      key: 'submission',
      title: 'ประวัติการกรอกแบบสอบถาม',
    },
  ]

  return (
    <div style={{ height: '100%' }}>
      {/* <div style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}>
        <h1>จัดการเพื่อน</h1>
      </div> */}
      <div style={{ height: '100%' }}>
        <div
          style={{
            height: '100%',
            minWidth: 520,
            maxWidth: 640,
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <AudienceInfo data={friendData} colorTheme={colorTheme} />
          <AudienceMenuTab
            colorTheme={colorTheme}
            menus={menus}
            selected={currMenu}
            onSelect={menuChange}
          />
          <div style={{ height: 'calc(100% - 242px)', overflowY: 'auto' }}>
            <AudienceForm data={friendData} onSubmit={handleFormSubmit} loading={loading} />
          </div>
        </div>
      </div>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '42px',
          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          padding: '0 48px',
          backgroundColor: '#206EB0',
        }}
      >
        <Tutorial
          videoLink="https://youtu.be/FuKrsQtJIK0"
          slideLink="https://drive.google.com/file/d/1K2q84d6Uwh-nK0R2kaLcATJKHnN141Yk/view"
        />
      </div> */}
    </div>
  )
}

export default AudienceDetail
