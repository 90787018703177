import React from 'react'
import { Icon, message } from 'antd'
import PT from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Switch, Route, withRouter } from 'react-router-dom'

import CreateTicketTypeForm from './CreateTicketTypeForm'
import EditTicketTypeForm from './EditTicketTypeForm'
import TicketTypeList from './TicketTypeList'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'

const ALL_TICKET_TYPE = gql`
  query allTicketTypes($eventId: String!) {
    allTicketTypes(activity_id: $eventId) {
      name
      title
      from
      to
      qty
      rate
    }
  }
`

const CREATE_TICKET_TYPE = gql`
  mutation createTicketType($eventId: String!, $input: TicketTypeInput!) {
    createTicketType(activity_id: $eventId, input: $input) {
      name
    }
  }
`

const UPDATE_TICKET_TYPE = gql`
  mutation updateTicketType($ticketTypeId: String!, $input: TicketTypeInput!) {
    updateTicketType(tt_id: $ticketTypeId, input: $input) {
      name
    }
  }
`

const DELETE_TICKET_TYPE = gql`
  mutation deleteTicketType($ticketTypeId: String!) {
    deleteTicketType(tt_id: $ticketTypeId) {
      name
    }
  }
`

const TicketTypeEditor = ({ eventId, match, history, readOnly }) => {
  const [loading, setLoading] = React.useState(false)
  const { data, error, loading: loadQuery } = useQuery(ALL_TICKET_TYPE, { variables: { eventId } })
  const [createTicketType] = useMutation(CREATE_TICKET_TYPE, {
    refetchQueries: [{ query: ALL_TICKET_TYPE, variables: { eventId } }],
    awaitRefetchQueries: true,
  })
  const [updateTicketType] = useMutation(UPDATE_TICKET_TYPE, {
    refetchQueries: [{ query: ALL_TICKET_TYPE, variables: { eventId } }],
    awaitRefetchQueries: true,
  })
  const [deleteTicketType] = useMutation(DELETE_TICKET_TYPE, {
    refetchQueries: [{ query: ALL_TICKET_TYPE, variables: { eventId } }],
    awaitRefetchQueries: true,
  })
  const selectedTicketType = window.location.pathname.split('/')[8]

  if (loadQuery) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }
  const allTicketTypes = (data && data.allTicketTypes) || []

  const openTicketTypeCreateForm = () => {
    history.replace(match.url + '/create')
  }

  const openTicketTypeEditForm = key => {
    history.replace(match.url + key)
  }

  const closeTicketTypeForm = () => {
    history.replace(match.url)
  }

  const handleCreateTicketType = async input => {
    try {
      setLoading(true)
      const { data } = await createTicketType({ variables: { eventId, input } })
      message.success('เพิ่มตั๋ว')
      const newTicketType = data && data.createTicketType
      openTicketTypeEditForm('/' + newTicketType.name)
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleUpdateTicketType = async (input, ticketTypeId) => {
    try {
      setLoading(true)
      await updateTicketType({ variables: { ticketTypeId, input } })
      message.success('บันทึก')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const handleDeleteTicketType = async ticketTypeId => {
    try {
      setLoading(true)
      await deleteTicketType({ variables: { ticketTypeId } })
      message.success('ลบตั๋ว')
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <div style={{ width: 420, height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <div style={{ padding: '8px 36px 6px', borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
          <h2 style={{ marginBottom: 0, color: '#1c70b0', fontWeight: 400 }}>บัตรเข้างาน</h2>
        </div>
        <TicketTypeList
          selected={selectedTicketType}
          ticketTypes={allTicketTypes}
          onTicketTypeClick={openTicketTypeEditForm}
        />
        {!readOnly && (
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              ghost
              style={{ margin: '16px' }}
              icon={<Icon type="plus" />}
              onClick={() => openTicketTypeCreateForm()}
              loading={loading}
            >
              สร้างบัตรเข้างาน
            </Button>
          </div>
        )}
      </div>
      <div style={{ width: 'calc(100% - 420px)', height: '100%', overflowY: 'auto' }}>
        <Switch>
          <Route
            path={match.path + '/create'}
            render={() => (
              <CreateTicketTypeForm
                readOnly={readOnly}
                closeForm={closeTicketTypeForm}
                onSubmit={handleCreateTicketType}
                loading={loading}
              />
            )}
          />
          <Route
            path={match.path + '/:ticketTypeId'}
            render={props => (
              <EditTicketTypeForm
                data={allTicketTypes.find(tt => tt.name === props.match.params.ticketTypeId)}
                onSubmit={handleUpdateTicketType}
                onDelete={handleDeleteTicketType}
                loading={loading}
                readOnly={readOnly}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  )
}

TicketTypeEditor.propTypes = {
  eventId: PT.string,
  readOnly: PT.bool,
}

export default withRouter(TicketTypeEditor)
