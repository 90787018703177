import React from 'react'
import PT from 'prop-types'
import { Input, Select, Radio, DatePicker, TimePicker } from 'antd'
import moment from 'moment'

import InputField from '../../../components/InputField'

const CampaignConfigForm = ({ allChannels, allTags, config, configChange, readOnly }) => {
  const [sendMode, modeChange] = React.useState(config.is_now ? 'now' : 'schedule')

  const handleModeChange = mode => {
    if (mode === 'now') {
      configChange({ schedule: undefined, is_now: true })
    } else {
      configChange({ is_now: false })
    }
    modeChange(mode)
  }

  const handleDateChange = date => {
    const curr = config.schedule ? config.schedule : moment()
    const tmp = moment({
      y: date.year(),
      M: date.month(),
      d: date.date(),
      h: curr.hour(),
      m: curr.minute(),
      s: 0,
      ms: 0,
    })
    configChange({ schedule: tmp })
  }

  const handleTimeChange = time => {
    const curr = config.schedule ? config.schedule : moment()
    const tmp = moment({
      y: curr.year(),
      M: curr.month(),
      d: curr.date(),
      h: time.hour(),
      m: time.minute(),
      s: 0,
      ms: 0,
    })
    configChange({ schedule: tmp })
  }

  return (
    <div>
      <InputField title="กลุ่มผู้รับ" isRequired>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="เลือกตามแท็ก.."
          value={config.tags}
          onChange={tags => configChange({ tags })}
          disabled={readOnly}
        >
          {allTags.map(t => (
            <Select.Option key={t}>{t}</Select.Option>
          ))}
        </Select>
      </InputField>
      <InputField title="ชื่อหัวข้อแคมเปญ" isRequired>
        <Input
          value={config.title}
          onChange={e => configChange({ title: e.target.value })}
          readOnly={readOnly}
        />
      </InputField>
      <InputField title="ช่องทางการส่งแคมเปญ" isRequired>
        <Select
          style={{ width: '100%' }}
          placeholder="เลือกช่องทาง"
          value={config.channel}
          onChange={key => configChange({ channel: key })}
          disabled={readOnly}
        >
          {allChannels.map(ch => (
            <Select.Option key={ch.name}>{ch.title}</Select.Option>
          ))}
        </Select>
      </InputField>
      <InputField title="เวลาส่งแคมเปญ" isRequired>
        <Radio.Group
          value={sendMode}
          onChange={e => handleModeChange(e.target.value)}
          disabled={readOnly}
        >
          <div>
            <Radio value="now">ส่งทันที</Radio>
          </div>
          <div>
            <Radio value="schedule">
              <span style={{ marginRight: 4 }}>ตั้งวันที่</span>
              <DatePicker
                placeholder="DD-MM-YYYY"
                style={{ width: 150, marginRight: 12 }}
                disabled={sendMode === 'now'}
                format="DD-MM-YYYY"
                value={config.schedule}
                onChange={handleDateChange}
              />
              <span style={{ marginRight: 4 }}>ตั้งเวลา</span>
              <TimePicker
                placeholder="HH:mm"
                style={{ width: 120 }}
                disabled={sendMode === 'now'}
                format="HH:mm"
                value={config.schedule}
                onChange={handleTimeChange}
              />
            </Radio>
          </div>
        </Radio.Group>
      </InputField>
    </div>
  )
}

CampaignConfigForm.propTypes = {
  allTags: PT.arrayOf(PT.string),
  allChannels: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
    }),
  ),
  config: PT.shape({
    title: PT.string,
    tags: PT.arrayOf(PT.string),
    schedule: PT.object,
    channel: PT.string,
  }),
  configChange: PT.func,
  readOnly: PT.bool,
}

export default CampaignConfigForm
