import React from 'react'
import PT from 'prop-types'
import { Input, InputNumber, Select } from 'antd'

import Button from '../../../../components/Button'

const PromoCodeForm = ({ data, onSave, onDelete }) => {
  const [input, setInput] = React.useState({
    ...data,
    type: 'FIX',
    name: undefined,
    __typename: undefined,
  })

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  return (
    <div
      style={{
        marginBottom: 8,
        padding: 12,
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ width: 80 }}>โปรโมโค้ด</span>
        <Input
          value={input.title}
          onChange={e => inputChange({ title: e.target.value.toUpperCase() })}
          style={{ width: 'calc(100% - 80px)' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
        <span style={{ width: 80 }}>ส่วนลด</span>
        <InputNumber
          value={input.discount}
          onChange={val => inputChange({ discount: val })}
          style={{ marginRight: 4, width: 'calc(100% - 184px)' }}
        />
        <Select
          value={input.type}
          onChange={val => inputChange({ type: val })}
          style={{ width: 100 }}
        >
          <Select.Option key="FIX">บาท</Select.Option>
          <Select.Option key="PERCENT">เปอร์เซนต์</Select.Option>
        </Select>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
        <span style={{ width: 80 }}>จำนวนโค้ด</span>
        <InputNumber
          value={input.max_use}
          onChange={val => inputChange({ max_use: val })}
          style={{ width: 'calc(100% - 80px)' }}
        />
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 8 }}
      >
        <Button style={{ marginRight: 8 }} type="danger" onClick={() => onDelete(data.title)}>
          ลบ
        </Button>
        <Button type="primary" onClick={() => onSave(input)}>
          บันทึก
        </Button>
      </div>
    </div>
  )
}

PromoCodeForm.propTypes = {
  data: PT.shape({
    name: PT.string,
    title: PT.string,
    type: PT.oneOf(['FIX', 'PERCENT', 'PRIVATE']),
    discount: PT.number,
    max_use: PT.number,
  }),
  onSave: PT.func,
  onDelete: PT.func,
}

PromoCodeForm.defaultProps = {
  data: {},
  onSave: () => {},
  onDelete: () => {},
}

export default PromoCodeForm
