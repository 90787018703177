import React from 'react'
import PT from 'prop-types'
import { Progress, Icon, Modal, Input, Select, Dropdown, Menu } from 'antd'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'

import AgentItem from './AgentItem'
import { ItemTypes } from './dndConstants'
import { PT_AGENT_GROUP, PT_AGENT } from '../propTypes'
import NumberRenderer from '../../../components/NumberRenderer'

const IconBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${({ active }) => active && 'background-color: rgba(0, 0, 0, 0.05)'};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const GroupItem = ({
  allSegments,
  allAgents,
  data,
  color,
  onDeleteAgent,
  onDrop,
  onSegmentsChange,
  onChange,
  onDelete,
}) => {
  const { agents = [], segments = [] } = data
  const [input, setInput] = React.useState(segments.map(s => s.name))
  const [titleInput, titleChange] = React.useState(data.title)
  const [modalState, setModal] = React.useState(false)
  const [menuState, setMenuState] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [{ isOver }, dropRef] = useDrop({
    accept: ItemTypes.AGENT,
    drop: () => ({ dropTarget: data.name }),
    collect: mon => ({
      isOver: !!mon.isOver(),
    }),
  })

  const handleSubmit = () => {
    onChange({ title: titleInput })
    setEditing(false)
  }

  const menu = (
    <Menu onClick={() => setMenuState(false)}>
      <Menu.Item onClick={() => setEditing(true)}>
        <Icon type="edit" style={{ marginRight: 4 }} />
        <span>แก้ไขชื่อกลุ่ม</span>
      </Menu.Item>
      <Menu.Item onClick={() => onDelete()} style={{ color: '#FF5C5C' }}>
        <Icon type="delete" style={{ marginRight: 4 }} />
        <span>ลบกลุ่ม</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div
      style={{
        minWidth: 220,
        textAlign: 'center',
        border: '1px solid #DBDBDB',
        borderRadius: 4,
        padding: '16px 8px 8px',
        marginLeft: 16,
        marginBottom: 16,
      }}
      ref={dropRef}
    >
      <div style={{ position: 'relative', marginBottom: 16 }}>
        <Progress
          type="circle"
          showInfo={false}
          strokeColor={color}
          percent={(agents.length / allAgents.length) * 100}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div style={{ fontWeight: 500, fontSize: '1.6em', lineHeight: '0.6em' }}>
            <NumberRenderer value={agents.length} />
          </div>
          <div style={{ fontSize: '1.4em' }}>คน</div>
        </div>
      </div>
      <div style={{ marginBottom: 16, padding: '0 16px', position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            plaement="bottomRight"
            visible={menuState}
            onVisibleChange={val => setMenuState(val)}
          >
            <IconBtn active={menuState}>
              <Icon type="more" />
            </IconBtn>
          </Dropdown>
        </div>
        {editing ? (
          <Input
            value={titleInput}
            onChange={e => titleChange(e.target.value)}
            style={{ marginBottom: 16 }}
            onBlur={handleSubmit}
            onPressEnter={handleSubmit}
            autoFocus
          />
        ) : (
          <div
            className="text-wrap-ellipsis"
            style={{ marginBottom: 16, padding: '0 16px', width: 170 }}
          >
            <span>{data.title || 'Segment'}</span>
          </div>
        )}
      </div>
      <div
        style={{
          marginBottom: 16,
          padding: '0 16px',
          position: 'relative',
        }}
      >
        <span>ดูแลลูกค้า {segments.length} กลุ่ม</span>
        <IconBtn style={{ position: 'absolute', right: 0, top: 0 }} onClick={() => setModal(true)}>
          <Icon type="edit" />
        </IconBtn>
      </div>
      {/* <div style={{ marginBottom: 16, padding: '0 16px' }}>
        <span>{data.description}</span>
      </div> */}
      <div style={{ position: 'relative' }}>
        {agents.map(a => (
          <AgentItem
            key={a.name}
            data={a}
            owner={data.name}
            onDrop={(item, target) => onDrop(item, target)}
            onDelete={onDeleteAgent}
          />
        ))}
        <div style={{ padding: 12, color: isOver ? 'rgba(0, 0, 0, 0.65)' : '#B7B7B7' }}>
          <Icon type="plus" style={{ marginRight: 4 }} />
          <span>เพิ่มเอเจนท์</span>
        </div>
      </div>
      <Modal
        title="เลือกกลุ่มลูกค้าให้เอเจนท์"
        visible={modalState}
        onOk={() => {
          onSegmentsChange(input)
          setModal(false)
        }}
        onCancel={() => setModal(false)}
        okText="บันทึก"
        cancelText="ยกเลิก"
        destroyOnClose
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="เลือกจากกลุ่มลูกค้าทั้งหมด"
          value={input}
          onChange={e => setInput(e)}
        >
          {allSegments.map(s => (
            <Select.Option key={s.name}>{s.title || 'Untitled'}</Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  )
}

GroupItem.propTypes = {
  allAgents: PT.arrayOf(PT_AGENT),
  allSegments: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
    }),
  ),
  color: PT.string,
  data: PT_AGENT_GROUP,
  onDeleteAgent: PT.func,
  onDrop: PT.func,
  onSegmentsChange: PT.func,
  onChange: PT.func,
  onDelete: PT.func,
}

GroupItem.defaultProps = {
  allAgents: [],
  allSegments: [],
  data: {},
  onDeleteAgent: () => {},
  onDrop: () => {},
  onSegmentsChange: () => {},
  onChange: () => {},
  onDelete: () => {},
}

export default GroupItem
