import React from 'react'
import PT from 'prop-types'
import { Input, Select } from 'antd'

import InputField from '../../../../../components/InputField'

const ActionItem = ({ index, action, onChange, loading }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <span style={{ width: 16, fontWeight: 500, color: 'rgba(136, 136, 136, 1)' }}>{index}</span>
      <InputField title="ประเภท" style={{ width: 150, marginRight: 8 }}>
        <Select
          value={action.type}
          onChange={val => onChange({ type: val })}
          style={{ width: '100%' }}
          loading={loading}
        >
          <Select.Option key="none">No Action</Select.Option>
          <Select.Option key="message">ข้อความ</Select.Option>
          <Select.Option key="uri">ลิงก์</Select.Option>
        </Select>
      </InputField>
      {action.type !== 'none' && (
        <InputField
          title={action.type === 'message' ? 'ข้อความ' : 'ลิงก์'}
          style={{ width: 'calc(100% - 16px - 158px)' }}
        >
          <Input
            value={action.data}
            onChange={e => onChange({ data: e.target.value })}
            disabled={loading}
          />
        </InputField>
      )}
    </div>
  )
}

ActionItem.propTypes = {
  index: PT.number.isRequired,
  action: PT.shape({
    type: PT.oneOf(['none', 'message', 'uri']),
    data: PT.string,
  }),
  onChange: PT.func,
  loading: PT.bool,
}

ActionItem.defaultProps = {
  action: {
    type: 'none',
  },
  onChange: () => {},
}

export default ActionItem
