import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const MenuItem = styled.div`
  padding: 12px 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  &:hover {
    background-color: #206eb017;
    font-weight: 600;
    color: #1c70b0;
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #206eb019;
    font-weight: 600;
    color: #1c70b0;
  `}
`

const SideMenu = ({ menu, defaultSelect, value, onChange }) => {
  const [itemSelected, setItemSelected] = React.useState(defaultSelect)
  const tmp = value === undefined ? itemSelected : value

  const handleMenuClick = key => {
    setItemSelected(key)
    onChange(key)
  }

  return (
    <div>
      {menu.map(m => (
        <MenuItem key={m.key} isSelected={m.key === tmp} onClick={() => handleMenuClick(m.key)}>
          {m.title}
        </MenuItem>
      ))}
    </div>
  )
}

SideMenu.propTypes = {
  menu: PT.arrayOf(
    PT.shape({
      key: PT.string,
      title: PT.oneOfType([PT.string, PT.node]),
    }),
  ),
  defaultSelect: PT.string,
  value: PT.string,
  onChange: PT.func,
}

SideMenu.defaultProps = {
  menu: [],
  onChange: () => {},
}

export default SideMenu
