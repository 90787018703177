import React from 'react'
import PT from 'prop-types'
import { Input, InputNumber } from 'antd'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import LINE_PAY_LOGO from '../../../assets/images/LINE-Pay-Logo.jpg'
import Button from '../../../components/Button'
import ImageRenderer from '../../../components/ImageRenderer'
import NumberRenderer from '../../../components/NumberRenderer'

const GET_PROMOCODE = gql`
  query getPromoCode($code: String!, $botId: String!) {
    getPromoCode(code: $code, bot_id: $botId) {
      status
      redeem_type
      amount
    }
  }
`

const PaymentItem = styled.div`
  width: 200px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.35s;
  margin-right: 16px;
  &:hover {
    box-shadow: none;
    font-weight: 500;
  }
`

const CreditTopup = ({ onSubmit, loading }) => {
  const [payMethod, setMethod] = React.useState('')
  const [redeemCode, redeemCodeChange] = React.useState('')
  const [credit, setCredit] = React.useState(0)
  const [promoCode, promoCodeChange] = React.useState('')

  const [getPromoCode, getPromoCodeRes] = useLazyQuery(GET_PROMOCODE)
  const promo = (getPromoCodeRes.data && getPromoCodeRes.data.getPromoCode) || {}

  const totalCredit = credit * ((promo.redeem_type === 'BONUS' && promo.amount) || 1)
  const totalAmount = (credit * ((promo.redeem_type === 'DISCOUNT' && promo.amount) || 100)) / 100

  const handleSubmitClick = () => {
    onSubmit({
      payMethod,
      credit: totalCredit,
      amount: totalAmount,
    })
  }

  const handlePromoCodeSubmit = async () => {
    try {
      await getPromoCode({ variables: { code: promoCode, botId: 'f0d5fcfb70' } })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div style={{ padding: 36, maxWidth: 480, width: '100%', margin: 'auto', textAlign: 'center' }}>
      <h2>เติมเครดิต</h2>
      {payMethod === '' ? (
        <div>
          <div style={{ marginBottom: 16 }}>เลือกวิธีการชำระ</div>
          <div style={{ display: 'flex' }}>
            <PaymentItem onClick={() => setMethod('redeem')}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span>ใช้ Redeem Code</span>
              </div>
            </PaymentItem>
            <PaymentItem onClick={() => setMethod('linepay')}>
              <ImageRenderer
                style={{ cursor: 'pointer' }}
                width="100%"
                src={LINE_PAY_LOGO}
                alt="linepay"
              />
            </PaymentItem>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 32 }}>
          <div style={{ marginBottom: 16 }}>
            {payMethod === 'redeem' && (
              <>
                <Input
                  value={redeemCode}
                  onChange={e => redeemCodeChange(e.target.value.toUpperCase())}
                  placeholder="ใส่โค้ดโปรโมชั่นเพื่อรับเครดิต.."
                  autoFocus
                />
              </>
            )}
            {payMethod === 'linepay' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginBottom: 8,
                  }}
                >
                  <span>กรอกจำนวนเครดิตที่ต้องการ</span>
                  <InputNumber
                    value={credit}
                    onChange={val => setCredit(val)}
                    min={0}
                    step={100}
                    style={{ width: 194 }}
                    formatter={value => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/฿\s?|(,*)/g, '')}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginBottom: 8,
                  }}
                >
                  <span>โค้ดโปรโมชั่น</span>
                  <div>
                    <Input
                      value={promoCode}
                      onChange={e => promoCodeChange(e.target.value.toUpperCase())}
                      onPressEnter={handlePromoCodeSubmit}
                      style={{ width: 120, marginRight: 4 }}
                      loading={getPromoCodeRes.loading}
                      disabled={
                        !getPromoCodeRes.loading && getPromoCodeRes.called && !getPromoCodeRes.error
                      }
                    />
                    <Button
                      type="primary"
                      ghost
                      loading={getPromoCodeRes.loading}
                      onClick={handlePromoCodeSubmit}
                    >
                      ใช้โค้ด
                    </Button>
                    {getPromoCodeRes.called && !getPromoCodeRes.loading && (
                      <div style={{ textAlign: 'left', fontWeight: 500 }}>
                        {getPromoCodeRes.error ? (
                          <small style={{ color: '#FF5C5C' }}>ผิดพลาด</small>
                        ) : (
                          <small style={{ color: '#00D196' }}>สำเร็จ</small>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginBottom: 8,
                  }}
                >
                  <span>เครดิตที่ได้</span>
                  <NumberRenderer value={totalCredit} suffix="฿" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginBottom: 8,
                  }}
                >
                  <span>ราคาที่ต้องจ่าย</span>
                  <NumberRenderer
                    value={totalAmount}
                    suffix="฿"
                    style={{ fontWeight: 500, color: '#FFB700' }}
                  />
                </div>
              </>
            )}
          </div>
          <Button
            type="primary"
            block
            style={{ marginBottom: 8 }}
            onClick={() => handleSubmitClick()}
            loading={loading}
          >
            ดำเนินการ
          </Button>
          <Button type="secondary" onClick={() => setMethod('')} block>
            เปลี่ยนวิธีการชำระเงิน
          </Button>
        </div>
      )}
    </div>
  )
}

CreditTopup.propTypes = {
  onSubmit: PT.func,
  loading: PT.bool,
}

CreditTopup.defaultProps = {
  onSubmit: () => {},
}

export default CreditTopup
