import Dashboard from '../pages/main/Dashboard'
import BroadcastList from '../pages/main/Broadcast/list'
import BroadcastEditor from '../pages/main/Broadcast/editor'
import RichMessageList from '../pages/main/RichMessage/list'
import RichMessageEditor from '../pages/main/RichMessage/editor'
import AutoReply from '../pages/main/AutoReply'
import RichmenuList from '../pages/main/Richmenu/RichmenuList'
import RichmenuCreate from '../pages/main/Richmenu/RichmenuCreate'

export default [
  {
    path: '/dashboard',
    page: Dashboard,
  },
  {
    path: '/broadcast/list',
    page: BroadcastList,
  },
  {
    path: '/broadcast/:bcId',
    page: BroadcastEditor,
  },
  {
    path: '/rich-message/list',
    page: RichMessageList,
  },
  {
    path: '/rich-message/:richMessageId',
    page: RichMessageEditor,
  },
  {
    path: '/autoreply',
    page: AutoReply,
  },
  {
    path: '/richmenu/list',
    page: RichmenuList,
  },
  {
    path: '/richmenu/create',
    page: RichmenuCreate,
  },
]
