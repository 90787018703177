import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { client } from './api/apollo'
import AppContainer from './container/App'
import HeightController from './libs/HeightController'
import Login from './pages/session/Login'
import LineLogin from './pages/session/Login/LineLogin'
import Logout from './pages/session/Logout'
import Portal from './pages/session/Portal'
import Register from './pages/session/Register'
import ForgotPwd from './pages/session/ForgotPwd'
import SetPwd from './pages/session/SetPwd'
import ActivateAdmin from './pages/session/ActivateAdmin'
import LinepayConfirm from './pages/session/LinepayConfirm'
import PrivateRoute from './routes/PrivateRoute'
import ActivateInvitation from './pages/session/ActivateInvitation'

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ApolloHookProvider client={client}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <HeightController>
              <div style={{ width: '100vw' }} className="full-height">
                <Switch>
                  <Redirect from="/" to="/app" exact />
                  <Route path="/R" component={Portal} />
                  <Route path="/register" component={Register} />
                  <Route path="/login" component={Login} />
                  <Route path="/linelogin" component={LineLogin} />
                  <Route path="/linepay-confirm" component={LinepayConfirm} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/forgot-password" component={ForgotPwd} />
                  <Route path="/set-password" component={SetPwd} />
                  <Route path="/a/invitation" component={ActivateInvitation} />
                  <PrivateRoute path="/app" component={AppContainer} />
                </Switch>
              </div>
            </HeightController>
          </DndProvider>
        </BrowserRouter>
      </ApolloHookProvider>
    </ApolloProvider>
  )
}

export default App
