import React from 'react'
import styled from 'styled-components'
import PT from 'prop-types'

import BTN_LOGIN_BASE from '../assets/images/LINE_Login/btn_base.png'
import BTN_LOGIN_HOVER from '../assets/images/LINE_Login/btn_hover.png'
import BTN_LOGIN_PRESS from '../assets/images/LINE_Login/btn_press.png'
import { Icon } from 'antd'

const LineButton = styled.div`
  cursor: pointer;
  margin: 0 auto 8px;

  height: 36px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  background-color: #00c300;

  #logo {
    width: 36px;
    height: 36px;
    background-position: center center;
    background-size: 36px 36px;
  }
  ${({ img }) => `
    #logo {
      background-image: url(${img.base});
    }
    &:hover {
      background-color: #00E000;
      #logo {
        background-image: url(${img.hover});
      }
    }
    &:active {
      background-color: #00B300;
      #logo {
        background-image: url(${img.active});
      }
    }
  `}
`

const LineLoginBtn = ({ title, onClick, style, loading }) => {
  const lineLogoUrl = {
    base: BTN_LOGIN_BASE,
    hover: BTN_LOGIN_HOVER,
    active: BTN_LOGIN_PRESS,
  }

  return (
    <LineButton img={lineLogoUrl} onClick={!loading && onClick} style={style}>
      <span id="logo" />
      <div style={{ textAlign: 'center', width: 'calc(100% - 36px)' }}>
        <span style={{ color: '#ffffff' }}>
          {loading && <Icon type="loading" style={{ marginRight: 8 }} />}
          <span style={{ fontWeight: 600 }}>{title || 'Log in with LINE'}</span>
        </span>
      </div>
    </LineButton>
  )
}

LineLoginBtn.propTypes = {
  title: PT.string,
  onClick: PT.func,
  style: PT.object,
  loading: PT.bool,
}

LineLoginBtn.defaultProps = {
  onClick: () => {},
  style: {},
}

export default LineLoginBtn
