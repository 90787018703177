import React from 'react'
import PT from 'prop-types'
import { Table } from 'antd'
import moment from 'moment'

import NO_IMG from '../../../../assets/images/no_img.png'
import ImageRenderer from '../../../../components/ImageRenderer'

const RichMessageTable = ({ dataSource, height, loading, openEditor }) => {
  const [currHover, hoverChange] = React.useState('')
  return (
    <Table
      style={{ minHeight: height + 54 }}
      dataSource={dataSource}
      rowKey="name"
      pagination={false}
      scroll={{ y: height }}
      loading={loading}
      onRow={record => ({
        onClick: () => openEditor(record.name),
        onMouseEnter: () => hoverChange(record.name),
        onMouseLeave: () => hoverChange(''),
        style: { cursor: 'pointer' },
      })}
    >
      <Table.Column
        title="ชื่อ"
        key="title"
        dataIndex="title"
        render={(title, record) => (
          <div>
            <ImageRenderer
              src={record.imageUrl}
              alt={record.name}
              errorImg={NO_IMG}
              style={{ marginRight: 8, cursor: 'pointer' }}
              height={48}
            />
            <span style={currHover === record.name ? { textDecoration: 'underline' } : {}}>
              {title}
            </span>
          </div>
        )}
      />
      {/* <Table.Column
        title="สถิติ(คลิก)"
        key="hits"
        width={120}
        dataIndex="hits"
        render={val => <NumberRenderer value={val} />}
      /> */}
      <Table.Column
        title="วันที่แก้ไขล่าสุด"
        key="modified"
        dataIndex="modified"
        width={180}
        render={timestamp => <span>{moment(timestamp).format('DD/MM/YYYY HH:mm')}</span>}
      />
    </Table>
  )
}

RichMessageTable.propTypes = {
  height: PT.number,
  dataSource: PT.arrayOf(
    PT.shape({
      name: PT.string,
      title: PT.string,
      imageUrl: PT.string,
      modified: PT.string,
      hits: PT.number,
    }),
  ),
  loading: PT.bool,
  openEditor: PT.func,
}

RichMessageTable.defaultProps = {
  dataSource: [],
  openEditor: () => {},
}

export default RichMessageTable
