import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const Item = styled.div`
  margin-right: 8px;
  padding: 8px 16px;
  cursor: pointer;
  &.active {
    font-weight: 600;
    color: rgba(28, 112, 176, 1);
  }
`

const TabMenu = ({ list, onChange, defaultSelected }) => {
  const [selected, changeSelected] = React.useState(
    defaultSelected ? defaultSelected : list ? list[0].key : undefined,
  )

  const handleSelected = key => {
    changeSelected(key)
    onChange(key)
  }

  return (
    <Container style={{ width: '100%', padding: '8px 0' }}>
      {list.map(item => (
        <Item
          key={item.key}
          onClick={() => handleSelected(item.key)}
          className={selected === item.key && 'active'}
        >
          <span>{item.menu}</span>
        </Item>
      ))}
    </Container>
  )
}

TabMenu.propTypes = {
  list: PT.arrayOf(
    PT.shape({
      key: PT.string,
      menu: PT.oneOfType([PT.string, PT.node]),
    }),
  ),
  onChange: PT.func,
  defaultSelected: PT.string,
}

TabMenu.defaultProps = {
  onChange: () => {},
}

export default TabMenu
