import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import WisibleSidenav from '../layouts/sidenav/WisibleSidenav'
import wisibleRoutes from '../routes/wisibleRoutes'

const WisibleContainer = props => {
  const initPath = props.match.path
  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div style={{ width: 280, height: '100%', backgroundColor: 'rgba(244, 244, 244, 1)' }}>
        <WisibleSidenav routeProps={props} />
      </div>
      <div
        style={{
          width: 'calc(100% - 280px)',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Switch>
          <Redirect from={initPath} to={initPath + '/config'} exact />
          {wisibleRoutes.map(route => (
            <Route key={route.path} path={initPath + route.path} component={route.page} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default WisibleContainer
