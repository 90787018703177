import React from 'react'
import PT from 'prop-types'
import { message } from 'antd'

import Button from './Button'
import ImageRenderer from './ImageRenderer'

const InputImageFile = ({ value, onChange }) => {
  const inputFile = React.useRef()

  const [preview, setPreview] = React.useState('')

  const setPreviewUrl = (file, cb) => {
    const fr = new FileReader()
    fr.onload = () => {
      cb(fr.result)
    }
    fr.readAsDataURL(file)
  }

  const handleUpload = file => {
    if (file.size > 1024 * 1024 * 10) {
      message.error('ไฟล์ต้องมีขนาดไม่เกิน 10MB!!')
      return
    }
    onChange(file)
  }

  if (value) {
    setPreviewUrl(value, url => {
      setPreview(url)
    })
  }

  return (
    <div>
      <input
        ref={inputFile}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => handleUpload(e.target.files[0])}
      />
      {preview && value ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{value.name}</span>
            <Button onClick={() => inputFile.current.click()}>อัพโหลดรูปภาพใหม่</Button>
          </div>
          <ImageRenderer src={preview} alt={value.name} height={300} />
        </>
      ) : (
        <Button onClick={() => inputFile.current.click()} block>
          อัพโหลดรูปภาพ
        </Button>
      )}
    </div>
  )
}

InputImageFile.propTypes = {
  value: PT.object,
  onChange: PT.func,
}

InputImageFile.defaultProps = {
  onChange: () => {},
}

export default InputImageFile
