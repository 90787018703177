import React from 'react'
import PT from 'prop-types'
import { Input, message, Icon, Tooltip } from 'antd'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Button from './Button'
import ImageRenderer from './ImageRenderer'
import { getObjectUrl, getImageResolution } from '../helpers/file'

const IMAGE_UPLOAD = gql`
  mutation imageUpload($file: Upload!, $botId: String) {
    imageUpload(file: $file, bot_id: $botId) {
      id
      filename
      mimetype
      encoding
      url
    }
  }
`

const InputImage = ({
  loading: propsLoad,
  readOnly,
  value,
  onChange,
  imageUpload,
  botId,
  alt,
  btnProps,
  accept,
  maxSize,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [size, setSize] = React.useState({})
  const inputFile = React.useRef()
  const copier = React.useRef()

  React.useEffect(() => {
    if (value) {
      getImageResolution(value).then(val => setSize(val))
    }
  }, [value])

  const openUpload = () => {
    inputFile.current.click()
  }

  const copyImageUrl = () => {
    copier.current.select()
    document.execCommand('copy')
    copier.current.blur()
    message.success('คัดลอกแล้ว')
  }

  const handleUpload = async file => {
    if (file.size > maxSize) {
      message.error('ไฟล์ต้องมีขนาดไม่เกิน 10MB!!')
      return
    }
    setLoading(true)
    try {
      const { data } = await imageUpload({ variables: { file, botId } })
      const imageUploadData = (data && data.imageUpload) || {}
      const imageUrl = imageUploadData.url
      onChange(imageUrl)
    } catch (err) {
      message.error(err.message)
      console.error(err.message)
    }
    setLoading(false)
  }

  return (
    <div>
      <input
        ref={inputFile}
        onChange={e => handleUpload(e.target.files[0])}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
      />
      {value ? (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
            <ImageRenderer
              style={{ width: '40%' }}
              src={value}
              alt={alt || 'preview'}
              clickToOpen
            />
            <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-end' }}>
              {!readOnly && (
                <Button loading={propsLoad || loading} type="danger" onClick={openUpload}>
                  อัพโหลดใหม่
                </Button>
              )}
              <small style={{ marginTop: 4 }}>{getObjectUrl(value).filename}</small>
              <small style={{ marginTop: 4 }}>
                {size.width}x{size.height} px
              </small>
            </div>
          </div>
          <Input
            ref={copier}
            value={value}
            addonAfter={
              document.queryCommandSupported('copy') && (
                <Tooltip title="คัดลอก">
                  <Icon type="copy" onClick={copyImageUrl} style={{ cursor: 'pointer' }} />
                </Tooltip>
              )
            }
          />
        </div>
      ) : readOnly ? null : (
        <Button
          style={{ marginBottom: 8 }}
          icon={<Icon type="picture" />}
          onClick={openUpload}
          loading={propsLoad || loading}
          block
          {...btnProps}
        >
          อัพโหลดรูปภาพ
        </Button>
      )}
    </div>
  )
}

InputImage.propTypes = {
  value: PT.string,
  onChange: PT.func,
  botId: PT.string,
  alt: PT.string,
  loading: PT.bool,
  readOnly: PT.bool,
  btnProps: PT.object,
  accept: PT.string,
  maxSize: PT.number,
}

InputImage.defaultProps = {
  onChange: () => {},
  accept: 'image/*',
  maxSize: 1024 * 1024 * 10,
}

export default props => {
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  return <InputImage {...props} imageUpload={imageUpload} />
}
