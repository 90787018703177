import React from 'react'
import PT from 'prop-types'
import { Switch, Input } from 'antd'

import Button from '../../../components/Button'
import InputField from '../../../components/InputField'

const ConfigForm = ({ onSubmit, data, loading }) => {
  const [input, setInput] = React.useState({
    enabled: data.enabled,
    access_token: data.access_token,
  })

  React.useEffect(() => {
    setInput({
      enabled: data.enabled,
      access_token: data.access_token,
    })
  }, [data])

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const handleSaveBtnClick = () => {
    onSubmit(input)
  }

  return (
    <div>
      <InputField title="เปิดใช้งาน Wisible">
        <Switch
          checked={input.enabled}
          onChange={e => inputChange({ enabled: e })}
          loading={loading}
        />
      </InputField>
      <InputField title="API token">
        <Input.TextArea
          autoSize={{ minRows: 4 }}
          value={input.access_token}
          onChange={e => inputChange({ access_token: e.target.value })}
          disabled={loading || !input.enabled}
        />
      </InputField>
      <Button type="primary" onClick={handleSaveBtnClick} block loading={loading}>
        บันทึก
      </Button>
    </div>
  )
}

ConfigForm.propTypes = {
  data: PT.shape({
    enabled: PT.bool,
    access_token: PT.string,
  }),
  onSubmit: PT.func,
  loading: PT.bool,
}

ConfigForm.defaultProps = {
  onSubmit: () => {},
}

export default ConfigForm
