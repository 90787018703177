import React from 'react'
import { Divider } from 'antd'

import RichmenuForm from '../shared/RichmenuForm'
import RichmenuPreview from '../shared/RichmenuPreview'

const RichmenuCreate = ({ match }) => {
  const botId = match.params.botId
  const [input, setInput] = React.useState({
    title: '',
    schedule: '',
    payload: {
      templateSize: {},
      actions: [],
      input: {},
    },
    line_payload: {},
    image_url:
      'https://s3.ap-southeast-1.amazonaws.com/dev.admin.aiya.ai/73fbd23255/images/truth_amulet.jpg',
  })

  const inputChange = item => {
    setInput({ ...input, ...item })
  }

  const config = (input.payload || {}).input || {}

  return (
    <div style={{ height: '100%' }}>
      <div style={{ padding: '24px 36px 16px', borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <h1>สร้างริชเมนูใหม่</h1>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 97px)' }}>
        <div
          style={{
            width: 480,
            borderRight: '1px solid rgba(0, 0, 0, 0.05)',
            padding: '12px 36px',
            overflowY: 'auto',
          }}
        >
          <RichmenuForm botId={botId} input={input} inputChange={inputChange} />
          <Divider />
        </div>
        <div style={{ width: 'calc(100% - 480px)', height: '100%' }}>
          <RichmenuPreview imageUrl={input.image_url} chatBarText={config.chatBarText} />
        </div>
      </div>
    </div>
  )
}

export default RichmenuCreate
