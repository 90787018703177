import React from 'react'
import PT from 'prop-types'
import { Input } from 'antd'

const FlexEditor = ({ message, onMessageChange, loading }) => {
  const [textInput, inputChange] = React.useState(JSON.stringify(message, null, 4))
  React.useEffect(() => {
    inputChange(JSON.stringify(message, null, 4))
  }, [message])

  const getJSONObj = str => {
    try {
      return JSON.parse(str)
    } catch (err) {
      inputChange(JSON.stringify(message, null, 4))
      return message
    }
  }

  const handleMessageChange = () => {
    onMessageChange(getJSONObj(textInput))
  }

  return (
    <Input.TextArea
      value={textInput}
      onChange={e => inputChange(e.target.value)}
      onBlur={handleMessageChange}
      onPressEnter={handleMessageChange}
      style={{ resize: 'none', border: 'none' }}
      autoSize={{ minRows: 9 }}
      disabled={loading}
    />
  )
}

FlexEditor.propTypes = {
  message: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

FlexEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default FlexEditor
